<template>
	<div class="d-flex justify-content-between">
		<div class="text-initial">
			<p v-if="operation.segment">
				{{ $t('segment') }}
			</p>
			<p class="text-grey" v-else-if="operation.order">
				{{ $t('order') }}
			</p>
			<h1 class="text-secondary">
				N°
				<span v-if="operation.segment">
					{{ operation.segment.internal_reference }}
				</span>
				<span v-else-if="operation.order">
					{{ operation.order.int_code }}
				</span>
			</h1>
		</div>
	</div>
</template>
<script>
export default {
	computed : {
		operation() {
			return this.$store.getters['operation/get'];
		}
	},
};
</script>