<template>
	<b-card no-body v-if="items.roads.length">
		<b-card-header>{{ $t('filter-per-road') }}</b-card-header>
		<b-card-body>
			<ul>
				<li class="road" v-for="(road, index) in items.roads" :key="index" @click="setRoad($event, road, index)"
					:id="'road'+ road._id">
					<div class="vertical-rectangle mr-1" :style="'background-color:' + road.color" />
					<p>
						R{{ road._id }} -
						<span v-for="(segment, key) in road.segments" :key="key">
							<span class="road-name"> {{ segment.origin.name }} </span>
							<span v-if="key != road.segments.length - 1" class="next-sign">
								>
							</span>
						</span>
					</p>
				</li>
			</ul>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	props : {
		items : {
			required : true,
			type     : Object
		}
	},
	created() {
		const t = this;
		const roads = t.items.roads;
		for (let i = 0; i < roads.length; i++) {
			const road = roads[i];
			road.active = false;
		}
	},
	computed : {
		currentRoad() {
			return this.items.currentRoad;
		}
	},
	watch : {
		currentRoad(val) {
			this.removeAllActiveRoad();
			if(val) {
				document.getElementById('road' + val._id).classList.add('active');
			}
		}
	},
	methods : {
		setRoad($event, road) {
			const li = $event.target.closest('li');
			const t = this;

			if(!li.classList.contains('active')) {
				this.removeAllActiveRoad();
				li.classList.add('active');
				t.items.currentRoad = road;
				t.$emit('set-items', t.items);
			} else {
				this.removeAllActiveRoad();
				t.items.currentRoad = null;
				t.$emit('set-items', t.items);
			}
		},
		removeAllActiveRoad() {
			var roads = Array.from(document.getElementsByClassName('road'));
			roads.forEach(function(e) {
				e.classList.remove('active');
			});
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.card-body {
	max-height: 164px;
	overflow-y: auto;
}
ul .road {
	display: flex;
	align-items: center;
	background-color: $white;
	border-radius: $border-radius;
	box-shadow: $shadow;
	border: 2px solid $white;
	padding: 0 0.5rem;
	margin-top: 2px;
	cursor: pointer;
	overflow-x: auto;
	max-width: 100%;
	white-space: nowrap;
	&:first-of-type {
		margin-top: 0;
	}
}
ul .road.active {
	border-color: $green;
}
ul .road > div {
	display: flex;
	margin-right: 3px;
}
ul li .vertical-rectangle {
	min-width: 7px;
}
p {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.next-sign {
	margin-left: 3px;
}
.road-name {
	display: inline-block;
	text-transform: lowercase;
	&:first-letter {
		text-transform: uppercase;
	}
}
</style>