import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import BootstrapVue from 'bootstrap-vue';
import VueMeta from 'vue-meta';
import './filter.js';
import './mixins/common.js';
import './mixins/settings.js';
import './mixins/user.js';
import VueInsProgressBar from 'vue-ins-progress-bar';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueInsProgressBar, {
	position : 'fixed',
	show     : true,
	height   : '3px'
});
Vue.use(VueAxios, axios);

Vue.component('multi-select', Multiselect);
Vue.config.productionTip = false;
Vue.prototype.$loading = Vue.observable({ state : false });
Vue.use(BootstrapVue, {
	breakpoints : [ 'xs', 'sm', 'md', 'lg', 'xl']
});
Vue.use(VueAxios, axios);
Vue.use(VueMeta);
new Vue({
	i18n,
	router,
	store,
	render : function(h) {
		return h(App);
	},
}).$mount('#app');
