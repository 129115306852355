<template>
	<b-card no-body>
		<b-card-header>
			{{ $t('segment-delay-conditions') }}
		</b-card-header>
		<b-card-body>
			<div class="d-flex justify-content-between align-items-center">
				<div class="d-flex">
					<form>
						<b-form-group :label="$t('modal')">
							<b-form-select :value="null">
								<option :value="null">
									Rail
								</option>
								<option>{{ $t('road') }}</option>
							</b-form-select>
						</b-form-group>
					</form>
					<form class="ml-3">
						<b-form-group :label="$t('type')">
							<b-form-select :value="null">
								<option :value="null">
									{{ $t('empty') }}
								</option>
								<option>{{ $t('full') }}</option>
							</b-form-select>
						</b-form-group>
					</form>
				</div>
				<div id="switch">
					<ul>
						<li class="active" @click="switchPercentage($event, 95)">
							95%
						</li>
						<li @click="switchPercentage($event, 98)">
							98%
						</li>
					</ul>
					<p>{{ $t('delay-condition') }}</p>
				</div>
			</div>
			<div class="technical-informations light">
				<ul>
					<li v-if="percentage == 95">
						<span>{{ $t('delay-in-minutes') }}</span>
						<span>0-30</span>
						<span>30-90</span>
						<span>90-270</span>
						<span>270-510</span>
						<span>> 510</span>
					</li>
					<li v-if="percentage == 98">
						<span>{{ $t('delay-in-minutes') }}</span>
						<span>0-240</span>
						<span>240-300</span>
						<span>300-480</span>
						<span>480-720</span>
						<span>> 720</span>
					</li>
					<li>
						<span>{{ $t('value') }}</span>
						<span>5%</span>
						<span>-3%</span>
						<span>-5%</span>
						<span>-10%</span>
						<span>-15%</span>
					</li>
				</ul>
			</div>
			<div class="mt-4">
				<ul id="legends-rows" v-if="percentage == 95">
					<p class="text-grey">
						{{ $t('delay-in-minutes') }}
					</p>
					<li>
						0
					</li>
					<li style="left: -5px;">
						30
					</li>
					<li style="left: -7px;">
						90
					</li>
					<li style="left: -11px;">
						270
					</li>
					<li style="left: -9px;">
						510
					</li>
				</ul>
				<ul id="legends-rows" v-if="percentage == 98">
					<p class="text-grey">
						{{ $t('delay-in-minutes') }}
					</p>
					<li>
						0
					</li>
					<li style="left: -11px;">
						240
					</li>
					<li style="left: -7px;">
						300
					</li>
					<li style="left: -11px;">
						480
					</li>
					<li style="left: -9px;">
						720
					</li>
				</ul>
				<div class="d-flex">
					<ul id="legends-columns">
						<p class="text-grey">
							{{ $t('value') }} %
						</p>
						<li>5%</li>
						<li>-3%</li>
						<li>-5%</li>
						<li>-10%</li>
						<li>-15%</li>
					</ul>
					<div id="container-array">
						<ul class="array-delay" v-for="(i, row) in new Array(22)" :key="i">
							<li v-for="(elem, column) in new Array(5)" :key="elem">
								<div v-if="row == 0 && column == 0">
									5%
								</div>
								<div v-if="row == 8 && column == 1">
									-3%
								</div>
								<div v-if="row == 11 && column == 2">
									-6%
								</div>
								<div v-if="row == 15 && column == 3">
									-10%
								</div>
								<div v-if="row == 20 && column == 4">
									-15	%
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	name : 'Delay',
	data() {
		return {
			percentage : 95
		};
	},
	methods : {
		switchPercentage($event, percentage) {
			this.percentage = percentage;
			const li = Array.from(document.getElementById('switch').getElementsByTagName('ul')[0]
				.getElementsByTagName('li'));
			li.forEach((e) => {
				e.classList.remove('active');
			});
			$event.target.classList.add('active');
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#switch {
	display: flex;
	align-items: center;
}
#switch ul {
	display: flex;
	background-color: $white;
	border-radius: 29px;
}
#switch p {
	color: $grey;
	margin-left: 1rem;
	text-transform: initial;
}
#switch ul li {
	cursor: pointer;
	padding: 5px 10px;
	border-radius: 29px;
}
#switch ul li.active {
	color: $white;
	background-color: $primary;
}
.technical-informations {
	width: 100%;
}
.technical-informations ul li {
	display: flex;
	align-items: stretch;
}
.technical-informations ul li span {
	text-transform: initial;
	display: flex;
	align-items: center;
	justify-content: center;
	&:first-of-type {
		min-width: 140px;
		text-align: left;
		justify-content: flex-start;
	}

	width: calc((100% - 100px) / 5);
}
#container-array {
	width: calc(100% - 60px);
}
.array-delay {
	display: flex;
	flex-wrap: wrap;
	&:first-of-type {
		border-top: 1px solid $grey;
	}
	&:last-of-type {
		border-bottom: 1px solid grey;
	}
}
.array-delay:nth-child(even) {
	background-color: lighten($grey, 30%);
}
.array-delay li {
	width: 20%;
	height: 15px;
	border-left: 1px solid $grey;
	border-right: 1px solid $grey;
}
.array-delay li div {
	line-height: 15px;
	font-size: 12px;
	text-align: center;
	color: $white;
	width: 100%;
	background-color: $primary;
}
#legends-rows {
	display: flex;
	position: relative;
	left: 60px;
	width: calc(100% - 60px);
	padding-top: 20px;
}
#legends-rows li {
	position: relative;
	font-weight: 700;
	width: 20%;
}
#legends-rows p {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}
#legends-columns {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: relative;
	height: 100%;
	line-height: 15px;
	width: 65px;
}
#legends-columns li {
	width: 35px;
	text-align: right;
	font-weight: 700;
	margin-right: 5px;
	height: calc(14px * 5);
	display: block;
	&:last-of-type {
		height: initial;
	}
}
#legends-columns p {
	position: absolute;
	top: 50%;
	left: -9px;
	transform: rotate(-90deg) translateY(-50%);
	height: fit-content;
	white-space: nowrap;
}
fieldset {
	margin-bottom: 0;
}
/deep/.custom-select {
	min-width: 100px;
}
</style>