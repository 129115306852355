<template>
	<div class="btn-icon statut">
		<div v-if="segment.type == 'rail' && segment.state == 'empty'" class="icon trains-outline primary" />
		<div v-if="segment.type == 'rail' && segment.state == 'full'" class="icon trains primary" />
		<div v-if="segment.type == 'rail' && segment.state == 'maintenance'" class="icon entretien primary" />
		<div v-if="segment.type == 'road'" class="icon truck primary" />
		<div v-if="segment.type == 'air'" class="icon plane primary" />
		<div v-if="segment.type == 'sea'" class="icon ship primary" />
	</div>
</template>
<script>
export default {
	name  : 'Statut',
	props : {
		segment : {
			type     : Object,
			required : true
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>