<template>
	<div id="wagons">
		<h1>{{ $t('wagons') }}</h1>
		<div class="d-flex justify-content-end child-mx-2">
			<b-button variant="primary" @click="$router.push({name: 'CreateWagon'})">
				{{ $t('add-wagon') }}
			</b-button>
			<b-button variant="primary">
				{{ $t('add-wagons') }}
			</b-button>
			<b-button variant="primary" class="btn-icon-only">
				<div class="icon sync" />
			</b-button>
		</div>
		<div class="tab-header border-radius-top mt-3">
			<Filters>
				<Search />
				<Status />
				<Group :items="itemsNotFiltered" @set-items="setItems" />
			</Filters>
		</div>
		<b-table head-variant="light" :striped="true" hover responsive show-empty
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="items"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(action)="wagon">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon eye" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon"
								@click="$router.push({name: 'UpdateWagon', params: {id: wagon.item._id}})">
								<div class="icon edit" />
							</b-button>
						</li>
						<li>
							<b-button v-if="true" variant="primary" class="btn-round-icon">
								<div class="icon on" />
							</b-button>
							<b-button v-else variant="primary" class="btn-round-icon">
								<div class="icon off" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon comments" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Filters from '@/components/filters/Filters.vue';
import { Search, Status } from '@/components/filters/components';
import Group from '@/components/filters/components/wagons/Group.vue';
import Pagination from '@/components/Pagination.vue';

export default {
	name       : 'Wagons',
	components : {
		Filters,
		Search,
		Status,
		Group,
		Pagination
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('wagons'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('code'),
					key      : 'code',
					sortable : true,
				},
				{
					label    : this._i18n.t('trafic-type'),
					key      : 'trafictype',
					sortable : true,
				},
				{
					label    : this._i18n.t('family'),
					key      : 'family',
					sortable : true,
				},
				{
					label    : this._i18n.t('evp'),
					key      : 'evp',
					sortable : true,
				},
				{
					label    : this._i18n.t('mileage'),
					key      : 'mileage',
					sortable : true,
				},
				{
					label    : this._i18n.t('mileage'),
					key      : 'mileage',
					sortable : true,
				},
				{
					label    : this._i18n.t('status'),
					key      : 'status',
					sortable : true,
				},
				{
					label    : this._i18n.t('group'),
					key      : 'group.name',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		}
	},
	data() {
		return {
			items            : [],
			itemsNotFiltered : [],
			pagination       : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			}
		};
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			const data  = require('@/utils/fakeData/' + user.company.name + '/mockWagons.json');
			this.items = [...data];
			this.itemsNotFiltered = [...data];
			this.pagination.totalRows = this.items.length;
		}
	},
	created() {
		this.getData();
	}
};
</script>
<style lang="scss" scoped>
/deep/.action-field {
	min-width: 200px;
}
.actions {
	max-width: none;
}
</style>