<template>
	<div id="add-location">
		<div class="d-flex justify-content-between align-items-center">
			<h1 class="mb-4">
				{{ $t('create-location') }}
			</h1>
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)">
				<div class="icon arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-rental class="primary d-flex justify-content-between">
				{{ $t('information-rental') }}
				<div class="icon chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-rental" visible accordion="accordion">
				<b-card-body>
					<RentalFields :address="address" @set-address="setAddress" />
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-contact class="primary d-flex justify-content-between">
				{{ $t('add-contact') }}
				<div class="icon add" />
				<div class="icon remove" />
			</b-card-header>
			<b-collapse id="collapse-contact" accordion="accordion">
				<b-card-body>
					<b-card class="h-100" no-body>
						<b-card-header>
							{{ $t('coordinates') }}
						</b-card-header>
						<b-card-body>
							<b-row>
								<b-col lg="6">
									<b-form-group :label="$t('lastname')">
										<b-form-input type="text" placeholder="_" />
									</b-form-group>

									<b-form-group :label="$t('email')">
										<b-form-input type="email" placeholder="_" />
									</b-form-group>

									<b-form-group :label="$t('job')">
										<b-form-input type="text" placeholder="_" />
									</b-form-group>
								</b-col>
								<b-col lg="6">
									<b-form-group :label="$t('firstname')">
										<b-form-input type="text" placeholder="_" />
									</b-form-group>

									<b-form-group :label="$t('phone')">
										<b-form-input type="number" placeholder="_" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-col lg="12" class="d-flex justify-content-end ml-2">
								<b-button class="btn-icon-only" variant="primary">
									<div class="icon add" />
								</b-button>
							</b-col>
						</b-card-body>
					</b-card>
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-modal class="primary d-flex justify-content-between">
				{{ $t('add-modal') }}
				<div class="icon add" />
				<div class="icon remove" />
			</b-card-header>
			<b-collapse id="collapse-modal" accordion="accordion">
				<b-card-body>
					<ModalFields :address="address" />
				</b-card-body>
			</b-collapse>
		</div>
		<div class="d-flex justify-content-center mt-3">
			<b-button class="btn-icon" variant="primary"
				@click="$router.push({name: 'LocationList'})">
				<div class="icon save" />
				{{ $t('save') }}
			</b-button>
		</div>
	</div>
</template>

<script>
import RentalFields from '../locations/components/RentalFields.vue';
import ModalFields from '../locations/components/ModalFields.vue';


export default {
	name       : 'CreateLocation',
	components : {
		RentalFields,
		ModalFields
	},
	data() {
		return {
			address : [
				{ address_number : '' },
				{ address_street : '' },
				{ address_postalcode : '' },
				{ address_city : '' },
				{ address_state : '' },
				{ address_country : '' }
			],
		};
	},
	methods : {
		setAddress(address) {
			this.address = address;
		},
	}
};
</script>