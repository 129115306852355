<template>
	<div>
		<ul class="informations">
			<li>
				<p>
					<b>{{ transportPlan.name }}</b>

					<span v-if="transportPlan.timetable && transportPlan.timetable.days
						&& transportPlan.timetable.days.length > transportPlan.timetable.circulation_number">
						{{ $t('date-to-choose') }}
					</span>
				</p>
			</li>
			<li>
				<p class="text-center">
					<b>{{ transportPlan.origin.name }}</b>
					<!--span v-if="FirstSegmentIsRoad()">{{ getFirstSegmentRailDate() }}</span-->
					<span>{{ transportPlan.timetable.departure_time }}</span>
				</p>
			</li>
			<li>
				<DateList v-if="transportPlan.timetable" :date-list="transportPlan.timetable" />
			</li>
			<li>
				<p class="text-center">
					<b>{{ transportPlan.destination.name }}</b>
					<span v-if="transportPlan.timetable">
						{{ transportPlan.timetable.arrival_time }}
						<span v-if="getDaysPlusTransportPlan(transportPlan) > 0">
							{{ $t('day-letter') }}+{{ getDaysPlusTransportPlan(transportPlan) }}
						</span>
					</span>
				</p>
			</li>
			<li />
		</ul>
	</div>
</template>
<script>
import Informations from '@/mixins/segments/Informations';
import DateList from '@/components/segments/DateList.vue';
export default {
	name       : 'Informations',
	components : {
		DateList
	},
	mixins : [
		Informations
	],
	props : {
		transportPlan : {
			type     : Object,
			required : false,
			default() {
				return {
					origin      : {},
					destination : {}
				};
			}
		},
	},
	methods : {
		FirstSegmentIsRoad() {
			if(this.transportPlan.segments[0].type == 'road') return true;
			else return false;
		},
		getFirstSegmentRailDate() {
			return this.transportPlan.segments[1].timetable.departure_time;
		}
	}

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.informations {
	color: $secondary;
}
</style>