<template>
	<div class="container-speedometer">
		<h2 :class="{'text-center color-primary my-2': true, 'color-yellow': threshold}">
			{{ circulation }}
		</h2>
		<div class="speedometer">
			<div class="arc" />
			<div :class="{'arc-bg': true, 'arc-cancel' : threshold}" />
			<div v-if="threshold" class="arc-alert" />
		</div>
		<h6 class="threshold" v-if="threshold">
			{{ $t('threshold') }} 1%
		</h6>
		<img src="@/assets/img/needle.jpg" class="needle" />
	</div>
</template>

<script>
export default {
	name  : 'Speedometer',
	props : {
		circulation : {
			type     : Number,
			required : true,
		},
		threshold : {
			type     : Number,
			required : false,
			default() {
				return 0;
			}
		}
	},
	mounted() {
		const el = this.$el;

		const percentage = (this.circulation / 40) * 100;
		const arcPrimary = el.getElementsByClassName('arc-bg')[0];
		const arcPrimaryDegree = Math.round((percentage * 180 / 100) - 45 );
		arcPrimary.style.transform = 'rotate(' + arcPrimaryDegree + 'deg)';

		if(this.threshold) {
			const arcAlert = el.getElementsByClassName('arc-alert')[0];
			const arcAlertDegree = Math.round(((100 - this.threshold) * 180 / 100) - 45 );
			arcAlert.style.transform = 'rotate(' + arcAlertDegree + 'deg)';
		}

		const needle = el.getElementsByClassName('needle')[0];
		const needleDegree = Math.round((percentage * 180 / 100) - 90);
		needle.style.transform = 'rotate(' + needleDegree + 'deg)';
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.container-speedometer {
	position: relative;
	padding-bottom: 0.5rem;
}
.speedometer {
	width: 200px !important;
	height: 100px;
	position: relative;
	overflow: hidden;
}
.speedometer .arc,
.speedometer .arc-bg,
.speedometer .arc-alert {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	border: 30px solid;
}
.speedometer .arc {
	border-color: $grey;
}
.speedometer .arc-bg {
	position: absolute;
	top: 0;
	left: 0;
	border-color: transparent transparent $primary $primary;
}
.speedometer .arc-cancel {
	border-color: transparent transparent $yellow $yellow;
}
.speedometer .arc-alert {
	position: absolute;
	top: 0;
	left: 0;
	border-color: $red $red transparent transparent;
}
.container-speedometer img {
	position: absolute;
	bottom: 0.5rem;
	height: 60px;
	margin-left: -6px;
	left: 50%;
	transform-origin: bottom center;
	z-index: 1;
	transform: rotate(0deg);
}
.threshold {
	position: absolute;
	left: 100%;
	white-space: nowrap;
	transform: translateX(-65%);
	color: $red;
	margin: 0.05rem 0 0 0;
}
</style>