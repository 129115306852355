<template>
	<div id="transport-plan">
		<div class="list-header border-radius-top">
			<ul>
				<li>{{ $t('road') }}</li>
				<li>{{ $t('origine') }}</li>
				<li />
				<li>{{ $t('destination') }}</li>
				<li />
			</ul>
		</div>
		<div class="list">
			<div class="container-item" v-for=" (transportPlan, index) in transportPlans" :key="index">
				<div class="item" v-b-toggle="'item-details-' + index">
					<Informations :transport-plan="transportPlan" />
					<HorizontalVisualization :segments="transportPlan.segments" class="mt-3" />
					<div class="toggle-details">
						<div class="icon chevron-down primary" />
					</div>
				</div>
				<b-collapse :id="'item-details-' + index">
					<div class="p-5 d-flex justify-content-between">
						<VerticalVisualization :transport-plan="transportPlan" />
						<TechnicalInformations :transport-plan="transportPlan" />
					</div>
				</b-collapse>
			</div>
		</div>
	</div>
</template>
<script>
import Informations from '@/components/transportPlan/Informations.vue';
import HorizontalVisualization from '@/components/transportPlan/HorizontalVisualization.vue';
import VerticalVisualization from '@/components/transportPlan/VerticalVisualization.vue';
import TechnicalInformations from './components/TechnicalInformations';

export default {
	name       : 'TransportPlan',
	components : {
		Informations,
		TechnicalInformations,
		HorizontalVisualization,
		VerticalVisualization,
	},
	data() {
		return {
			transportPlans : [],
		};
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			this.transportPlans  = require('@/utils/fakeData/' + user.company.name + '/mockRoutes.json');
		}
	},
	created() {
		this.getData();
	}

};
</script>