<template>
	<div class="circle-on-bar">
		<div class="bar-container">
			<div class="bar" :style="getStyle(road)">
				<div class="circle">
					{{ road.used }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props : {
		road : {
			type     : Object,
			required : true,
		}
	},
	methods : {
		getStyle(road) {
			const width = Math.round((road.used / road.total).toFixed(2) * 100);
			return 'width: ' + width + '%';
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.circle-on-bar {
	width: 100%;
}
.bar-container {
	height: 40px;
	width: 100%;
	background-color: $grey;
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}
.bar {
	width: 40%;
	height: 100%;
	background-color: $green;
	position: relative;
}
.circle {
	width: 80px;
	height: 80px;
	border: 4px solid $green;
	background-color: $white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	font-weight: 600;
	color: $green;
	font-size: 1.5rem;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	left: 50%;
}
</style>