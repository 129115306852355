<template>
	<b-card no-body>
		<b-card-header>
			{{ $t('circulations-cancelled') }}
		</b-card-header>
		<b-card-body>
			<b-form-select :value="null">
				<template v-slot:first>
					<b-form-select-option :value="null">
						2021
					</b-form-select-option>
				</template>
			</b-form-select>
			<Speedometer :circulation="3" :threshold="19" />
			<h6>
				{{ $t('on') }} 1920 {{ $t('planned-circulations') }}
			</h6>
		</b-card-body>
	</b-card>
</template>
<script>
import Speedometer from './components/Speedometer';
export default {
	components : {
		Speedometer,
	}

};
</script>