<template>
	<b-card no-body id="calendar" class="identification-components">
		<b-card-header>
			{{ $t('calendar') }}
		</b-card-header>
		<b-card-body>
			<ul>
				<li>
					<span>{{ $t('date-planning') }} :</span>
					<b>{{ calendar.planning_date | getDateMonthYear }}</b>
				</li>
				<li>
					<span>{{ $t('start-date') }} :</span>
					<b>{{ calendar.ini_date | getDateMonthYear }}</b>
				</li>
				<li>
					<span>{{ $t('end-date') }} :</span>
					<b>{{ calendar.end_date | getDateMonthYear }}</b>
				</li>
				<li>
					<span>{{ $t('operational-weeks') }} :</span>
					<b>{{ calendar.working_weeks }}</b>
				</li>
				<li>
					<span>{{ $t('unemployed-weeks') }} :</span>
					<b v-if="calendar.weeks_off">{{ calendar.weeks_off.join() }}</b>
				</li>
			</ul>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	name : 'ContractCalendar',

	props : {
		calendar : {
			type     : Object,
			required : false,
			default() {
				return {};
			}
		},
	}
};
</script>
<style lang="scss" scoped>
ul li span {
	min-width: 180px;
}
</style>