<template>
	<div id="add-wagon">
		<h1>{{ $t('create-wagon') }}</h1>
		<div class="d-flex justify-content-between align-items-baseline">
			<Breadcrumbs />
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)">
				<div class="icon arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<b-button variant="primary" v-b-toggle="'identification'" class="btn-collapse">
			{{ $t('information-id') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="identification" visible accordion="accordion">
			<b-card>
				<Identification />
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'location'" class="btn-collapse">
			{{ $t('information-rental') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="location" accordion="accordion">
			<b-card>
				<Locations />
			</b-card>
		</b-collapse>

		<b-button variant="primary" v-b-toggle="'maintenance'" class="btn-collapse">
			{{ $t('information-maintenance') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="maintenance" accordion="accordion">
			<b-card>
				<Maintenance />
			</b-card>
		</b-collapse>
		<!--div>
			<b-card-header v-b-toggle.collapse-comments class="primary d-flex justify-content-between">
				{{ $t('comments') }}
				<div class="icon chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-comments" accordion="accordion">
				<b-card-body>
					<Comments />
				</b-card-body>
			</b-collapse>
		</-div-->
		<div class="d-flex justify-content-center mt-3">
			<b-button class="btn-icon" variant="primary"
				@click="$router.push({name: 'WagonList'})">
				<div class="icon save" />
				{{ $t('save') }}
			</b-button>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from '../wagons/components/Breadcrumbs.vue';
import Identification from '../wagons/components/Identification.vue';
import Locations from '../wagons/components/Locations.vue';
import Maintenance from '../wagons/components/Maintenance.vue';
//import Comments from '../wagons/components/Comments.vue';

export default {
	name       : 'CreateWagon',
	components : {
		Breadcrumbs,
		Identification,
		Locations,
		Maintenance,
		//Comments
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('wagons'),
		};
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>