<template>
	<div id="update-wagon">
		<div class="d-flex justify-content-between align-items-center">
			<h1 class="mb-4">
				{{ $t('update-wagon') }}
			</h1>
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)">
				<div class="icon arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-identification class="primary d-flex justify-content-between">
				{{ $t('information-id') }}
				<div class="icon chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-identification" visible accordion="accordion" class="mt-2">
				<b-card-body>
					<Identification :wagon="wagon" />
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-locations class="primary d-flex justify-content-between">
				{{ $t('information-rental') }}
				<div class="icon chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-locations" accordion="accordion" class="mt-2">
				<b-card-body>
					<Locations :wagon="wagon" />
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-maintenance class="primary d-flex justify-content-between">
				{{ $t('information-maintenance') }}
				<div class="icon chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-maintenance" accordion="accordion" class="mt-2">
				<b-card-body>
					<Maintenance :wagon="wagon" />
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-comments class="primary d-flex justify-content-between">
				{{ $t('comments') }}
				<div class="icon chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-comments" accordion="accordion" class="mt-2">
				<b-card-body>
					<Comments :wagon="wagon" />
				</b-card-body>
			</b-collapse>
		</div>
		<div class="d-flex justify-content-center mt-3">
			<b-button class="btn-icon" variant="primary"
				@click="$router.push({name: 'WagonList'})">
				<div class="icon save" />
				{{ $t('save') }}
			</b-button>
		</div>
	</div>
</template>

<script>
import Identification from '../wagons/components/Identification.vue';
import Locations from '../wagons/components/Locations.vue';
import Maintenance from '../wagons/components/Maintenance.vue';
import Comments from '../wagons/components/Comments.vue';


export default {
	name       : 'UpdateWagon',
	components : {
		Identification,
		Locations,
		Maintenance,
		Comments
	},
	created() {
		// to do : récupérer données de locations, maintenance, comments
		this.getData();
		const id = this.$route.params.id;
		this.wagon = this.wagons.find(wagon => wagon._id === id);
	},

	data() {
		return {
			wagon  : {},
			wagons : [],
		};
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			this.wagons  = require('@/utils/fakeData/' + user.company.name + '/mockWagons.json');
		}
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>