<template>
	<b-card no-body class="h-100">
		<b-card-header>
			{{ $t('changes-conditions') }}
		</b-card-header>
		<b-card-body class="d-flex flex-column justify-content-center align-items-center">
			<div class="technical-informations light">
				<ul>
					<li>
						<span>{{ $t('day-changes') }} S-1 ({{ $t('wednesday') }})</span>
						<span>1</span>
					</li>
					<li>
						<span>{{ $t('direction-changes') }} ({{ $t('wednesday') }})</span>
						<span>2</span>
					</li>
				</ul>
			</div>

			<p class="text-grey mt-3">
				{{ $t('times-per-weeks') }}
			</p>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	name : 'Changes'
};
</script>
<style lang="scss" scoped>
.technical-informations ul li span {
	&:first-of-type {
		width: 90%;
	}
	&:last-of-type {
		width: 10%;
	}
}
</style>