<template>
	<form @submit="submit">
		<b-form-group :label="$t('company-name')">
			<b-input placeholder="_" />
		</b-form-group>
		<b-form-group :label="$t('company-type')">
			<b-form-select :value="null">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
		<b-form-group :label="$t('siren-number')">
			<b-input placeholder="_" type="number" />
		</b-form-group>
		<b-form-group :label="$t('nic-number')">
			<b-input placeholder="_" type="number" />
		</b-form-group>
		<b-form-group :label="$t('vat-number')">
			<b-input placeholder="_" type="number" />
		</b-form-group>
		<div class="w-100 mt-3 d-flex justify-content-end">
			<b-button variant="primary" class="mb-3 w-100" @click="submit">
				{{ $t('next') }}
			</b-button>
		</div>
	</form>
</template>

<script>
export default {
	name    : 'Society',
	methods : {
		submit($event) {
			$event.preventDefault();
			this.$emit('go-to-step', 'address');
		}
	}
};
</script>