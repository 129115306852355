<template>
	<div id="state-incidents">
		<Header />
		<b-card-body class="grey rounded">
			<b-row>
				<b-col lg="6" md="12">
					<div class="group-inline">
						<label>{{ $t('type') }}</label>
						<b-form-select class="content" v-model="incident.type" :options="typesOfIncident">
							<template v-slot:first>
								<b-form-select-option :value="null" disabled>
									_
								</b-form-select-option>
							</template>
						</b-form-select>
					</div>
				</b-col>
				<b-col lg="6" md="12">
					<div class="group-inline">
						<label>{{ $t('title') }}</label>
						<b-input type="text" placeholder="_" class="content" v-model="incident.title" />
					</div>
				</b-col>
				<b-col lg="6" md="12">
					<div class="group-block">
						<label>{{ $t('wagons') }}</label>
						<multi-select class="content" v-model="incident.wagons"
							:deselect-label="$t('press-enter-to-remove')" :selected-label="$t('selected')"
							select-label="" placeholder="_" :searchable="true" label="code" track-by="code"
							:multiple="true" :options="wagons" :hide-selected="true">
							<template slot="noResult">
								{{ $t('no-result') }}
							</template>
						</multi-select>
					</div>
				</b-col>
				<b-col lg="6" md="12">
					<div class="group-block">
						<label>{{ $t('description') }}</label>
						<b-form-textarea class="content" v-model="incident.description" placeholder="_"
							no-resize max-rows="8" />
					</div>
				</b-col>
				<b-col lg="12" md="12">
					<div class="group-inline">
						<label for="file">{{ $t('picture') }}</label>
						<b-form-file id="file" :placeholder="$t('join-a-file')" class="content" />
					</div>
				</b-col>
				<b-col md="6" sm="12">
					<b-form-group :label="$t('date')">
						<b-form-input type="date" v-model="incident.date" placeholder="_" />
					</b-form-group>
				</b-col>
				<b-col md="6" sm="12">
					<b-form-group :label="$t('hours')">
						<b-form-input type="time" v-model="incident.time" placeholder="_" />
					</b-form-group>
				</b-col>
				<b-col sm="6" class="d-flex justify-content-end mt-3">
					<b-button variant="info" @click="$router.go(-1)">
						{{ $t('cancel') }}
					</b-button>
				</b-col>
				<b-col sm="6" class="mt-3">
					<b-button variant="primary" @click="addIncident" :class="getClass">
						<span v-if="this.query && this.query.index">{{ $t('edit') }}</span>
						<span v-else>{{ $t('report') }}</span>
					</b-button>
				</b-col>
			</b-row>
		</b-card-body>
	</div>
</template>
<script>
import Header from './components/Header';
export default {
	data() {
		return {
			wagons          : [],
			incident        : {
				id          : Date.now(),
				type        : null,
				title       : '',
				wagons      : [],
				description : '',
				date        : this.toDateInput(new Date()),
				time        : this.toTimeInput(new Date())
			},
			query : this.$route.query
		};
	},
	components : {
		Header
	},
	created() {
		const query = this.$route.query;
		if(query && query.index) {
			this.incident = this.$store.getters['operation/get'].incidents[query.index];
		}
		this.getData();
	},
	computed : {
		userIsDriver() {
			return JSON.parse(this.$store.getters['login/user']).scope.includes('driver');
		},
		userIsHandler() {
			return JSON.parse(this.$store.getters['login/user']).scope.includes('handler');
		},
		typesOfIncident() {
			return [
				this._i18n.t('wagon'),
				this._i18n.t('order'),
				this._i18n.t('segment'),
				this._i18n.t('other'),
			];
		},
		getClass() {
			const incident = this.incident;
			if(!incident.type || !incident.title || !incident.wagons.length || !incident.description) {
				return 'disabled';
			}
			return '';
		}
	},
	methods : {
		addIncident() {
			const query = this.$route.query;
			if(query && query.index) {
				this.$store.commit('operation/editIncident', this.incident, query.index);
				this.$router.push('/operation/state');
			} else {
				this.$store.commit('operation/pushIncident', this.incident);
				this.$router.push('/operation/state');
			}
		},
		getData() {
			const user = JSON.parse(localStorage.user);
			this.wagons  = require('@/utils/fakeData/' + user.company.name + '/mockWagons.json');
		}
	}
};
</script>