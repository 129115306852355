<template>
	<div>
		<b-card no-body id="provider" class="identification-components" v-for="(actor, index) in providers"
			:key="index">
			<b-card-header>
				{{ $t(actor.type) }} - {{ actor.name }}
			</b-card-header>
			<b-card-body>
				<ul>
					<li>
						<p class="title-components">
							{{ actor.name }}
						</p>
						<p>{{ $t('siren') }} : {{ actor.siret.siren }}</p>
						<p>{{ $t('nic') }} : {{ actor.siret.nic }}</p>
						<p>{{ $t('vat-number') }} : {{ actor.vat }}</p>
						<p>
							{{ actor.address.number }} {{ actor.address.street }},
							{{ actor.address.city }}, {{ actor.address.country }}
						</p>
					</li>
					<li v-for="(contact, ind) in actor.contacts" :key="ind">
						<p class="title-components">
							{{ $t('contact') }}
						</p>
						<p>{{ contact.firstname }} {{ contact.lastname }}</p>
						<p>{{ $t('phone') }}: {{ contact.phone }}</p>
						<p>{{ contact.email }}</p>
						<p>
							{{ contact.address.number }} {{ contact.address.street }},
							{{ contact.address.city }}, {{ contact.address.country }}
						</p>
					</li>
				</ul>
			</b-card-body>
		</b-card>
	</div>
</template>
<script>
export default {
	name       : 'Providers',
	props : {
		providers : {
			type     : Array,
			required : true,
		}
	}
};
</script>