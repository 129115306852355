<template>
	<div id="identification">
		<b-row>
			<b-col lg="6">
				<b-form-group :label="$t('code')">
					<b-form-input type="number" placeholder="_" v-model="wagon.code" />
				</b-form-group>

				<b-form-group :label="$t('length')+('(m)')">
					<b-form-input type="number" placeholder="_" v-model="wagon.length" />
				</b-form-group>

				<b-form-group :label="$t('weight-brakes')+('(T)')">
					<b-form-input type="number" placeholder="_" v-model="wagon.weight_brakes" />
				</b-form-group>

				<b-form-group :label="$t('family')">
					<b-form-input placeholder="_" v-model="wagon.family" />
				</b-form-group>

				<b-form-group :label="$t('evp')">
					<b-form-input type="number" placeholder="_" v-model="wagon.evp" />
				</b-form-group>

				<b-form-group :label="$t('current-mileage')">
					<b-form-input type="number" placeholder="_" v-model="wagon.km_cov" />
				</b-form-group>
			</b-col>
			<b-col lg="6">
				<b-form-group :label="$t('type')">
					<b-form-input placeholder="_" v-model="wagon.type" />
				</b-form-group>

				<b-form-group :label="$t('number-axles')">
					<b-form-input type="number" placeholder="_" v-model="wagon.nb_essieu" />
				</b-form-group>

				<b-form-group :label="$t('gross-total-mass')+('(T)')">
					<b-form-input type="number" placeholder="_" v-model="wagon.total_mass" />
				</b-form-group>

				<b-form-group :label="$t('tare')+('(T)')">
					<b-form-input type="number" placeholder="_" v-model="wagon.tare" />
				</b-form-group>

				<b-form-group :label="$t('payload')+('(T)')">
					<b-form-input type="number" placeholder="_" v-model="wagon.ch_utile" />
				</b-form-group>

				<b-form-group :label="$t('owner')">
					<b-form-input placeholder="_" v-model="wagon.owner.name" />
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name  : 'Identification',
	props : {
		wagon : {
			type     : Object,
			required : false,
			default() {
				return {
					owner : {}
				};
			}
		},
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>