<template>
	<div v-if="dateList" class="d-flex flex-column align-items-center width-content">
		<ul class="date-list child-mx-1">
			<li :class="setColors(1)">
				L
			</li>
			<li :class="setColors(2)">
				M
			</li>
			<li :class="setColors(3)">
				M
			</li>
			<li :class="setColors(4)">
				J
			</li>
			<li :class="setColors(5)">
				V
			</li>
			<li :class="setColors(6)">
				S
			</li>
			<li :class="setColors(7)">
				D
			</li>
		</ul>
		<p v-if="segment.state==='maintenance'" class="text-grey text-center my-1">
			{{ $t('requested_time') }}
		</p>
	</div>
</template>

<script>
export default {
	name  : 'DateList',
	props : {
		dateList : {
			type     : Object,
			required : false,
			default() {
				return {};
			}
		},
		segment : {
			type     : Object,
			required : false,
			default() {
				return {};
			}
		}
	},
	methods : {
		setColors(date) {
			const dateList = this.dateList;
			if(!dateList) return;
			if(dateList.days && dateList.days.includes(date)) {
				return 'active';
			} else if(dateList.desactive && dateList.desactive.includes(date)) {
				return 'desactive';
			} else if(dateList.primary && dateList.primary.includes(date)) {
				return 'primary';
			} else if(dateList.grey && dateList.grey.includes(date)) {
				return 'grey';
			} else {
				return '';
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.date-list {
	display: flex;
}
.date-list li {
	font-weight: 700;
	width: 30px;
	height: 30px;
	border-radius: $border-radius;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $light-grey;
}
.date-list li.active {
	background-color: $green;
}
.date-list li.desactive {
	background-color: $red;
}
.date-list li.primary {
	background-color: $primary;
}
.date-list li.grey {
	background-color: $grey;
}
.maintenance-msg {
	font-size: smaller;
	color: $grey;
}
</style>