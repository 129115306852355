<template>
	<main id="requester">
		<div class="d-flex justify-content-between align-items-center">
			<h1 class="font-header mb-4">
				{{ $t('order-requester') }}
			</h1>
			<b-button variant="primary" class="btn-icon">
				<div class="icon download" />
				{{ $t('extract') }}
			</b-button>
		</div>
		<b-row class="mt-3">
			<b-col sm="12">
				<b-card no-body class="mb-0">
					<div class="card-header">
						<button type="button" v-b-toggle:collapseclient id="filter"
							class="btn rounded mr-3 ml-3 btn-outline-light">
							<span class="glyphicon glyphicon-cog" /> {{ $t('identity-input') }}
						</button>

						<button type="button" v-b-toggle:collapserail id="filter"
							class="btn rounded mr-3 btn-outline-light">
							{{ $t('routes-input') }}
						</button>
						<button type="button" v-b-toggle:collapseothers id="filter"
							class="btn rounded mr-3 btn-outline-light">
							{{ $t('others-input') }}
						</button>
					</div>
					<b-collapse id="collapseclient">
						<div class="card-body">
							<b-form-tags v-model="clientItems" add-on-change no-outer-focus class="border-0">
								<template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
									<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-1">
										<li v-for="tag in tags" :key="tag" class="list-inline-item">
											<b-form-tag @remove="removeTag(tag)" :title="tag"
												:disabled="disabled" variant="primary">
												{{ tag }}
											</b-form-tag>
										</li>
									</ul>
									<b-form-select v-bind="inputAttrs" class="input-round" v-on="inputHandlers"
										:options="getOptionsMultiple(internalSchemaclient, clientItems)"
										:disabled="disabled ||
											getOptionsMultiple(internalSchemaclient, clientItems).length === 0">
										<template v-slot:first>
											<!-- This is required to prevent bugs with Safari -->
											<option disabled value="">
												{{ $t('identity-input') }}
											</option>
										</template>
									</b-form-select>
								</template>
							</b-form-tags>
						</div>
					</b-collapse>


					<b-collapse id="collapserail">
						<div class="card-body">
							<b-form-tags v-model="railItems" add-on-change no-outer-focus class="border-0">
								<template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
									<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-1">
										<li v-for="tag in tags" :key="tag" class="list-inline-item">
											<b-form-tag @remove="removeTag(tag)" :title="tag"
												:disabled="disabled" variant="primary">
												{{ tag }}
											</b-form-tag>
										</li>
									</ul>
									<b-form-select v-bind="inputAttrs" class="input-round" v-on="inputHandlers"
										:options="getOptionsMultiple(internalSchemaroute, railItems)"
										:disabled="disabled ||
											getOptionsMultiple(internalSchemaroute, railItems).length === 0">
										<template v-slot:first>
											<!-- This is required to prevent bugs with Safari -->
											<option disabled value="">
												{{ $t('routes-input') }}
											</option>
										</template>
									</b-form-select>
								</template>
							</b-form-tags>
						</div>
					</b-collapse>
					<b-collapse id="collapseothers">
						<div class="card-body">
							<b-form-tags v-model="otherItems" add-on-change no-outer-focus class="border-0">
								<template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
									<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-1">
										<li v-for="tag in tags" :key="tag" class="list-inline-item">
											<b-form-tag @remove="removeTag(tag)" :title="tag"
												:disabled="disabled" variant="primary">
												{{ tag }}
											</b-form-tag>
										</li>
									</ul>
									<b-form-select v-bind="inputAttrs" class="input-round" v-on="inputHandlers"
										:options="getOptionsMultiple(internalSchemaOther, otherItems)"
										:disabled="disabled ||
											getOptionsMultiple(internalSchemaOther, otherItems).length === 0">
										<template v-slot:first>
											<!-- This is required to prevent bugs with Safari -->
											<option disabled value="">
												{{ $t('others-input') }}
											</option>
										</template>
									</b-form-select>
								</template>
							</b-form-tags>
						</div>
					</b-collapse>
					<b-card-footer class="p-0 d-flex justify-content-center"
						v-b-toggle="['collapseclient', 'collapserail', 'collapseothers']">
						<div class="toggle-details">
							<div class="icon chevron-down when-closed primary" />
						</div>
					</b-card-footer>
				</b-card>
			</b-col>
		</b-row>

		<div class="tab-header border-radius-top mt-3">
			<Filters>
				<Clients />
				<Roads :roads="routes" />
				<DepartureDateEarly />
				<DepartureDateLate />
			</Filters>
		</div>
		<b-table head-variant="light" :striped="true" hover responsive show-empty
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="items"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(type)="data">
				{{ $t(data.item.type) }}
			</template>
			<template #cell(status)="data">
				{{ $t(data.item.status) }}
			</template>
			<template #cell(route)="data">
				{{ displayRoute(data.item.trs[0]) }}
			</template>
			<template #cell(departure_date)="data">
				{{ (data.item.departure_date) | getFullDate }}
			</template>
			<template #cell(arrival_date)="data">
				{{ (data.item.arrival_date) | getFullDate }}
			</template>
		</b-table>

		<Pagination :pagination="pagination" />
	</main>
</template>

<script>
import Filters from '@/components/filters/Filters';
import { Clients, Roads } from '@/components/filters/components';
import { DepartureDateEarly, DepartureDateLate } from '@/components/filters/components/requester';
import Pagination from '@/components/Pagination.vue';


export default {
	name : 'Requester',
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('requester'),
		};
	},
	components : {
		Filters,
		Clients,
		Roads,
		DepartureDateEarly,
		DepartureDateLate,
		Pagination
	},
	computed : {
		routes() {
			const routes = this.contracts.routes;
			const display_route = routes.map(ro => {
				const name = ro.sites.map(si=>si.name).join(' > ');
				return {
					name,
					_id : ro._id
				};
			});
			return display_route;
		},
		fields() {
			return [
				{
					label    : this._i18n.t('order-code'),
					key      : 'int_code',
					sortable : true,
				},
				{
					label    : this._i18n.t('order-type'),
					key      : 'type',
					sortable : true,
				},
				{
					label    : this._i18n.t('client'),
					key      : 'client.name',
					sortable : true,
				},
				{
					label    : this._i18n.t('ref-client'),
					key      : 'client_code',
					sortable : true,
				},
				{
					label    : this._i18n.t('route'),
					key      : 'route',
					sortable : true,
				},

				{
					label : this._i18n.t('departure-date'),
					key   : 'departure_date',
				},
				{
					label : this._i18n.t('arrival-date'),
					key   : 'arrival_date',
				},
				{
					label : this._i18n.t('status'),
					key   : 'status',
				},
				{
					label : this._i18n.t('unit-cost'),
					key   : 'cost',
				},
				{
					label : this._i18n.t('extra-cost'),
					key   : 'extra_cost',
				},
				{
					label : this._i18n.t('total-cost'),
					key   : 'total_cost',
				},
				{
					label : this._i18n.t('preinvoice-code'),
					key   : 'preinvoice_code',
				},
				{
					label : this._i18n.t('co2'),
					key   : 'co2',
				},

			];
		}
	},
	data() {
		return {
			items     : [],
			contracts : [],
			clients   : [
				{
					_id  : 1,
					name : 'Sénalia'
				},
				{
					_id  : 2,
					name : 'Monoprix'
				},
				{
					_id  : 3,
					name : 'Carrefour'
				},
			],
			pagination : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			},
			internalSchemaclient : [
				{ label : this.$i18n.t('order-code'),
					key   : 'int_code',
					group : this.$i18n.t('identity-input') },
				{ label : this.$i18n.t('type'),
					key   : 'order_type',
					group : this.$i18n.t('identity-input') },

				{ label : this.$i18n.t('client'),
					key   : 'client',
					group : this.$i18n.t('identity-input') },

				{ label : this.$i18n.t('ref-client'),
					key   : 'ref_client',
					group : this.$i18n.t('identity-input') },
			],
			internalSchemaroute : [
				{ label : this.$i18n.t('routes'),
					key   : 'trainroute',
					group : this.$i18n.t('routes-input') },
				{ label : this.$i18n.t('origine'),
					key   : 'origine',
					group : this.$i18n.t('routes-input') },
				{ label : this.$i18n.t('destination'),
					key   : 'destination',
					group : this.$i18n.t('routes-input') },
				{ label : this.$i18n.t('departure-date'),
					key   : 'departure_date',
					group : this.$i18n.t('routes-input') },
				{ label : this.$i18n.t('arrival-date'),
					key   : 'arrival_date',
					group : this.$i18n.t('routes-input') },
			],
			internalSchemaOther : [
				{ label : this.$i18n.t('status'),
					key   : 'status',
					group : this.$i18n.t('others-input') },
				{ label : this.$i18n.t('unit-cost'),
					key   : 'cost',
					group : this.$i18n.t('others-input') },
				{ label : this.$i18n.t('extra-cost'),
					key   : 'extra_cost',
					group : this.$i18n.t('others-input') },
				{ label : this.$i18n.t('total-cost'),
					key   : 'totalcost',
					group : this.$i18n.t('others-input') },
				{ label : this.$i18n.t('preinvoice-code'),
					key   : 'preinvoice_code',
					group : this.$i18n.t('others-input') },
				{ label : this.$i18n.t('co2'),
					key   : 'co2',
					group : this.$i18n.t('others-input') },

			],
			clientItems : [
				this.$i18n.t('client'), // client
				this.$i18n.t('type'), // client
				this.$i18n.t('order-code'), // code
				this.$i18n.t('ref-client'), // ref_client
			],
			railItems   : [
				this.$i18n.t('routes'), // trainroute
				this.$i18n.t('origine'), // terminal_arrival
				this.$i18n.t('destination'), // terminal_arrival
				this.$i18n.t('departure-date'), // departure_date
				this.$i18n.t('arrival-date'), // arrival_date
			],
			otherItems   : [
				this.$i18n.t('status'),
				this.$i18n.t('unit-cost'),
				this.$i18n.t('extra-cost'),
				this.$i18n.t('total-cost'),
				this.$i18n.t('preinvoice-code'),
				this.$i18n.t('co2')
			],
		};
	},
	methods : {
		getOptionsMultiple(options, filter) {
			if (filter) {
				const optionsFiltered = options.filter((option) => filter.indexOf(option.label) === -1);
				return optionsFiltered.map((item) => item.label);
			}
			return options.map((item) => item.label);
		},
		displayRoute(route) {
			// Get only sites
			const sites = [];
			for (let index = 0; index < route.segments.length; index++) {
				if (index === 0) {
					sites.push(route.segments[index].origin.name);
				}
				sites.push(route.segments[index].destination.name);
			}
			return sites.join('>');
		},
		getData() {
			const user = JSON.parse(localStorage.user);
			this.contracts = require('@/utils/fakeData/' + user.company.name + '/mockContract.json');
			this.items  = require('@/utils/fakeData/' + user.company.name + '/mockOrder.json');
			this.pagination.totalRows = this.items.length;
		}
	},
	created() {
		this.getData();
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.btn-outline-light.not-collapsed {
	background-color: white !important;
	color: $primary;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
	display: none;
}
.toggle-details {
	display: flex;
	align-items: center;
	transition: transform $transition;
	margin: 0.5rem 0;
}
.card-footer.not-collapsed .toggle-details {
	transform: rotate(180deg);
}
</style>
