<template>
	<div id="ModalFields">
		<b-row>
			<b-col lg="4">
				<b-form-group :label="$t('type-modal')">
					<b-form-select v-model="modal">
						<option v-for="(option, key) in options.modal" :key="key" :value="option">
							{{ $t(option) }}
						</option>
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>
								_
							</b-form-select-option>
						</template>
					</b-form-select>
				</b-form-group>
			</b-col>
		</b-row>
		<div v-if="modal == 'rail'">
			<ModalRail :address="address" />
		</div>
		<div v-if="modal == 'road'">
			<ModalRoute :address="address" />
		</div>
	</div>
</template>

<script>
import ModalRail from '../components/modals/ModalRail.vue';
import ModalRoute from '../components/modals/ModalRoute.vue';

export default {
	name       : 'ModalFields',
	components : {
		ModalRail,
		ModalRoute,
	},
	props : {
		location : {
			type     : Object,
			required : false,
			default() {
				return {
				};
			}
		},
		address : {
			type     : Array,
			required : true,
		}
	},

	data() {
		return {
			modal    : null,
			options : {
				modal : [
					'rail',
					'road'
				],
			},
		};
	}
};
</script>