<template>
	<ul>
		<li>
			<b-button variant="primary" class="btn-round-icon" @click.stop>
				<div class="icon edit" />
			</b-button>
		</li>
		<li>
			<b-button variant="primary" class="btn-round-icon" @click.stop>
				<div class="icon comments" />
			</b-button>
		</li>
		<li>
			<b-button variant="primary" class="btn-round-icon" @click.stop>
				<div class="icon warning" />
			</b-button>
		</li>
		<li>
			<b-button variant="primary" class="btn-round-icon" @click.stop>
				<div class="icon eye" />
			</b-button>
		</li>
		<li>
			<b-button variant="primary" class="btn-round-icon" @click.stop>
				<div class="icon flag" />
			</b-button>
		</li>
		<li>
			<b-button variant="primary" class="btn-round-icon" @click.stop>
				<div class="icon power" />
			</b-button>
		</li>
	</ul>
</template>
<script>
export default {
	name : 'Actions',
};
</script>
<style lang="scss" scoped>
ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 130px;
}
li {
	margin: 0 0.25rem 0.25rem 0.25rem;
}
</style>