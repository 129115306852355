<template>
	<main id="login">
		<router-link to="/register/society" class="float-right">
			<b-button variant="light">
				{{ $t('register') }}
			</b-button>
		</router-link>
		<form @submit="login">
			<router-link to="/" class="mb-4">
				<img src="@/assets/img/logo_ecopms_primary.png" id="logo" />
			</router-link>
			<b-input class="mb-3" :placeholder="$t('email')" v-model="user.email" name="email" />
			<b-input class="mb-3" :placeholder="$t('password')" type="password"
				v-model="user.password" name="password" />

			<b-button variant="primary" type="submit" class="btn-icon justify-content-center">
				<div class="icon log-in" />
				{{ $t('login') }}
			</b-button>
			<router-link to="/register" class="mt-3 align-self-end">
				{{ $t('password-forget') }}
			</router-link>
		</form>
	</main>
</template>

<script>
export default {
	name : 'Login',
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('login'),
		};
	},
	data() {
		return {
			user : {
				email    : 'raphael@mcledger.io',
				password : 'qsdfg1234'
			}
		};
	},
	methods : {
		login() {
			event.preventDefault();
			this.$loading.state = true;
			this.$store.commit('alert/DISMISS');
			this.$store.dispatch('user/LOGIN', this.user)
				.then(() => {
					this.$loading.state = false;
					this.$router.push('/');
				})
				.catch((err) => {
					this.$loading.state = false;
					if(err == 'ULGE008') {
						this.$router.push('/register/confirmation');
					} else if(['ULGE007', 'ULGE009', 'ULGE010', 'ULGEMAX'].includes(err)) {
						this.$store.commit('alert/SHOW',
							{
								message : this._i18n.t(err),
								variant : 'danger'
							}
						);
					} else {
						this.$store.commit('alert/SHOW',
							{
								message : this._i18n.t('internal-problem'),
								variant : 'danger'
							}
						);
					}
				});
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
main {
	width: 100%;
	height: 100vh;
}
form {
	width: calc(100% - 40px);
	max-width: 400px;
	display: flex;
	flex-direction: column;
	border-radius: $border-radius;
	box-shadow: 0 0 5px $black;
	height: fit-content;
	background-color: $white;
	justify-content: center;
	align-items: center;
	padding: 25px 50px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
form .form-group {
	width: 100%;
}
button {
	width: 100%;
}
a {
	display: inline-block;
}
#logo {
	width: 250px;
}

@include media-breakpoint-down(xs) {
	form {
		padding: 25px;
	}
}
</style>
<i18n>
{
	"fr" : {
		"ULGE007": "L'email n'est pas connu",
		"ULGE009": "Votre compte n'a pas encore été activé",
		"ULGE010": "Mauvais mot de passe",
		"ULGEMAX": "Vous avez atteint le nombre maximum de tentatives de connexion. Veuillez réessayer après 5 minutes."
	},
	"en" : {
		"ULGE007": "The email is not known",
		"ULGE009": "Your account has not yet been activated",
		"ULGE010": "Wrong password",
		"ULGEMAX": "Your reached maximum number of login attempts. Please retry after 5 minutes."
	}
}
</i18n>
