<template>
	<b-card no-body>
		<b-card-header>
			{{ $t('dates') }}
		</b-card-header>
		<b-card-body>
			<div class="d-flex justify-content-between">
				<ul class="legends">
					<li>
						<span class="plan" />
						<span>{{ $t('already-planned') }}</span>
					</li>
					<li class="ml-3">
						<span class="square" />
						<span>{{ $t('not-available') }}</span>
					</li>
				</ul>
				<div v-if="order.date" v-b-modal.others-roads-modal
					class="icon primary information pointer outline-none" />
			</div>
			<b-calendar class="w-100 mt-3" :value="new Date()"
				:label-no-date-selected="$t('no-date-selected')" :locale="_i18n.locale"
				:date-info-fn="datePlanned" :date-disabled-fn="dateDisabled" v-model="order.date" />
			<b-modal id="others-roads-modal" title="Autres routes qui circulent ce jour" size="xl" scrollable>
				<div class="list">
					<div class="container-item" v-for=" (transportPlan, index) in transportPlans" :key="index">
						<div class="item">
							<Informations :transport-plan="transportPlan" />
							<HorizontalVisualization :segments="transportPlan.segments" class="mt-3" />
						</div>
					</div>
				</div>
			</b-modal>
		</b-card-body>
	</b-card>
</template>
<script>
import Informations from '@/components/segments/Informations';
import HorizontalVisualization from '@/components/segments/HorizontalVisualization';
export default {
	name       : 'Dates',
	components : {
		Informations,
		HorizontalVisualization
	},
	props : {
		order : {
			required : true,
			type     : Object,
		}
	},
	data() {
		return {
			transportPlans : [
				{
					'segments' : [
						{
							'type'     : 'road',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'BECKER',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'MOULINS-ENGILBERT',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'Pick-up_posit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'MOULINS-ENGILBERT',
								'origin_id' : '507f1f77bcft86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'APROPORT CHALON',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'posit_deposit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'APROPORT CHALON',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'unplanned',
							'testination' : {
								'name'           : 'EUROFOS',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'interoperability_time' : 0
						}
					],
					'_id'    : '507f1f77baf86cd799439011',
					'road'   : '101VW',
					'origin' : {
						name : 'VOLVIC'
					},
					'destination' : {
						name : 'VOLVIC'
					}
				},
				{
					'segments' : [
						{
							'type'     : 'road',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'BECKER',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'MOULINS-ENGILBERT',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'Pick-up_posit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'MOULINS-ENGILBERT',
								'origin_id' : '507f1f77bcft86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'APROPORT CHALON',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'posit_deposit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'APROPORT CHALON',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'unplanned',
							'testination' : {
								'name'           : 'EUROFOS',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'interoperability_time' : 0
						}
					],
					'_id'    : '507f1f77bafc86cd799439011',
					'road'   : '101VW',
					'origin' : {
						name : 'VOLVIC'
					},
					'destination' : {
						name : 'VOLVIC'
					}
				},
				{
					'segments' : [
						{
							'type'     : 'road',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'BECKER',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'MOULINS-ENGILBERT',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'Pick-up_posit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'MOULINS-ENGILBERT',
								'origin_id' : '507f1f77bcft86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'APROPORT CHALON',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'posit_deposit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'APROPORT CHALON',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'unplanned',
							'testination' : {
								'name'           : 'EUROFOS',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'interoperability_time' : 0
						}
					],
					'_id'    : '507f1f77baf86scd799439011',
					'road'   : '101VW',
					'origin' : {
						name : 'VOLVIC'
					},
					'destination' : {
						name : 'VOLVIC'
					}
				},
				{
					'segments' : [
						{
							'type'     : 'road',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'BECKER',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'MOULINS-ENGILBERT',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'Pick-up_posit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'MOULINS-ENGILBERT',
								'origin_id' : '507f1f77bcft86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'APROPORT CHALON',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'posit_deposit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'APROPORT CHALON',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'unplanned',
							'testination' : {
								'name'           : 'EUROFOS',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'interoperability_time' : 0
						}
					],
					'_id'    : '507f1f77bcf86cd799439011',
					'road'   : '101VW',
					'origin' : {
						name : 'VOLVIC'
					},
					'destination' : {
						name : 'VOLVIC'
					}
				},
				{
					'segments' : [
						{
							'type'     : 'road',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'BECKER',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'MOULINS-ENGILBERT',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'Pick-up_posit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'MOULINS-ENGILBERT',
								'origin_id' : '507f1f77bcft86cd799439011'
							},
							'statut'      : 'finished',
							'testination' : {
								'name'           : 'APROPORT CHALON',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'shipping_type'         : 'posit_deposit',
							'interoperability_time' : 0
						},
						{
							'type'     : 'rail',
							'operator' : '507f1f77bcf86cd799439011',
							'origin'   : {
								'name'      : 'APROPORT CHALON',
								'origin_id' : '507f1f77bcf86cd799439011'
							},
							'statut'      : 'unplanned',
							'testination' : {
								'name'           : 'EUROFOS',
								'destination_id' : '507f1f77bcf86cd799439011'
							},
							'interoperability_time' : 0
						}
					],
					'_id'    : '507f1f77bcf86qcd799439011',
					'road'   : '101VW',
					'origin' : {
						name : 'VOLVIC'
					},
					'destination' : {
						name : 'VOLVIC'
					}
				}
			]
		};
	},
	methods : {
		datePlanned(ymd, date) {
			const day = date.getDate();
			return day >= 10 && day <= 20 ? 'planned' : '';
		},
		dateDisabled(ymd, date) {
			const today = new Date();
			return date < today.setDate(today.getDate() - 1);
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.legends {
	display: flex;
	justify-content: flex-end;
}
.legends li {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: darken($grey, 10%);
}
.legends .square {
	content: '';
	width: 14px;
	height: 14px;
	background-color: $grey;
	border-radius: $border-radius/2;
}
.legends .plan {
	content: '';
	width: 60px;
	height: 10px;
	background-color: $secondary;
	border-radius: 25px;
	margin-bottom: 3px;
	margin-top: 2px;
}
/deep/.b-calendar > div {
	width: 100% !important;
}
/deep/.b-calendar output {
	font-size: 1rem;
	padding: 0.5rem 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	height: fit-content;
}
/deep/.b-calendar .b-calendar-grid {
	border-right: 0;
	border-bottom: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
/deep/.b-calendar .b-calendar-grid header {
	border-right: 1px solid #ced4da;
	font-size: 1rem;
	padding: 1rem;
}
/deep/.b-calendar .b-calendar-grid-body .col[data-date] .btn {
	width: 100% !important;
	height: 100% !important;
	margin: 0 !important;
	border-radius: 0 !important;
	font-size: 1rem;
	text-align: left;
	padding: 0.25rem 2rem 2rem 0.25rem;
	border-right: 1px solid #ced4da !important;
	border-bottom: 1px solid #ced4da !important;
}
/deep/.b-calendar .form-control:focus {
	box-shadow: none;
	border-color: #ced4da;
}
/deep/.b-calendar .b-calendar-grid-weekdays {
	border-right: 1px solid #ced4da;
}
/deep/.b-calendar .b-calendar-grid-weekdays small {
	padding: 0.5rem;
	border-right: 1px solid #ced4da;
	border-top: 1px solid #ced4da;
	font-weight: bold;
	color: $secondary;
	font-size: 1rem;
}
/deep/ .b-calendar .b-calendar-nav .btn {
	padding: 1rem;
}
/deep/ .b-calendar .planned .btn:after {
	content: '';
	display: block;
	position: absolute;
	top: 0.25rem;
	right: 0.25rem;
	width: 50%;
	height: 10px;
	background-color: $secondary;
	border-radius: 25px;
}
/deep/ .b-calendar .planned .btn.active:after {
	background-color: $white;
}
/deep/ .b-calendar .bg-light span {
	color: $white !important;
	background-color: $grey !important;
}
/deep/ .modal-content footer {
	display: none;
}
</style>