<template>
	<div id="ModalRail">
		<b-card no-body>
			<b-card-header>
				{{ $t('modal-rail') }} :
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="4">
						<b-form-group :label="$t('associated-rail-station')">
							<b-form-input type="text" placeholder="_" />
						</b-form-group>
					</b-col>
					<b-col lg="4">
						<b-form-group :label="$t('length-capacity')">
							<b-form-input type="text" placeholder="_" />
						</b-form-group>
					</b-col>
					<b-col lg="4">
						<b-form-group :label="$t('weight-capacity')">
							<b-form-input type="text" placeholder="_" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col lg="4">
						<b-form-group :label="$t('track-number')">
							<b-form-input type="text" placeholder="_" />
						</b-form-group>
					</b-col>
					<b-col lg="4">
						<b-form-group :label="$t('platform-number')">
							<b-form-input type="text" placeholder="_" />
						</b-form-group>
					</b-col>
					<b-col lg="4">
						<b-form-group :label="$t('gps-coordinates')">
							<b-form-input type="text" placeholder="_" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-row>
			<b-col lg="6" class="mt-3">
				<b-card class="h-100" no-body>
					<b-card-header class="d-flex justify-content-between">
						{{ $t('address') }}
						<b-form-checkbox v-model="selected">
							{{ $t('same-address-site') }}
						</b-form-checkbox>
					</b-card-header>
					<b-card-body>
						<b-row class="mt-2">
							<b-col lg="6">
								<b-form-group :label="$t('street-number')">
									<b-form-input v-if="selected" v-model="address.address_number" type="number"
										placeholder="_" />
									<b-form-input v-else type="number" placeholder="_" />
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group :label="$t('street')">
									<b-form-input v-if="selected" v-model="address.address_street" type="text"
										placeholder="_" />
									<b-form-input v-else type="text" placeholder="_" />
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col lg="6">
								<b-form-group :label="$t('city')">
									<b-form-input v-if="selected" v-model="address.address_city" type="text"
										placeholder="_" />
									<b-form-input v-else type="text" placeholder="_" />
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group :label="$t('postal-code')">
									<b-form-input v-if="selected" v-model="address.address_postalcode" type="number"
										placeholder="_" />
									<b-form-input v-else type="number" placeholder="_" />
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col lg="6">
								<b-form-group :label="$t('country')">
									<b-form-input v-if="selected" v-model="address.address_country" type="text"
										placeholder="_" />
									<b-form-input v-else type="text" placeholder="_" />
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group :label="$t('state')">
									<b-form-input v-if="selected" v-model="address.address_state" type="text"
										placeholder="_" />
									<b-form-input v-else type="text" placeholder="_" />
								</b-form-group>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col lg="6" class="mt-3">
				<b-card no-body>
					<b-card-header>
						{{ $t('hours') }}
					</b-card-header>
					<b-card-body>
						<div class="primary d-flex justify-content-between">
							<b-form-group :label="$t('weekday_lo_1')" />
							00:00-00:00
						</div>
						<div class="primary d-flex justify-content-between">
							<b-form-group :label="$t('weekday_lo_2')" />
							00:00-00:00
						</div>
						<div class="primary d-flex justify-content-between">
							<b-form-group :label="$t('weekday_lo_3')" />
							00:00-00:00
						</div>
						<div class="primary d-flex justify-content-between">
							<b-form-group :label="$t('weekday_lo_4')" />
							00:00-00:00
						</div>
						<div class="primary d-flex justify-content-between">
							<b-form-group :label="$t('weekday_lo_5')" />
							00:00-00:00
						</div>
						<div class="primary d-flex justify-content-between">
							<b-form-group :label="$t('weekday_lo_6')" />
							00:00-00:00
						</div>
						<div class="primary d-flex justify-content-between">
							<b-form-group :label="$t('weekday_lo_7')" />
							00:00-00:00
						</div>
						<div class="d-flex justify-content-end">
							<b-button @click="modalShow = !modalShow" variant="primary">
								{{ $t('manage-hours') }} :
							</b-button>
							<b-modal v-model="modalShow" title="Horaires" cancel-title="Annuler" ok-title="Enregistrer">
								<div class="d-flex justify-content-between">
									<b-button v-for="(btn, idx) in buttons" :key="idx"
										:pressed.sync="btn.state" :variant="getBtnVariant(btn.state)">
										{{ btn.caption }}
									</b-button>
								</div>
								<b-row class="mt-2">
									<div class="ml-3">
										<b-form-checkbox>
											<b-form-group :label="$t('closed')" />
										</b-form-checkbox>
									</div>
									<div class="ml-3">
										<b-form-checkbox>
											<b-form-group :label="$t('always-open')" />
										</b-form-checkbox>
									</div>
								</b-row>
								<div class="d-flex justify-content-between">
									<b-form-timepicker size="sm" :label-no-time-selected="$t('open-time-selected')"
										locale="en" />
									_
									<b-form-timepicker size="sm" :label-no-time-selected="$t('close-time-selected')"
										locale="en" />
								</div>
								<div class="d-flex justify-content-end mt-2">
									<b-button class="btn-icon-only" variant="primary">
										<div class="icon add" />
									</b-button>
								</div>
							</b-modal>
						</div>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>

export default {
	name  : 'ModalRail',
	props : {
		address : {
			type     : Array,
			required : true,
		}
	},
	data() {
		return {
			selected  : false,
			modalShow : false,
			buttons   : [
				{ caption : 'L',
					state   : false },
				{ caption : 'M',
					state   : false },
				{ caption : 'M',
					state   : false },
				{ caption : 'J',
					state   : false },
				{ caption : 'V',
					state   : false },
				{ caption : 'S',
					state   : false },
				{ caption : 'D',
					state   : false }
			]
		};
	},
	methods : {
		getBtnVariant(state) {
			if(state) {
				return 'primary';
			} else {
				return 'info';
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.card {
	box-shadow: none;
}
</style>