<template>
	<b-card no-body>
		<b-card-header>
			{{ $t('cancellations') }}
		</b-card-header>
		<b-card-body>
			<h5>{{ $t('title-cancellations') }}</h5>
			<div class="d-flex justify-content-center align-items-center">
				<ul>
					<li>
						<span>{{ $t('week') }} -2</span>
						<b-badge variant="light">
							25%
						</b-badge>
					</li>
					<li>
						<span>{{ $t('week') }} -1</span>
						<b-badge variant="info">
							75%
						</b-badge>
					</li>
					<li>
						<span>0-6 {{ $t('days') }}</span>
						<b-badge variant="danger">
							100%
						</b-badge>
					</li>
				</ul>
				<i>N°{{ $t('cancellations-condition') }} > 1% 40%</i>
			</div>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	name : 'Cancellations'
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/.card-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}
ul {
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
}
i {
	max-width: 140px;
	margin-left: 1rem;
	text-transform: initial;
	color: $grey;
}
ul li {
	margin-top: 0.5rem;
	display: flex;
	width: fit-content;
	align-items: center;
}
ul li span {
	min-width: 75px;
}
/deep/ .badge {
	font-size: 100%;
	width: 55px;
	margin-left: 0.5rem;
	padding: 5px 0 5px 0;
}
</style>