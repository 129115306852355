import Vue from 'vue';
Vue.mixin({
	methods : {
		getNextItemArray(key, array) {
			return array[key + 1];
		},
		getPreviousItemArray(key, array) {
			return array[key - 1];
		},
		setItems(items) {
			this.items = items;
			this.pagination.currentPage = 1;
			this.pagination.totalRows = this.items.length;
		},
		toDateInput(date) {
			var local = new Date(date);
			local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
			return local.toJSON().slice(0, 10);
		},
		toTimeInput(date) {
			return ('0' + date.getHours()).substr(-2) + ':00';
		}
	}
});