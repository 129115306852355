<template>
	<div id="RentalFields">
		<b-card no-body>
			<b-card-header>
				{{ $t('coordinates') }}
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('firstname')">
							<b-form-input type="text" placeholder="_" v-model="registration.user.firstname" />
						</b-form-group>

						<b-form-group :label="$t('phone')">
							<b-form-input type="email" placeholder="_" v-model="registration.user.phone[0]" />
						</b-form-group>

						<b-form-group :label="$t('language')">
							<b-form-input type="text" placeholder="_" v-model="registration.user.language" />
						</b-form-group>

						<b-form-group :label="$t('registration-number')">
							<b-form-input type="text" placeholder="_"
								v-model="registration.user.identification" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('lastname')">
							<b-form-input type="text" placeholder="_" v-model="registration.user.lastname" />
						</b-form-group>

						<b-form-group :label="$t('email')">
							<b-form-input type="email" placeholder="_" v-model="registration.user.email" />
						</b-form-group>

						<b-form-group :label="$t('job')">
							<b-form-input type="text" placeholder="_" v-model="registration.user.job" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-card no-body class="mt-3">
			<b-card-header class="d-flex justify-content-between">
				{{ $t('address') }}
				<b-form-checkbox v-model="selected">
					{{ $t('same-address-site') }}
				</b-form-checkbox>
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('street')">
							<b-form-input v-if="selected" v-model="registration.company.address.street"
								type="text" placeholder="_" />
							<b-form-input v-else type="text" placeholder="_" />
						</b-form-group>
						<b-form-group :label="$t('postal-code')">
							<b-form-input v-if="selected" v-model="registration.company.address.code"
								type="text" placeholder="_" />
							<b-form-input v-else type="text" placeholder="_" />
						</b-form-group>
						<b-form-group :label="$t('department-or-region')">
							<b-form-input v-if="selected" v-model="registration.company.address.department"
								type="text" placeholder="_" />
							<b-form-input v-else type="text" placeholder="_" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('complement-address')">
							<b-form-input v-if="selected" v-model="registration.company.address.other"
								type="text" placeholder="_" />
							<b-form-input v-else type="text" placeholder="_" />
						</b-form-group>
						<b-form-group :label="$t('city')">
							<b-form-input v-if="selected" v-model="registration.company.address.city"
								type="text" placeholder="_" />
							<b-form-input v-else type="text" placeholder="_" />
						</b-form-group>
						<b-form-group :label="$t('country')">
							<b-form-input v-if="selected" v-model="registration.company.address.country"
								type="text" placeholder="_" />
							<b-form-input v-else type="text" placeholder="_" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
export default {
	name       : 'CompanyFields',
	props : {
		registration : {
			type     : Object,
			required : true,
		}
	},

	data() {
		return {
			selected : false
		};
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>