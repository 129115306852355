<template>
	<b-card no-body id="transport-plan" class="identification-components">
		<b-card-header>
			{{ $t('transport-plan') }}
		</b-card-header>
		<b-card-body>
			<ul>
				<li v-for="(route, index) in routes" :key="index">
					<div class="d-flex align-items-center">
						<div class="icon trains primary" v-if="getType(route.type, 'rail')" />
						<div class="icon truck primary" v-if="getType(route.type, 'road')" />
						<div class="icon plane primary" v-if="getType(route.type, 'air')" />
						<div class="icon ship primary" v-if="getType(route.type, 'sea')" />
						<b class="text-secondary nowrap ml-2">{{ $t('road') }} {{ index + 1 }} :</b>
					</div>
					<p>
						<span v-for="(site, i) in route.sites" :key="i">
							{{ site.name }}
							<span v-if="i < (route.sites.length -1)"> > </span>
						</span>
					</p>
					<b class="text-secondary">{{ route.cost }} €/{{ $t('linear-meter') }}</b>
				</li>
			</ul>
			<div class="d-flex justify-content-end">
				<b-button variant="primary" size="sm" class="mt-2" v-b-toggle="'transport-plan'">
					{{ $t('details') }}
				</b-button>
			</div>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	name : 'ContractRoutes',

	props : {
		routes : {
			type     : Array,
			required : false,
			default() {
				return {};
			}
		},
	},
	methods : {
		getType(type, icon) {
			if(Array.isArray(type)) {
				for (let i = 0; i < type.length; i++) {
					const e = type[i];
					if(icon == e) return true;
				}
			} else {
				if(icon == type) return type;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
ul li {
	display: flex;
	justify-content: space-between;
}
ul li span {
	margin-right: 5px;
}
</style>