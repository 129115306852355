<template>
	<div>
		<Charger :contractor="contract.contractor" />
		<Provider class="mt-3" :providers="contract.providers" />
		<TransportPlan :routes="contract.routes" />
		<b-row>
			<b-col lg="6" class="mt-3">
				<Terminal :terminals="contract.locations" />
			</b-col>
			<b-col lg="6" class="mt-3">
				<Segments :segments="contract.segments" />
			</b-col>
			<b-col lg="6" class="mt-3">
				<Calendar :calendar="contract.operation" />
			</b-col>
			<b-col lg="6" class="mt-3">
				<Wagons v-if="false" />
			</b-col>
		</b-row>
	</div>
</template>
<script>

import { Charger, Provider, Terminal, Segments, Calendar, TransportPlan, Wagons } from './components';

export default {
	name       : 'Identification',
	components : {
		Charger,
		Wagons,
		Provider,
		Terminal,
		Segments,
		Calendar,
		TransportPlan
	},
	data() {
		return {
			contract : []
		};
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			this.contract  = require('@/utils/fakeData/' + user.company.name + '/mockContract.json');
		}
	},
	created() {
		this.getData();
	}

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>