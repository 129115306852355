<template>
	<div id="planning">
		<h1>{{ $t('edition-of-orders') }}</h1>
		<b-card no-body class="mt-3">
			<b-card-header class="primary">
				{{ $t('road') }}
			</b-card-header>
			<b-card-body>
				<b-row class="h-100">
					<b-col lg="8" class="max-h-100">
						<Calendar :items="items" @set-items="setItems" />
					</b-col>
					<b-col lg="4" class="child-mt-3">
						<FilterPerRoad @set-items="setItems" :items="items" />
						<SelectDate v-if="items.currentRoad" @set-items="setItems" :items="items" />
						<EditOrder v-if="items.currentRoad || items.selectedDate"
							@set-items="setItems" :items="items" />
						<Cancel v-if="items.selectedDate" />
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>
<script>
import { Calendar, FilterPerRoad, SelectDate, EditOrder, Cancel } from './components/planning';

export default {
	name       : 'Panning',
	components : {
		Calendar,
		FilterPerRoad,
		SelectDate,
		EditOrder,
		Cancel
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('planning'),
		};
	},
	data() {
		return {
			items : {
				orders       : [],
				currentRoad  : null,
				selectedDate : null,
				newDate      : null,
				roads        : [],

			},
			currentRoad  : null,
			selectedDate : null,
			roads        : [],
			newDate      : null,
			orders       : [],
			routes       : []
		};
	},
	created() {
		const t = this;
		this.getData();
		for (let i = 0; i < this.routes.length; i++) {
			const road = this.routes[i];
			const orders = t.items.orders;
			if(orders.find((order) => order.road_id == road._id) != undefined) {
				const roads = t.items.roads;
				roads.push(road);
			}
		}
	},
	methods : {
		setItems(items) {
			this.items = items;
		},
		setCurrentRoad(road) {
			this.currentRoad = road;
		},
		setSelectedDate(date) {
			this.selectedDate = date;
		},
		setNewDate(date) {
			this.newDate = date;
		},
		getData() {
			const user = JSON.parse(localStorage.user);
			const orders  = require('@/utils/fakeData/' + user.company.name + '/mockOrder.json');
			this.items.orders = [...orders];
			this.orders = [...orders];
			this.routes = require('@/utils/fakeData/' + user.company.name + '/mockRoutes.json');
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/.vertical-rectangle {
	min-width: 8px;
	border-radius: $border-radius/2;
	height: 18px;
	background-color: $light-grey;
}
</style>
<i18n>
{
	"fr":{
		"edition-of-orders": "Modification des commandes"
	},
	"en": {
		"edition-of-orders": "Edition of orders"
	}
}
</i18n>