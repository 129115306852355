<template>
	<div class="container-item">
		<div class="item" v-b-toggle="'item-details-' + order.int_code">
			<Informations :order="order" />
			<HorizontalVisualization :segments="order.trs[0].segments" :order="order" />
			<div class="toggle-details">
				<div class="icon chevron-down primary" />
			</div>
		</div>
		<b-collapse :id="'item-details-' + order.int_code">
			<div class="p-5 d-flex justify-content-between">
				<VerticalVisualization :segments="order.trs[0].segments" :order="order" />
				<TechnicalInformations :order="order" />
			</div>
		</b-collapse>
	</div>
</template>
<script>
import Informations from '@/components/segments/Informations.vue';
import HorizontalVisualization from '@/components/segments/HorizontalVisualization.vue';
import VerticalVisualization from './components/VerticalVisualization';
import TechnicalInformations from './components/TechnicalInformations';
export default {
	name       : 'Order',
	components : {
		Informations,
		HorizontalVisualization,
		TechnicalInformations,
		VerticalVisualization
	},
	props : {
		order : {
			type     : Object,
			required : true
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.collapsed > .when-open,
.not-collapsed > .when-closed {
	display: none;
}
/deep/ .visualization li .position {
	background-color: $thirdary;
	border: 3px solid $white;
	border-radius: 50%;
	content: '';
	height: 18px;
	position: absolute;
	width: 18px;
	z-index: 2;
}
/deep/ .visualization li .finished .position {
	background-color: $green;
}
/deep/ .visualization li .finished .segment {
	background-color: $green;
}
/deep/ .visualization li .started .position:not(:last-of-type) {
	background-color: $green;
}
/deep/ .visualization li .error .segment {
	background-color: $red;
}

</style>