<template>
	<main id="update-location">
		<div class="d-flex justify-content-between mb-3">
			<h1>
				{{ $t('update-location') }}
			</h1>
			<b-button class="btn-icon" variant="info"
				@click="$router.push({name: 'LocationList'})">
				<div class="icon arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-rental class="primary d-flex justify-content-between">
				{{ $t('edit-rental') }}
				<div class="icon chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-rental" visible accordion="accordion">
				<b-card-body>
					<RentalFields :location="location" />
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-contact class="primary d-flex justify-content-between">
				{{ $t('edit-contact') }}
				<div class="icon add" />
				<div class="icon remove" />
			</b-card-header>
			<b-collapse id="collapse-contact" accordion="accordion">
				<b-card-body>
					<b-card class="h-100" no-body>
						<b-table head-variant="light" :striped="true" hover responsive show-empty
							:empty-text="$t('no-data-to-display')" :fields="fields" :items="items"
							:current-page="pagination.currentPage" :per-page="pagination.perPage">
							<template #cell(action)>
								<div class="d-flex justify-content-center">
									<ul class="actions">
										<li>
											<b-button variant="primary" class="btn-round-icon">
												<div class="icon edit" />
											</b-button>
										</li>
									</ul>
								</div>
							</template>
						</b-table>
					</b-card>
					<Pagination :pagination="pagination" />
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-modal class="primary d-flex justify-content-between">
				{{ $t('edit-modal') }}
				<div class="icon add" />
				<div class="icon remove" />
			</b-card-header>
			<b-collapse id="collapse-modal" accordion="accordion">
				<b-card-body>
					<b-card class="h-100" no-body>
						<b-table head-variant="light" :striped="true" hover responsive show-empty
							:empty-text="$t('no-data-to-display')" :fields="fields" :items="items"
							:current-page="pagination.currentPage" :per-page="pagination.perPage">
							<template #cell(action)>
								<div class="d-flex justify-content-center">
									<ul class="actions">
										<li>
											<b-button variant="primary" class="btn-round-icon">
												<div class="icon edit" />
											</b-button>
										</li>
									</ul>
								</div>
							</template>
						</b-table>
					</b-card>
					<Pagination :pagination="pagination" />
				</b-card-body>
			</b-collapse>
		</div>
		<div class="d-flex justify-content-center">
			<b-button class="btn-icon mt-3" variant="primary"
				@click="$router.push({name: 'LocationList'})">
				<div class="icon save" />
				{{ $t('save') }}
			</b-button>
		</div>
	</main>
</template>

<script>
import RentalFields from '../locations/components/RentalFields.vue';
import Pagination from '@/components/Pagination.vue';


export default {
	name       : 'UpdateLocation',
	components : {
		RentalFields,
		Pagination
	},

	created() {
		const id = this.$route.params.id;
		const user = JSON.parse(localStorage.user);
		const items = require('@/utils/fakeData/' + user.company.name + '/mockTerminals.json');
		this.location = items.find(location => location.id === id);
		this.pagination.totalRows = this.location.length;
	},

	data() {
		return {
			location   : {},
			items      : [],
			pagination : {
				perPage     : 10,
				totalRows  	: [],
				currentPage : 1,
			}
		};
	},

	computed : {
		fields() {
			return [
				{
					label        : this._i18n.t('lastname'),
					key          : 'lastname',
					sortable     : true,
				},
				{
					label        : this._i18n.t('firstname'),
					key          : 'firstname',
					sortable     : true,
				},
				{
					label    : this._i18n.t('email'),
					key      : 'email',
					sortable : true,
				},
				{
					label    : this._i18n.t('phone'),
					key      : 'phone',
					sortable : true,
				},
				{
					label    : this._i18n.t('job'),
					key      : 'job',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		}
	},
};
</script>