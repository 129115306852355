<template>
	<main id="dashboard">
		<h1>
			{{ $t('dashboard') }}
		</h1>
		<b-row>
			<b-col lg="4" md="6" sm="12">
				<CirculationsRealized />
			</b-col>
			<b-col lg="4" md="6" sm="12">
				<CirculationsModified />
			</b-col>
			<b-col lg="4" md="6" sm="12">
				<CirculationsCancelled />
			</b-col>
			<b-col lg="4" md="6" sm="12">
				<Road />
			</b-col>
			<b-col lg="8" md="12" class="pt-0">
				<b-row>
					<b-col lg="12">
						<Production />
					</b-col>
					<b-col lg="12">
						<Economy v-if="false" />
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</main>
</template>

<script>
import { CirculationsRealized, CirculationsCancelled, CirculationsModified, Road, Economy, Production }
	from './components';

export default {
	name       : 'Dashboard',
	components : {
		CirculationsCancelled,
		CirculationsRealized,
		CirculationsModified,
		Road,
		Economy,
		Production
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('dashboard'),
		};
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.b-card-group {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.row  /deep/.card {
	margin-bottom: 0.5rem;
}
.row /deep/.card > .card-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.row /deep/.card:not(:last-child) .card-header {
	border-radius: $border-radius $border-radius 0 0;
}
/deep/h6 {
	font-weight: 600;
	margin: 0.5rem 0;
}
</style>