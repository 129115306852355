<template>
	<main id="finance">
		<h1>{{ $t('finance') }}</h1>
		<div class="d-flex justify-content-end child-mx-2">
			<b-button variant="primary" class="btn-icon-only">
				<div class="icon sync" />
			</b-button>
		</div>
		<div class="tab-header border-radius-top mt-3">
			<Filters>
				<Clients />
				<Roads />
				<Year />
				<PeriodType :items="itemsNotFiltered" @set-items="setItems" />
				<PeriodDef />
			</Filters>
		</div>
		<b-table head-variant="light" :striped="true" hover responsive show-empty
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="items"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(action)="data">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li v-if="data.item.preinvoice_status !== 'WAIT'">
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon download" />
							</b-button>
						</li>
						<li v-if="data.item.preinvoice_status === 'WAIT'">
							<b-button variant="primary" class="btn-round-icon"

								@click="preinvoiceModal=true">
								<div class="icon receipt" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
			<template #cell(route)="data">
				<span v-if="data.item.route"> {{ data.item.route.sites | route }} </span>
				<span v-else> {{ $t('all') }}</span>
			</template>
			<template #cell(cost)="data">
				<span v-if="data.item.route"> {{ data.item.route.cost }} €</span>
				<span v-else> {{ data.item.cost }} € </span>
			</template>
			<template #cell(transported_weight)="data">
				<span> {{ data.item.transported_weight }} T </span>
			</template>
			<template #cell(preinvoice_status)="data">
				<span> {{ $t(data.item.preinvoice_status) }} </span>
			</template>
			<template #cell(period_type)="data">
				{{ $t(data.item.period_type) }} {{ data.item.period_number }}
			</template>
			<template #cell(total_cost)="data">
				<span v-if="data.item.route"> {{ data.item.route.cost * data.item.transported_weight }} €</span>
				<span v-else> {{ data.item.cost * data.item.transported_weight }} €</span>
			</template>
			<template #cell(preinvoice_generation_date)="data">
				{{ data.item.preinvoice_generation_date | getDateMonthYear }}
			</template>
			<template #cell(preinvoice_send_date)="data">
				{{ data.item.preinvoice_send_date | getDateMonthYear }}
			</template>
			<template #cell(preinvoice_valid_date)="data">
				{{ data.item.preinvoice_valid_date | getDateMonthYear }}
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
		<b-modal id="modal-1" centered :title="$t('addPreInvoice')" v-model="preinvoiceModal"
			:cancel-title="$t('no')" :ok-title="$t('yes')">
			<p class="my-3 text-initial">
				{{ $t('ask-preinvoice') }}
			</p>
		</b-modal>
	</main>
</template>

<script>
import Filters from '@/components/filters/Filters.vue';
import { Clients, Roads } from '@/components/filters/components';
import { Year, PeriodDef, PeriodType } from '@/components/filters/components/finance';
import Pagination from '@/components/Pagination.vue';

export default {
	name       : 'Finance',
	components : {
		Filters,
		Clients,
		Roads,
		Year,
		PeriodDef,
		PeriodType,
		Pagination
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('trains'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('period-type'),
					key      : 'period_type',
					sortable : true,
				},
				{
					label    : this._i18n.t('client'),
					key      : 'client.name',
					sortable : true,
				},
				{
					label    : this._i18n.t('routes'),
					key      : 'route',
					sortable : true,
				},
				{
					label    : this._i18n.t('transported-weight'),
					key      : 'transported_weight',
					sortable : true,
				},
				{
					label : this._i18n.t('unit-cost'),
					key   : 'cost',
				},
				{
					label : this._i18n.t('total-cost'),
					key   : 'total_cost',
				},
				{
					label : this._i18n.t('preinvoice-code'),
					key   : 'preinvode_code',
				},
				{
					label : this._i18n.t('preinvoice-status'),
					key   : 'preinvoice_status',
				},
				{
					label : this._i18n.t('preinvoice-generation-date'),
					key   : 'preinvoice_generation_date',
				},
				{
					label : this._i18n.t('preinvoice-send-date'),
					key   : 'preinvoice_send_date',
				},
				{
					label : this._i18n.t('preinvoice-valid-date'),
					key   : 'preinvoice_valid_date',
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			items            : [],
			itemsNotFiltered : [],
			preinvoiceModal  : false,
			pagination       : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			}
		};
	},
	methods : {
		setItems(items) {
			this.items = items;
			this.pagination.currentPage = 1;
			this.pagination.totalRows = this.items.length;
		},
		getData() {
			const user = JSON.parse(localStorage.user);
			const data  = require('@/utils/fakeData/' + user.company.name + '/mockPreinvoice.json');
			this.items = [...data];
			this.itemsNotFiltered = [...data];
			this.pagination.totalRows = this.items.length;
		}
	},
	created() {
		this.getData();
	},
	filters : {
		route(value) {
			return value.map(site=> site.name).join(' > ');
		}
	}
};
</script>
