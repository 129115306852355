<template>
	<div id="contracts-list">
		<h1>{{ $t('contracts') }}</h1>
		<div class="d-flex justify-content-end child-mx-2">
			<b-button variant="primary">
				{{ $t('add-contract') }}
			</b-button>

			<b-button variant="primary" class="btn-icon-only">
				<div class="icon sync" />
			</b-button>
		</div>
		<div class="tab-header border-radius-top mt-3">
			<Filters>
				<Search />
				<Status />
			</Filters>
		</div>
		<b-table head-variant="light" :striped="true" hover responsive show-empty
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="items">
			<template #cell(action)>
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<router-link :to="{name: 'VisualizationContracts'}">
								<b-button variant="primary" class="btn-round-icon">
									<div class="icon eye" />
								</b-button>
							</router-link>
						</li>
						<li>
							<router-link :to="{name: 'EditContracts'}">
								<b-button variant="primary" class="btn-round-icon">
									<div class="icon edit" />
								</b-button>
							</router-link>
						</li>
						<li>
							<b-button v-if="true" variant="primary" class="btn-round-icon">
								<div class="icon on" />
							</b-button>
							<b-button v-else variant="primary" class="btn-round-icon">
								<div class="icon off" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Filters from '@/components/filters/Filters';
import { Search, Status } from '@/components/filters/components';
import Pagination from '@/components/Pagination.vue';
export default {
	name       : 'ContractList',
	components : {
		Filters,
		Search,
		Status,
		Pagination
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('contracts'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('contract-number'),
					key      : 'contractnumber',
					sortable : true,
				},
				{
					label    : this._i18n.t('client'),
					key      : 'client',
					sortable : true,
				},
				{
					label    : this._i18n.t('type'),
					key      : 'type',
					sortable : true,
				},
				{
					label    : this._i18n.t('status'),
					key      : 'status',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			items : [
				{ contractnumber : '315203232',
					client         : 'Aubert & Duval',
					type           : 'Interne',
					status         : 'Actif' },

				{ contractnumber : '315203233',
					client         : 'Tesli',
					type           : 'Externe',
					status         : 'Actif' },

				{ contractnumber : '315203234',
					client         : 'Hyper M',
					type           : 'Externe',
					status         : 'Actif' }
			],
			pagination : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			}
		};
	}
};
</script>
