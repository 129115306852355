const state = () => ({
	operation : JSON.parse(localStorage.getItem('operation')) || { incidents : [] }
});

const getters = {
	get : state => state.operation,
};

const mutations = {
	setLocation(state, location) {
		state.operation.location = location;
		localStorage.setItem(('operation'), JSON.stringify(state.operation));
	},
	setOrder(state, order) {
		state.operation.order = order;
		localStorage.setItem(('operation'), JSON.stringify(state.operation));
	},
	setState(state, operationState) {
		state.operation.state = operationState;
		localStorage.setItem(('operation'), JSON.stringify(state.operation));
	},
	setSegment(state, segment) {
		state.operation.segment = segment;
		localStorage.setItem(('operation'), JSON.stringify(state.operation));
	},
	pushIncident(state, incident) {
		state.operation.incidents.push(incident);
		localStorage.setItem(('operation'), JSON.stringify(state.operation));
	},
	editIncident(state, incident, index) {
		state.operation.incidents[index] = incident;
		localStorage.setItem(('operation'), JSON.stringify(state.operation));
	},
	reset(state) {
		state.operation = { incidents : [] };
		localStorage.setItem(('operation'), JSON.stringify(state.operation));
	}
};
export default {
	namespaced : true,
	state,
	getters,
	mutations
};