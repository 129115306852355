<template>
	<main>
		<b-spinner variant="white" />
	</main>
</template>
<script>
export default {
	mounted() {
		//const t = this;
		//t.axios
		// .get('http://localhost:3000/api/users/verify?token=' + t.$route.params.key)
		// .then((res) => {
		// 	t.$store.dispatch('login/LOGIN', res);
		// 	t.$router.push('/');
		// })
		// /**.catch((err) => {
		// 	console.log(err);
		// }); */
	},
};
</script>
<style lang="scss" scoped>
main {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

</style>