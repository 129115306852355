<template>
	<div id="incidents">
		<h1>{{ $t('incidents') }}</h1>
		<div class="d-flex justify-content-end child-mx-2">
			<b-button variant="primary">
				{{ $t('add-incident') }}
			</b-button>
			<b-button variant="primary" class="btn-icon-only">
				<div class="icon sync" />
			</b-button>
		</div>
		<div class="tab-header border-radius-top mt-3">
			<Filters>
				<Search />
				<StatusIncident :items="itemsNotFiltered" @set-items="setItems" />
				<TypeIncident :items="itemsNotFiltered" @set-items="setItems" />
			</Filters>
		</div>
		<b-table head-variant="light" :striped="true" hover responsive show-empty
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="items"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(action)>
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon edit" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Filters from '@/components/filters/Filters.vue';
import { Search } from '@/components/filters/components';
import TypeIncident from '@/components/filters/components/incidents/TypeIncident.vue';
import StatusIncident from '@/components/filters/components/incidents/StatusIncident.vue';
import Pagination from '@/components/Pagination.vue';

export default {
	name       : 'Incidents',
	components : {
		Filters,
		Search,
		TypeIncident,
		StatusIncident,
		Pagination
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('incidents'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('incident-type'),
					key      : 'incident.type',
					sortable : true,
				},
				{
					label    : this._i18n.t('identification'),
					key      : 'incident.identification',
					sortable : true,
				},
				{
					label    : this._i18n.t('incident-description'),
					key      : 'incident.description',
					sortable : true,
				},
				{
					label    : this._i18n.t('status'),
					key      : 'incident.status',
					sortable : true,
				},
				{
					label    : this._i18n.t('cost'),
					key      : 'incident.cost',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			items            : [],
			itemsNotFiltered : [],
			pagination       : {
				perPage     : 10,
				totalRows  	: [].length,
				currentPage : 1,
			}
		};
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			const data  = require('@/utils/fakeData/' + user.company.name + '/mockIncidents.json');
			this.items = [...data];
			this.itemsNotFiltered = [...data];
			this.pagination.totalRows = this.items.length;
		}
	},
	created() {
		this.getData();
	}
};
</script>
<style lang="scss" scoped>
/deep/.action-field {
	min-width: 200px;
}
.actions {
	max-width: none;
}
</style>