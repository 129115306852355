<template>
	<b-card no-body class="h-100 d-flex flex-column">
		<b-card-header>
			{{ $t('penalties') }}
		</b-card-header>
		<b-card-body>
			<h5>
				{{ $t('title-penalties') }}
			</h5>
			<div class="width-content">
				<div class="d-flex legends">
					<div class="square primary" />
					<p>Jours selectionner</p>
				</div>
				<div class="d-flex align-item-center legends">
					<div class="square red" />
					<p>100%</p>
					<div class="square grey" />
					<p>50%</p>
					<div class="square green" />
					<p>0%</p>
				</div>
				<DateList v-for="(segment, index) in segments" :key="index" :date-list="segment.dateList" />
			</div>
			<i class="text-initial w-80 mt-3">
				{{ $t('penalties-rules') }}
			</i>
		</b-card-body>
	</b-card>
</template>

<script>
import DateList from '@/components/segments/DateList';
export default {
	name       : 'Penalites',
	components : {
		DateList
	},
	data() {
		return {
			segments : [
				{
					dateList : {
						desactive : [3, 4],
						primary   : [2],
						grey      : [5, 6, 7]
					}
				},
				{
					dateList : {
						days : [1, 2, 3, 4, 5, 6, 7],
					}
				},
				{
					dateList : {
						days : [1, 2, 3, 4, 5, 6, 7],
					}
				}
			]
		};
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.card-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.legends {
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	color: $grey;
	&:not(:first-of-type) {
		margin-top: 0.5rem;
	}
}
p {
	margin-right: 0.5rem;
}
i {
	color: $grey;
}
.square {
	content: '';
	width: 0.8rem;
	height: 0.8rem;
	border-radius: $border-radius/2;
	display: block;
	margin-right: 0.25rem;
}
.square.red {
	background-color: $red;
}
.square.green {
	background-color: $green;
}
.square.primary {
	background-color: $primary;
}
.square.grey {
	background-color: $grey;
}
/deep/.date-list {
	margin-top: 0.5rem;
	width: fit-content;
}

</style>