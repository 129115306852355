<template>
	<div id="segments-list">
		<div class="list-header border-radius-top">
			<ul>
				<li>{{ $t('segment-id') }}</li>
				<li>{{ $t('origine') }}</li>
				<li />
				<li>{{ $t('destination') }}</li>
				<li>{{ $t('type_status') }}</li>
			</ul>
		</div>
		<div class="list">
			<div class="container-item" v-for="(segment, index) in segments" :key="index">
				<div class="item" v-b-toggle="'item-details-' + index">
					<Informations :segment="segment" />
					<HorizontalVisualization :segments="new Array(segment)" />
					<div class="toggle-details">
						<div class="icon chevron-down primary" />
					</div>
				</div>
				<b-collapse :id="'item-details-' + index">
					<div class="p-5 d-flex justify-content-center">
						<TechnicalInformations :segment="segment" />
					</div>
				</b-collapse>
			</div>
		</div>
	</div>
</template>
<script>
import Informations from '@/components/segments/Informations.vue';
import TechnicalInformations from './components/TechnicalInformations.vue';
import HorizontalVisualization from '@/components/segments/HorizontalVisualization.vue';

export default {
	name       : 'SegmentsList',
	components : {
		Informations,
		HorizontalVisualization,
		TechnicalInformations
	},
	data() {
		return {
			segments : [],
		};
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			this.segments  = require('@/utils/fakeData/' + user.company.name + '/mockSegments.json');
		}
	},
	created() {
		this.getData();
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/ .visualization.horizontal #last-destination {
	left: 100%;
}
</style>