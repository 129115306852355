import Vue from 'vue';
import Vuex from 'vuex';
import alert from './modules/alert.js';
import user from './modules/user.js';

import registers from './modules/registers.js';
import operation from './modules/operation';


Vue.use(Vuex);
export default new Vuex.Store({
	state     : {},
	mutations : {},
	actions   : {},
	modules   : {
		alert,
		user,
		registers,
		operation
	},
});
