<template>
	<b-card no-body id="wagons" class="identification-components">
		<b-card-header>
			{{ $t('wagons') }}
		</b-card-header>
		<b-card-body>
			<ul>
				<li v-for="(wagon, key) in wagons" :key="key">
					<span>
						{{ wagon.name }} :
						<b> {{ wagon.units }} {{ $t('wagons') }} </b>
					</span>
					<b-button variant="primary" size="sm" class="mt-2"
						@click="$router.push({path:'/wagons', query: { group: key+1 }})">
						{{ $t('details') }}
					</b-button>
				</li>
				<li>
					<p>{{ $t('wagons-in-reserve') }} : <b>{{ wagonReserve }}</b></p>
					<b-button variant="primary" size="sm" class="mt-2"
						@click="$router.push({path:'/wagons', query: { group: 3 }})">
						{{ $t('details') }}
					</b-button>
				</li>
				<li>
					<p>{{ $t('handler-wagons') }} : <b>SÉNALIA</b></p>
				</li>
			</ul>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	data() {
		return {
			wagonReserve : 5,
			wagons       : [
				{
					name  : 'Parc 1',
					units : '22'
				},
				{
					name  : 'Parc 2',
					units : '22'
				},

			]
		};
	}
};
</script>
<style lang="scss" scoped>
ul {
	display: flex;
	flex-direction: column;
}
ul li {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 44px;
}
</style>