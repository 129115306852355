<template>
	<div id="locations">
		<h1>{{ $t('locations') }}</h1>
		<div class="d-flex justify-content-end child-mx-2">
			<b-button variant="primary"
				@click="$router.push({name: 'CreateLocation'})">
				{{ $t('add-location') }}
			</b-button>
			<b-button variant="primary" class="btn-icon-only">
				<div class="icon sync" />
			</b-button>
		</div>
		<div class="tab-header border-radius-top mt-3">
			<Filters>
				<Search />
				<Status />
			</Filters>
		</div>
		<b-table head-variant="light" :striped="true" hover responsive show-empty
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="items"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(typology)>
				<div class="icon trains primary" />
			</template>
			<template #cell(action)="location">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon eye" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon"
								@click="$router.push({name: 'UpdateLocation',
									params: {id: location.item.id}})">
								<div class="icon edit" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon on" v-if="true" />
								<div class="icon off" v-else />
							</b-button>
						</li>
						<!--li>
							<b-button size="sm" @click="row.toggleDetails" class="mr-2">
								{{ row.detailsShowing ? 'Cacher' : 'Voir' }} Les terminaux
							</b-button>
							<div class="icon chevron-down primary" />
						</li-->
					</ul>
				</div>
			</template>
			<!--template-- #row-details="row">
				<b-card>
					<ul>
						<li v-for="(item, index) in row.item.interconnections" :key="index">
							{{ item }}
						</li>
					</ul>
				</b-card>
			</!--template-->
			<template #cell(terminalstype)="data">
				<div v-if="data.item.interconnections && data.item.interconnections.length > 0"
					class="d-flex align-items-center justify-content-center">
					<div class="icon group-locations primary mr-1" />
					<span>{{ $t('terminal-agglomeration') }}</span>
				</div>
				<div v-else class="d-flex align-items-center justify-content-center">
					<div class="icon location primary mr-1" />
					{{ $t('terminal') }}
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Filters from '@/components/filters/Filters.vue';
import { Search, Status } from '@/components/filters/components';
import Pagination from '@/components/Pagination.vue';

export default {
	name       : 'Locations',
	components : {
		Filters,
		Search,
		Status,
		Pagination
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('locations'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label        : this._i18n.t('name'),
					key          : 'name',
					sortable     : true,
				},
				{
					label        : this._i18n.t('terminal-type'),
					key          : 'terminalstype',
					sortable     : true,
				},
				{
					label    : this._i18n.t('typology'),
					key      : 'typology',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		}
	},
	data() {
		return {
			items      : [],
			pagination : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			}
		};
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			this.items  = require('@/utils/fakeData/' + user.company.name + '/mockTerminals.json');
			this.pagination.totalRows = this.items.length;
		}
	},
	created() {
		this.getData();
	}
};
</script>
<style lang="scss" scoped>
/deep/.action-field {
	min-width: 200px;
}
.actions {
	max-width: none;
}
</style>