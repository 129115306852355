<template>
	<div id="collaborators-fields">
		<b-form-group :label="$t('firstname')">
			<b-form-input placeholder="_" v-model="collaborator.firstname" />
		</b-form-group>

		<b-form-group :label="$t('email')">
			<b-form-input type="email" placeholder="_" v-model="collaborator.email" />
		</b-form-group>

		<b-form-group :label="$t('job')">
			<b-form-input placeholder="_" v-model="collaborator.scope" />
		</b-form-group>

		<b-form-group :label="$t('language')">
			<b-form-select v-model="collaborator.language" :options="optionsLanguage">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
		<b-form-group :label="$t('name')">
			<b-form-input placeholder="_" v-model="collaborator.lastname" />
		</b-form-group>

		<b-form-group :label="$t('phone-number')">
			<b-form-input placeholder="_" v-model="collaborator.phone[0]" />
		</b-form-group>

		<b-form-group :label="$t('registration')">
			<b-form-input placeholder="_" />
		</b-form-group>

		<b-form-group :label="$t('street')">
			<b-form-input placeholder="_" v-model="collaborator.address.street" />
		</b-form-group>

		<b-form-group :label="$t('city')">
			<b-form-input placeholder="_" v-model="collaborator.address.city" />
		</b-form-group>

		<b-form-group :label="$t('department-or-region')">
			<b-form-input placeholder="_" />
		</b-form-group>
		<b-form-group :label="$t('complement-address')">
			<b-form-input placeholder="_" />
		</b-form-group>

		<b-form-group :label="$t('postal-code')">
			<b-form-input placeholder="_" v-model="collaborator.address.code" />
		</b-form-group>

		<b-form-group :label="$t('country')">
			<b-form-select v-model="collaborator.address.country" :options="optionsCountry">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
	</div>
</template>

<script>
export default {
	name : 'CollaboratorsFields',

	props : {
		collaborator : {
			type     : Object,
			required : false,
			default() {
				return {
					address : {
						country : null,

					},
				};
			}
		},
	},

	computed : {
		optionsLanguage() {
			return [
				{
					value : 'FR-lang',
					text  : this._i18n.t('french')
				},
				{
					value : 'EN-lang',
					text  : this._i18n.t('english')
				},
			];
		},
		optionsCountry() {
			return [
				{
					value : 'FR-country',
					text  : this._i18n.t('france')
				},
				{
					value : 'EN-country',
					text  : this._i18n.t('england')
				},
				{
					value : 'US-country',
					text  : this._i18n.t('united-states')
				},
			];
		}
	}
};

</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#collaborators-fields {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.form-group {
	margin: 0 15px;
	width: calc(50% - 30px);
}
</style>