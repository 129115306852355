
import Vue from 'vue';
Vue.mixin({
	methods : {
		userHaveAccess(route) {
			const userScope = this.$store.getters['user/get'].scope;
			const permissionRoute =  this.$router.resolve({
				name : route,
			}).route.meta.permission;

			for (let i = 0; i < permissionRoute.length; i++) {
				const perm = permissionRoute[i];
				if(userScope.find((x) => x == perm)) {
					return true;
				}
			}
			return false;
		}
	}
});