<template>
	<div>
		<b-card no-body>
			<b-card-header>Selectionner un site</b-card-header>
			<b-card-body>
				<form @submit="submit">
					<multi-select class="content" v-model="location"
						:deselect-label="$t('press-enter-to-remove')" :selected-label="$t('selected')"
						select-label="" placeholder="_" :searchable="true" :hide-selected="true"
						:options="locations" label="name" track-by="name">
						<template slot="noResult">
							{{ $t('no-result') }}
						</template>
					</multi-select>
					<div class="d-flex justify-content-center mt-3">
						<b-button variant="primary" :disabled="!location" @click="submit">
							{{ $t('next') }}
						</b-button>
					</div>
				</form>
			</b-card-body>
		</b-card>
	</div>
</template>
<script>

export default {
	data() {
		return {
			locations : [],
			location  : '',
		};
	},
	created() {
		this.$store.commit('operation/reset');
		this.getData();
	},
	methods : {
		submit($event) {
			$event.preventDefault();
			this.$store.commit('operation/setLocation', this.location);
			const scope = this.$store.getters['user/get'].scope;
			if(scope.includes('handler')) {
				this.$router.push('/operation/select-order');
			} else if(scope.includes('driver')) {
				this.$router.push('/operation/select-segment');
			}
		},
		getData() {
			const user = JSON.parse(localStorage.user);
			const contract  = require('@/utils/fakeData/' + user.company.name + '/mockContract.json');
			this.locations = contract.locations;
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.list-group-item {
	display: flex;
	cursor: pointer;
	padding: 5px 0;
	&:not(.active):hover {
		background-color: darken($white, 5%);
	}
}
.list-group-item.active {
	background-color: $primary;
	color: $white;
}
.list-group-item.active .icon {
	filter: invert(98%) sepia(1%) saturate(261%) hue-rotate(138deg) brightness(120%) contrast(100%);
}
</style>