<template>
	<header>
		<Hamburger />
		<router-link to="/" id="logo-name">
			<img src="@/assets/img/logo_ecopms_name.png" />
		</router-link>
		<form id="contract">
			<b-form-group :label="$t('contract') + ' :'" label-cols-lg="auto" class="p-0">
				<b-form-select :value="null">
					<template v-slot:first>
						<b-form-select-option :value="null" disabled>
							315203232
						</b-form-select-option>
					</template>
				</b-form-select>
			</b-form-group>
		</form>
		<div class="account-container">
			<p class="text-grey text-capitalize">
				{{ user.firstname }}
				<span class="uppercase"> {{ user.lastname }}</span>
			</p>
			<b-dropdown size="small" variant="link" right text="Right align" toggle-class="text-decoration-none"
				no-caret id="profile">
				<template slot="button-content">
					<b-avatar variant="info" class="sr-only" />
				</template>
				<b-dropdown-item href="#" @click="logout">
					<div class="icon log-out" />
					{{ $t('logout') }}
				</b-dropdown-item>
			</b-dropdown>
			<b-button class="round p-0 no-shadow" id="lang" @click="switchLanguage">
				{{ _i18n.locale }}
			</b-button>
		</div>
	</header>
</template>

<script>
import Hamburger from './components/Hamburger.vue';
export default {
	name       : 'Header',
	components : {
		Hamburger
	},
	computed : {
		user() {
			return this.$store.getters['user/get'];
		}
	},
	methods : {
		switchLanguage() {
			if(this._i18n.locale == 'fr') {
				localStorage.setItem('language', 'en');
				this._i18n.locale = 'en';
			} else {
				this._i18n.locale = 'fr';
				localStorage.setItem('language', 'fr');
			}
		},
	},

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
header {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	width: 100%;
	padding: 20px 20px 0 20px;
}
.account-container {
	display: flex;
	align-items: center;
}
.account-container > * {
	margin: 0 10px;
	&:first-child {
		margin: 0;
	}
	&:last-child {
		margin: 0;
	}
}
/deep/.col-lg-auto {
	width: fit-content;
}
#lang {
	width: 37px;
	height: 37px;
	border: none;
	background-color: $grey;
	&:hover {
		color: $white;
		background-color: $primary;
	}
}
.form-group {
	margin-bottom: 0;
}
/deep/#hamburger {
	display: none;
}
#logo-name {
	display: none;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@include media-breakpoint-down(md) {
	header {
		padding: 20px;
		background-color: $secondary;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
	}
	#logo-name {
		display: block;
	}
	/deep/legend {
		display: none;
	}
	/deep/#hamburger {
		display: block;
	}
	.account-container {
		display: none;
	}
}
</style>