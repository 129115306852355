const state = () => ({
	alert : { message : '' }
});

const getters = {
	getAlert : state => state.alert,
};

const mutations = {
	SHOW(state, payload) {
		state.alert = {};
		payload.show = true;
		state.alert = payload;
		setTimeout(() => {
			state.alert = {};
		}, 10000);
	},
	DISMISS(state) {
		state.alert = {};
	}
};
export default {
	namespaced : true,
	state,
	getters,
	mutations
};