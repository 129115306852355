<template>
	<b-card no-body id="production">
		<b-card-header class="d-flex align-items-center">
			<span class="mr-3">{{ $t('production-volumes') }}</span>
		</b-card-header>
		<b-card-body class="w-100">
			<div>
				<b-form-radio-group v-model="yAxisData" :options="yAxisOptions" button-variant="outline-primary"
					buttons stacked />
			</div>
			<div class="legends">
				<div class="point" />
				<div>
					{{ $t('curve-comparative') }}
				</div>
			</div>
			<BarChart :chart-data="chartData" :options="options" />
			<div class="d-flex justify-content-end">
				<b-form-radio-group v-model="xAxisData" :options="xAxisOptions" button-variant="outline-info"
					buttons />
			</div>
		</b-card-body>
	</b-card>
</template>
<script>
import BarChart from './components/BarChart.js';
export default {
	components : {
		BarChart
	},
	computed : {
		yAxisOptions() {
			return [
				{
					text  : this._i18n.t('transported-units'),
					value : 'transportedUnits'
				},
				{
					text  : this._i18n.t('orders'),
					value : 'orders'
				}
			];
		},
		xAxisOptions() {
			return [
				{
					text  : this._i18n.t('per-destination'),
					value : 'destination'
				},
				{
					text  : this._i18n.t('per-client'),
					value : 'client'
				}
			];
		}
	},
	data() {
		return {
			yAxisData : '',
			xAxisData : '',
			chartData : {
				labels   : [],
				datasets : this.dataSetByTransportUnits,
			},
			dataSetsClient : {
				transportedUnits : [
					[1000, 600, 300, 700, 700, 1000],
					[100, 700, 800, 1000, 900, 200]
				],
				orders : [
					[3, 6, 7, 1, 2, 5],
					[4, 6, 4, 3, 8, 2]
				],
			},
			dataSetsDestination : {
				transportedUnits : [
					[200, 400, 500, 800, 900, 200],
					[200, 300, 600, 800, 900, 300]
				],
				orders : [
					[1, 2, 5, 1, 2, 3],
					[4, 3, 2, 1, 2, 5]
				],
			},
			defaultDataSets : [
				{
					fill                 : false,
					pointBackgroundColor : getComputedStyle(document.body).getPropertyValue('--purple'),
					borderColor          : getComputedStyle(document.body).getPropertyValue('--purple'),
					lineTension          : 0,
					type                 : 'line'

				},
				{
					maxBarThickness : 75,
					backgroundColor : getComputedStyle(document.body).getPropertyValue('--primary'),
				}
			],
			options : {

				legend : {
					display : false,
				},
				animation : {
					duration : 0
				},
				responsive          : true,
				maintainAspectRatio : false,
				events              : [],
				scales              : {
					yAxes : [{
						ticks : {
							beginAtZero   : true,
							suggestedMax  : 0,
							fontColor     : getComputedStyle(document.body).getPropertyValue('--black'),
							fontFamily    : 'Barlow',
							fontSize      : 15,
							fontStyle     : 500
						},
					}],
					xAxes : [{
						ticks : {
							fontColor  : getComputedStyle(document.body).getPropertyValue('--black'),
							fontFamily : 'Barlow',
							fontSize   : 15,
							fontStyle  : 500
						},
					}]
				}
			}
		};
	},
	created() {
		this.xAxisData = 'destination';
		this.yAxisData = 'transportedUnits';
	},
	watch : {
		yAxisData(val) {
			if(this.xAxisData == 'client') {
				this.setData(this.dataSetsClient[val]);
			} else if(this.xAxisData == 'destination') {
				this.setData(this.dataSetsDestination[val]);
			}
		},
		xAxisData(val) {
			if(val == 'client') {
				this.chartData.labels = ['Client 1', 'Client 2', 'Client 3', 'Client 4', 'Client 5', 'Client 6'];
				this.setData(this.dataSetsClient[this.yAxisData]);
			} else if(val == 'destination') {
				this.chartData.labels = ['Les Ancizes', 'Firminy', 'Aéroport CDG', 'Aéroport Vatry',
					'Aéroport international de Shanghai-Pudong', 'Le port du Havre', 'Le port de Yokohama'];
				this.setData(this.dataSetsDestination[this.yAxisData]);
			}
		},

	},
	methods : {
		setData(data) {
			const allData = (Array.from(data[0]
				.concat(data[1])));
			const dataMax = Math.max(...allData);
			this.defaultDataSets[0].data = data[0];
			this.defaultDataSets[1].data = data[1];
			this.chartData.datasets = this.defaultDataSets;
			this.options.scales.yAxes[0].ticks.suggestedMax = dataMax + Math.round(dataMax * 0.2);
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.card-body {
	display: flex;
	align-items: initial !important;
}
.canvas-container {
	margin-left: 70px;
	margin-right: 124px;
	width: calc(100% - 194px);
	height: 100%;
}
.legends {
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: initial;
}
.legends .point {
	width: 0.5em;
	margin-right: 0.5rem;
	height: 0.5em;
	border-radius: 50%;
	background-color: var(--purple);
}

@media screen
	and (max-width: 1300px)
	and (min-width: 1000px) {
	.canvas-container {
		margin: 0;
		width: 100%;
	}
}

@media screen
	and (max-width: 850px) {
	.card-body {
		display: flex;
		flex-direction: column;
		align-items: center !important;
	}
	.canvas-container {
		margin: 0;
		width: 100%;
	}
	.btn-group-toggle {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
	/deep/.btn-group-toggle > .btn {
		width: 100%;
		margin-left: 0;
	}
	/deep/.btn-group-toggle > .btn:not(:last-child):not(.dropdown-toggle) {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: $border-radius;
	}
	/deep/.btn-group-toggle > .btn:not(:first-child),
	.btn-group-vertical > .btn-group:not(:first-child) > .btn {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: $border-radius;
	}
}
</style>
<i18n>
{
    "fr": {
        "production-volumes": "Production en volumes",
        "transported-units": "Unités transportées",
        "per-destination": "Par destination",
		"per-client": "Par client",
		"curve-comparative": "Courbe comparative année N-1"
    },
    "en": {
        "production-volumes": "Production volumes",
        "transported-units": "Transported units",
        "per-destination": "Per destination",
        "per-client": "Per client",
		"curve-comparative": "Comparative curve year N-1"
    }
}
</i18n>