<template>
	<ul :class="{'light' : !order.int_code, 'vertical visualization' : true}">
		<li v-for="(segment, key) in segments" :key="key" :class="segment.status">
			<div>
				<ul class="loading-segment">
					<li />
					<li />
					<li />
					<p class="duration-loading" v-if="segment.handling">
						<span>{{ $t('handler') }} : {{ segment.handling.name }}</span>
						<span>
							<span v-if="segment.type == 'rail' && getPreviousItemArray(key, segments)
								&& getPreviousItemArray(key, segments).type == 'road'">
								{{ $t('handling-time-per-truck') }} :
								{{ segment.handling.time }} h

							</span>
							<span v-else-if="segment.type == 'rail'">
								{{ $t('handling-time') }} : {{ segment.handling.time }} h
							</span>
							<span v-if="segment.type == 'road'">
								{{ $t('handling-time-per-truck') }} : {{ segment.handling.time }} h
							</span>
						</span>
						<span v-if="segment.type == 'road' && segment.units">
							{{ $t('number-of-trucks') }} : {{ segment.units }}
						</span>
						<span v-if="segment.type == 'rail' && getPreviousItemArray(key, segments)
							&& getPreviousItemArray(key, segments).type == 'road'">
							{{ $t('number-of-trucks') }} : {{ segment.handling.units }}
						</span>
					</p>
				</ul>
				<p class="segment-date first-departure-date"
					v-if="key == 0 && segment.handling && segment.handling.time != 0">
					<b>{{ substractDate(segment) | getDateMonthYear }}</b>
					<span>{{ substractDate(segment) | getHoursMinutes }}</span>
				</p>
			</div>
			<div class="segment-container" :class="segment.statut + ' ' + segment.finished">
				<p class="segment-date derparture-date">
					<b>{{ segment.departure_date | getDateMonthYear }}</b>
					<span>{{ segment.departure_date | getHoursMinutes }}</span>
				</p>
				<div class="segment-visualization">
					<div v-if="checkCurrentPosition(segment)" class="current-position"
						:style="{top : order.current_position.position + '%'}">
						<div class="icon flag green" />
					</div>
					<div class="error-segment statut" v-if="segment.statut === 'error'">
						<div class="icon warning red" />
					</div>

					<div class="position" :id="'position-' + key" />
					<div :class="'segment ' + segment.type">
						<div class="road-element" v-if="segment.type == 'road'" />
					</div>
				</div>
				<div class="position-destination">
					<p class="segment-date destination-date">
						<b>{{ segment.arrival_date | getDateMonthYear }}</b>
						<span>{{ segment.arrival_date | getHoursMinutes }}</span>
					</p>
					<div class="position" />
					<b class="destination-name">{{ segment.destination.name }}</b>
				</div>
				<div class="segment-details">
					<div class="d-flex flex-column">
						<b>{{ segment.origin.name }}</b>
						<p class="d-flex flex-column text-grey text-initial">
							<span v-if="segment.sillon_code">{{ $t('sillon') }} : {{ segment.sillon_code }}</span>
							<span v-if="segment.operator">
								{{ $t('carrier') }} : {{ segment.operator.operator_name }}
							</span>
							<span v-if="segment.type == 'road' && segment.units">
								{{ $t('number-of-trucks') }} : {{ segment.units }}
							</span>
							<span v-if="segment.type == 'road'">
								{{ $t('travel-time-per-trucks') }} : {{ getTravelTimePerTrucks(segment) }} h
							</span>
						</p>
					</div>
				</div>
			</div>
			<div v-if="key == (segments.length - 1)" class="unload">
				<ul class="loading-segment">
					<li />
					<li />
					<li />
					<p class="duration-loading" v-if="segment.handling">
						<span>{{ $t('handler') }} : {{ segment.handling.name }}</span>
						<span>
							<span v-if="segment.type == 'rail' && getPreviousItemArray(key, segments)
								&& getPreviousItemArray(key, segments).type == 'road'">
								{{ $t('handling-time-per-truck') }} :
								{{ segment.handling.time }} h

							</span>
							<span v-else-if="segment.type == 'rail'">
								{{ $t('handling-time') }} : {{ segment.handling.time }} h
							</span>
							<span v-if="segment.type == 'road'">
								{{ $t('handling-time-per-truck') }} : {{ segment.handling.time }} h
							</span>
						</span>
						<span v-if="segment.type == 'road' && segment.units">
							{{ $t('number-of-trucks') }} : {{ segment.units }}
						</span>
						<span v-if="segment.type == 'rail' && getPreviousItemArray(key, segments)
							&& getPreviousItemArray(key, segments).type == 'road'">
							{{ $t('number-of-trucks') }} : {{ segment.handling.units }}
						</span>
					</p>
				</ul>
			</div>
		</li>
	</ul>
</template>
<script>
import VerticalVisualization from '@/mixins/segments/VerticalVisualization.js';
import moment from 'moment';
export default {
	name : 'VerticalVisualization',

	props : {
		order : {
			type     : Object,
			required : false,
			default() {
				return {
					current_position : {}
				};
			}
		},
		segments : {
			type     : Array,
			required : true,
		}
	},
	mixins : [
		VerticalVisualization
	],
	methods : {
		checkCurrentPosition(segment) {
			return this.order.current_position.segment_id && segment._id == this.order.current_position.segment_id;
		},
		substractDate(segment) {
			const date = moment(segment.departure_date);
			return date.subtract(segment.handling.time, 'hours');
		},
		getTravelTimePerTrucks(segment) {
			// return moment(segment.arrival_date).format('h') - moment(segment.departure_date).format('h');
			return segment.duration;
		}
	}

};
</script>