<template>
	<div id="contracts-visualization">
		<h1>{{ $t('contract') }} : N° 315203232</h1>
		<div class="d-flex justify-content-between align-items-baseline">
			<Breadcrumbs />
			<div class="d-flex">
				<b-button variant="info" class="btn-icon mr-2" @click="$router.push({name: 'ListContracts'})">
					<div class="icon arrow-back" />
					{{ $t('return') }}
				</b-button>
				<b-button variant="primary" class="btn-icon" @click="$router.push({name: 'EditContracts'})">
					<div class="icon edit" />
					{{ $t('edit') }}
				</b-button>
			</div>
		</div>
		<b-button variant="primary" v-b-toggle="'identification'" class="btn-collapse">
			{{ $t('identification') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="identification" visible accordion="accordion">
			<b-card>
				<Identification />
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'sites'" class="btn-collapse">
			{{ $t('sites') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="sites" accordion="accordion">
			<b-card>
				<Terminals />
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'segments'" class="btn-collapse">
			{{ $t('segments') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="segments" accordion="accordion">
			<b-card>
				<SegmentsList />
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'transport-plan'" class="btn-collapse">
			{{ $t('transport-plan') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="transport-plan" accordion="accordion">
			<b-card>
				<TransportPlan />
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'pricing-conditions'" class="btn-collapse">
			{{ $t('pricing-conditions') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="pricing-conditions" accordion="accordion">
			<b-card>
				<PricingConditions />
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'invoice-conditions'" class="btn-collapse">
			{{ $t('invoice-conditions') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="invoice-conditions" accordion="accordion">
			<b-card>
				<InvoiceConditions />
			</b-card>
		</b-collapse>
	</div>
</template>

<script>
import Breadcrumbs from './components/Breadcrumbs.vue';
import Identification from './components/visualization/identification/Identification.vue';
import SegmentsList from './components/visualization/segment/SegmentsList.vue';
import TransportPlan from '@/views/contracts/components/visualization/transportPlan/TransportPlan.vue';
import Terminals from './components/visualization/Terminals.vue';
import PricingConditions from './components/visualization/pricingConditions/PricingConditions';
import InvoiceConditions from './components/visualization/InvoiceConditions';
export default {
	name       : 'Visualization',
	components : {
		Breadcrumbs,
		Identification,
		InvoiceConditions,
		TransportPlan,
		SegmentsList,
		Terminals,
		PricingConditions
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('contracts') + ' N° 315203232',
		};
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/ #charger ul > li,
/deep/ #provider ul > li {
	width: 25%;
	text-transform: initial;
	font-weight: 600;
	&:not(:first-of-type) {
		padding-left: 20px;
		border-left: 2px solid $light-grey;
	}
	&:not(:last-of-type) {
		padding-right: 20px;
	}
}
/deep/ #terminals ul li,
/deep/ #identification #segments ul li,
/deep/ #calendar ul li,
/deep/ #identification #transport-plan ul li,
/deep/ #identification #wagons ul li {
	border-bottom: 2px solid $light-grey;
}
/deep/ #calendar ul,
/deep/ #identification #transport-plan ul {
	text-transform: initial;
}
/deep/ #calendar ul li span,
/deep/ #identification #transport-plan ul li span {
	margin-right: 5px;
	font-weight: 600;
}
/deep/ #terminals ul li span,
/deep/ #identification #segments ul li span {
	font-weight: 700;
}
</style>