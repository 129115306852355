<template>
	<div id="list-incidents">
		<Header />
		<div class="d-flex justify-content-end">
			<b-button variant="info" class="btn-icon mb-3"
				@click="$router.push('/operation/state')">
				<div class="icon arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<b-card no-body>
			<b-card-header>{{ $t('list-of-incidents') }}</b-card-header>
			<b-card-body class="grey">
				<b-row class="justify-content-center">
					<b-col v-if="operation.incidents && operation.incidents.length">
						<b-list-group>
							<b-list-group-item v-for="(incident, index) in operation.incidents" :key="index"
								class="d-flex justify-content-between align-items-center">
								<p class="text-overflow">
									<span class="color-primary">{{ index + 1 }}. </span>{{ incident.title }}
								</p>
								<div class="icon chevron-right primary pointer" @click="editIncident(index)" />
							</b-list-group-item>
						</b-list-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>
<script>
import Header from './components/Header';
export default {
	data() {
		return {
		};
	},
	components : {
		Header
	},
	computed : {
		operation() {
			return this.$store.getters['operation/get'];
		}
	},
	methods : {
		editIncident(index) {
			this.$router.push({
				path   : '/operation/incident',
				query : { index : index }
			});
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.list-group {
	font-weight: 600;
}
.list-group-item {
	display: flex;
}
.list-group-item .content {
	width: calc(100% - 100px);
}
.list-group-item label {
	color: $primary;
	width: 100px;
	margin: 0;
}
</style>
<i18n>
{
    "fr": {
        "list-of-incidents": "Liste des incidents"
    },
    "en": {
        "list-of-incidents": "Liste of incidents"
    }
}
</i18n>