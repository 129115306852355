<template>
	<div id="maintenance">
		<b-row>
			<b-col lg="3">
				<b-form-group :label="$t('company')">
					<b-form-input placeholder="_" />
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('note')">
					<b-form-input placeholder="_" />
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('maintenance-site')">
					<b-form-input placeholder="_" />
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('maintenance-km')">
					<b-form-input type="number" placeholder="_" />
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('start-date')">
					<b-form-datepicker placeholder="_"
						:label-no-date-selected="$t('no-date-selected')" />
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('end-date')">
					<b-form-datepicker class="mb-2" placeholder="_"
						:label-no-date-selected="$t('no-date-selected')" />
				</b-form-group>
			</b-col>
			<b-col lg="6" class="d-flex mb-3 justify-content-end align-items-end">
				<b-button class="btn-icon-only" variant="primary">
					<div class="icon add" />
				</b-button>
			</b-col>
		</b-row>
		<b-table head-variant="light" :striped="true" hover responsive v-if="items.length"
			:fields="fields" :items="items" class="mt-4"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(action)>
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon edit" />
							</b-button>
						</li>
						<li>
							<b-button v-if="true" variant="primary" class="btn-round-icon">
								<div class="icon on" />
							</b-button>
							<b-button v-else variant="primary" class="btn-round-icon">
								<div class="icon off" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';

export default {
	name       : 'Maintenance',
	components : {
		Pagination,
	},
	props : {
		wagon : {
			type     : Object,
			required : false,
			default() {
				return {};
			}
		},
	},

	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('company'),
					key      : 'company',
					sortable : true,
				},
				{
					label    : this._i18n.t('maintenance-site'),
					key      : 'maintenance-site',
					sortable : true,
				},
				{
					label    : this._i18n.t('maintenance-start-date'),
					key      : 'startdatemaintenance',
					sortable : true,
				},
				{
					label    : this._i18n.t('maintenance-end-date'),
					key      : 'enddatemaintenance',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		}
	},

	data() {
		return {
			pagination : {
				perPage     : 10,
				currentPage : 1,
			},
			items : []
		};
	},
};

</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>