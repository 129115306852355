<template>
	<div>
		<div class="d-flex justify-content-between mb-2" v-if="order.int_code" id="button-technical-informations">
			<b-button variant="primary" class="d-flex align-items-center">
				<div class="icon map mr-2" />
				<span class="text-nowrap">{{ $t('map-view') }}</span>
			</b-button>
			<b-button variant="primary" class="d-flex align-items-center">
				<div class="icon paper mr-2" />
				<span>{{ $t('history') }} / {{ $t('tracking') }}</span>
			</b-button>
		</div>
		<div class="technical-informations">
			<h3 class="bold">
				{{ $t('technicals-informations') }}
			</h3>
			<ul>
				<li>
					<span>{{ $t('order-type') }}</span>
					<span> {{ $t(order.type) }} </span>
				</li>
				<li>
					<span>{{ $t('ref-client') }}</span>
					<span> {{ order.client_code }} </span>
				</li>
				<li>
					<span>{{ $t('weight') }}</span>
					<span v-if="order.freight"> {{ order.freight.weight }} T</span>
				</li>
				<li>
					<span>{{ $t('merchandise') }}</span>
					<span v-if="order.freight && order.freight.goods_list">
						{{ order.freight.goods_list.join(',') }}
					</span>
				</li>
				<li>
					<span>{{ $t('status') }}</span>
					<span> {{ $t(order.status) }} </span>
				</li>
				<li>
					<span>{{ $t('dangerous') }}</span>
					<span v-if="order.dangerous"> {{ $t('yes') }} </span>
					<span v-else>{{ $t('no') }} </span>
				</li>
				<li>
					<span>{{ $t('incident') }}</span>
					<span v-if="order.open_incidents"> {{ $t('yes') }} </span>
					<span v-else>{{ $t('no') }} </span>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name  : 'TechnicalInformationsCharger',
	props : {
		order : {
			required : false,
			type     : Object,
			default() {
				return {};
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#button-technical-informations button {
	&:first-of-type {
		width: 39%;
	}
	&:last-of-type {
		width: 59%;
	}
}
</style>