<template>
	<ul :class="{'light' : true, 'horizontal visualization' : true}">
		<li v-for="(segment, key) in segments" :key="key" :class="segment.status">
			<div class="segment-container" :class="segment.statut">
				<div class="current-position center" style="left: 50%;">
					<Statut :segment="segment" />
				</div>

				<div class="position" :id="'position-' + key">
					<span v-if="key != 0 && segments.length < 5">
						{{ segment.origin.name }}
					</span>
				</div>
				<b-tooltip v-if="key != 0 && segments.length >= 5"
					:target="'position-' + key" triggers="hover" placement="top">
					{{ segment.origin.name }}
				</b-tooltip>
				<div :class="'segment ' + segment.type">
					<div class="road-element" v-if="segment.type == 'road'" />
				</div>
				<div v-if="key == (segments.length - 1)" class="position" id="last-destination" />
			</div>
		</li>
	</ul>
</template>
<script>

import Statut from '@/components/segments/Statut';

export default {
	name       : 'HorizontalVisualization',
	components : { Statut },
	props      : {
		segments : {
			type     : Array,
			required : true,
		}
	}

};
</script>
<style lang="scss" scoped>
.visualization.horizontal #last-destination {
	left: 100%;
}
</style>