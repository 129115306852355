<template>
	<div id="collaborators-list">
		<h1>{{ $t('collaborators') }}</h1>
		<div class="d-flex justify-content-end child-mx-2">
			<b-button variant="primary" v-if="userHaveAccess('CreateCollaborator')"
				@click="$router.push({name: 'CreateCollaborator'})">
				{{ $t('add-collaborator') }}
			</b-button>
			<b-button variant="primary" class="btn-icon-only">
				<div class="icon sync" />
			</b-button>
		</div>
		<div class="tab-header border-radius-top mt-3">
			<Filters>
				<Search />
				<Status />
			</Filters>
		</div>
		<b-table head-variant="light" :striped="true" hover responsive show-empty
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="items"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(action)="collaborator">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon eye" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon"
								@click="$router.push({name: 'UpdateCollaborator',
									params: {id: collaborator.item._id}})">
								<div class="icon edit" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon on" v-if="true" />
								<div class="icon off" v-else />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Filters from '@/components/filters/Filters.vue';
import { Search, Status } from '@/components/filters/components';
import Pagination from '@/components/Pagination.vue';

export default {
	name       : 'Collaborators',
	components : {
		Filters,
		Search,
		Status,
		Pagination
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('collaborators'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label        : this._i18n.t('firstname'),
					key          : 'firstname',
					sortable     : true,
				},
				{
					label    : this._i18n.t('lastname'),
					key      : 'lastname',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			items   : [],
			filters : {
				name    : null,
				status : null
			},
			pagination : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			}
		};
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			this.items  = require('@/utils/fakeData/' + user.company.name + '/mockCollaborators.json');
			this.pagination.totalRows = this.items.length;
		}
	},
	created() {
		this.getData();
	}
};
</script>
