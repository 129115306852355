<template>
	<div id="locations">
		<b-row>
			<b-col lg="3">
				<b-form-group :label="$t('tenant')">
					<b-form-input type="text" placeholder="_" />
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('daily-price')">
					<b-form-input type="number" placeholder="_" />
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('start-date-contract')">
					<b-form-datepicker class="mb-2" placeholder="_"
						:label-no-date-selected="$t('no-date-selected')" />
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('end-date-contract')">
					<b-form-datepicker class="mb-2" placeholder="_"
						:label-no-date-selected="$t('no-date-selected')" />
				</b-form-group>
			</b-col>
			<b-col lg="12" class="d-flex justify-content-end">
				<b-button class="btn-icon-only" variant="primary">
					<div class="icon add" />
				</b-button>
			</b-col>
		</b-row>
		<b-table head-variant="light" :striped="true" hover responsive
			v-if="items.length" :fields="fields" :items="items" class="mt-4"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(start_contract)="data">
				{{ data.item.start_contract | getDateMonthYear }}
			</template>
			<template #cell(end_contract)="data">
				{{ data.item.end_contract | getDateMonthYear }}
			</template>
			<template #cell(action)>
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon edit" />
							</b-button>
						</li>
						<li>
							<b-button v-if="true" variant="primary" class="btn-round-icon">
								<div class="icon on" />
							</b-button>
							<b-button v-else variant="primary" class="btn-round-icon">
								<div class="icon off" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';

export default {
	name       : 'Locations',
	components : {
		Pagination,
	},
	props : {
		wagon : {
			type     : Object,
			required : false,
			default() {
				return {};
			}
		},
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('tenant'),
					key      : 'company.name',
					sortable : true,
				},
				{
					label    : this._i18n.t('daily-price'),
					key      : 'cost_day',
					sortable : true,
				},
				{
					label    : this._i18n.t('start-date-contract'),
					key      : 'start_contract',
					sortable : true,
				},
				{
					label    : this._i18n.t('end-date-contract'),
					key      : 'end_contract',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		}
	},

	data() {
		return {
			pagination : {
				perPage     : 10,
				currentPage : 1,
			},
			items : []
		};
	},
};

</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>