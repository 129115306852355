<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('status')">
			<b-form-select v-model="status" :options="options">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
	</b-col>
</template>
<script>
export default {
	computed : {
		options() {
			return [
				this._i18n.t('active'),
				this._i18n.t('inactive')
			];
		}
	},
	data() {
		return {
			status : null,
		};
	}
};
</script>