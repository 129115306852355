<template>
	<div id="search-partner">
		<div class="d-flex justify-content-between mb-4">
			<h1>
				{{ $t('search-partner') }}
			</h1>
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)">
				<div class="icon arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<b-card no-body>
			<b-card-header class="primary" />
			<b-card-body>
				<b-row>
					<b-col md="6" sm="12">
						<b-form-group :label="$t('siren')">
							<b-form-input type="number" placeholder="_" />
						</b-form-group>
					</b-col>
					<b-col md="6" sm="12">
						<b-form-group :label="$t('nic')">
							<b-form-input type="number" placeholder="_" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<div class="d-flex justify-content-center mt-3">
			<b-button variant="primary"	class="btn-icon" @click="$router.push({name: 'CreatePartner'})">
				<div class="icon search" />
				{{ $t('search') }}
			</b-button>
		</div>
	</div>
</template>

<script>
export default {
	name : 'SearchPartner',
};
</script>