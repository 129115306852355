<template>
	<div id="comments">
		<b-row>
			<b-col lg="4">
				<b-form-group :label="$t('comment')">
					<b-form-input placeholder="_" />
				</b-form-group>
			</b-col>
			<b-col lg="4">
				<b-form-group :label="$t('comment-date')">
					<b-form-datepicker class="mb-2" :placeholder="$t('no-date-selected')"
						:label-no-date-selected="$t('no-date-selected')" />
				</b-form-group>
			</b-col>
			<b-col lg="4" class="d-flex justify-content-end align-items-center">
				<b-button class="btn-icon-only" variant="primary">
					<div class="icon add" />
				</b-button>
			</b-col>
		</b-row>
		<b-table head-variant="light" :striped="true" hover responsive v-if="items.length"
			:fields="fields" :items="items"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(commentdate)="data">
				{{ data.item.date | getDateMonthYear }}
			</template>
			<template #cell(action)>
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<div class="icon edit" />
							</b-button>
						</li>
						<li>
							<b-button v-if="true" variant="primary" class="btn-round-icon">
								<div class="icon on" />
							</b-button>
							<b-button v-else variant="primary" class="btn-round-icon">
								<div class="icon off" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';

export default {
	name       : 'Comments',
	components : {
		Pagination,
	},
	props : {
		wagon : {
			type     : Object,
			required : false,
			default() {
				return {};
			}
		},
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('comment'),
					key      : 'comment',
					sortable : true,
				},
				{
					label    : this._i18n.t('comment-date'),
					key      : 'commentdate',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		}
	},
	data() {
		return {
			pagination : {
				perPage     : 10,
				currentPage : 1,
			},
			items : []
		};
	},
};

</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>