<template>
	<b-card no-body id="charger" class="identification-components">
		<b-card-header>
			{{ $t('charger') }} - TAMUCO
		</b-card-header>
		<b-card-body>
			<b-form>
				<b-row>
					<b-col>
						<p class="title-components">
							{{ $t('charger') }}
						</p>
						<b-row>
							<h3 />
							<b-col v-for="(input, key) in array" :key="key" xl="4">
								<b-form-group :label="$t(input) + ' :'">
									<b-form-input placeholder="_" />
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					<b-col>
						<p class="title-components">
							{{ $t('contact') }}
						</p>
						<b-row>
							<b-col v-for="(input, key) in array" :key="key" xl="4">
								<b-form-group :label="$t(input) + ' :'">
									<b-form-input placeholder="_" />
								</b-form-group>
							</b-col>
							<b-col xl="8" class="d-flex justify-content-end align-items-end">
								<b-button variant="primary" size="sm" class="mb-3">
									{{ $t('edit') }}
								</b-button>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-form>
		</b-card-body>
	</b-card>
</template>

<script>
export default {
	name : 'Charger',
	data() {
		return {
			array : ['name', 'road', 'region', 'siren', 'complement', 'country', 'nic', 'postal-code', 'city',
				'vat-number']
		};
	}
};
</script>