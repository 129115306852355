<template>
	<div id="registrations">
		<h1>{{ $t('registrations') }}</h1>
		<div class="d-flex justify-content-end child-mx-2">
			<b-button variant="primary" class="btn-icon-only">
				<div class="icon sync" />
			</b-button>
		</div>
		<div class="tab-header border-radius-top mt-3">
			<Filters>
				<Search />
				<Status />
			</Filters>
		</div>
		<b-table head-variant="light" :striped="true" hover responsive show-empty
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="items"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(action)="registration">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon"
								@click="registerDetail(registration.item)">
								<div class="icon eye" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Filters from '@/components/filters/Filters.vue';
import { Search, Status } from '@/components/filters/components';
import Pagination from '@/components/Pagination.vue';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
export default {
	name       : 'Registrations',
	components : {
		Filters,
		Search,
		Status,
		Pagination
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('registrations'),
		};
	},
	computed : {
		...mapGetters('registers', {
			list     : 'registers',
			selected : 'selectedRegister'
		}),
		fields() {
			return [
				{
					label    : this._i18n.t('company-name'),
					key      : 'company.name',
					sortable : true,
				},
				{
					label    : this._i18n.t('company-type'),
					key      : 'company.type',
					sortable : true,
				},
				{
					label    : this._i18n.t('siren'),
					key      : 'company.siret.siren',
					sortable : true,
				},
				{
					label    : this._i18n.t('nic'),
					key      : 'company.siret.nic',
					sortable : true,
				},
				{
					label    : this._i18n.t('firstname'),
					key      : 'user.firstname',
					sortable : true,
				},
				{
					label    : this._i18n.t('lastname'),
					key      : 'user.lastname',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			items            : [], //this.$store.getters.registers
			pagination       : {
				perPage     : 10,
				totalRows  	: [],
				currentPage : 1,
			}
		};
	},
	created() {
		this.loadRegisters();
		this.getData();
	},
	methods : {
		...mapActions('registers', {
			loadRegisters  : 'loadRegisters',
			selectRegister : 'selectRegister'
		}),
		registerDetail(item) {
			this.selectRegister(item);
			this.$router.push({
				name   : 'SendRegistration',
				params : {
					id : item._id
				}
			});
		},
		getData() {
			const user = JSON.parse(localStorage.user);
			this.items  = require('@/utils/fakeData/' + user.company.name + '/mockRegistrations.json');
			this.pagination.totalRows = this.items.length;
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/.action-field {
	min-width: 200px;
}
.actions {
	max-width: none;
}
</style>