<template>
	<div id="breadcrumbs">
		<b-breadcrumb class="p-0 child-mx-4">
			<div v-for="(breadcrumb, index) in breadcrumbs" :key="index">
				<b-breadcrumb-item @click="openCollapse($event,breadcrumb)" :id="'breadcrumb-' + breadcrumb">
					<span class="number mr-2">{{ index + 1 }}</span>
					<span>{{ $t(breadcrumb) }}</span>
				</b-breadcrumb-item>
			</div>
		</b-breadcrumb>
	</div>
</template>
<script>
export default {
	name : 'Breadcrumb',
	data() {
		return {
			breadcrumbs : [
				'identification',
				'location',
				'maintenance'
			]
		};
	},
	methods : {
		openCollapse($event, collapse) {
			this.$root.$emit('bv::toggle::collapse', collapse);
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>