import axios from 'axios';

const hostName = process.env.VUE_APP_API_HOST;
// console.log(hostName);
// console.log('VUE_APP_I18N_LOCALE', process.env.VUE_APP_I18N_LOCALE);
// console.log('BASE_URL', process.env.BASE_URL);



export const HTTP = axios.create({
	baseURL         : `${hostName}/api/V1`,
	withCredentials : true,
	'Content-Type'  : 'application/json'
	// credentials     : 'same-origin', //'include'
	// 'Content-Type'  : 'application/json',
	// baseURL : process.env.BASE_URL,
});
