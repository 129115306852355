<template>
	<b-card no-body id="terminals" class="identification-components">
		<b-card-header>
			{{ $t('sites') }}
		</b-card-header>
		<b-card-body>
			<ul>
				<li v-for="(terminal, index) in terminals" :key="index">
					<div class="icon trains primary" v-if="getType(terminal.type, 'rail')" />
					<div class="icon truck primary" v-if="getType(terminal.type, 'road')" />
					<div class="icon ship primary" v-if="getType(terminal.type, 'sea')" />
					<div class="icon plane primary" v-if="getType(terminal.type, 'air')" />
					<b class="ml-2">{{ terminal.name }}</b>
				</li>
			</ul>
			<div class="d-flex justify-content-end">
				<b-button variant="primary" size="sm" class="mt-2" v-b-toggle="'sites'">
					{{ $t('details') }}
				</b-button>
			</div>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	name  : 'ContractTerminals',
	props : {
		terminals : {
			type     : Array,
			required : false,
			default() {
				return {};
			}
		},
	},
	methods : {
		getType(type, icon) {
			if(Array.isArray(type)) {
				for (let i = 0; i < type.length; i++) {
					const e = type[i];
					if(icon == e) return true;
				}
			} else {
				if(icon == type) return type;
			}
		}
	}
};
</script>