<template>
	<div class="technical-informations light">
		<h3 class="bold">
			{{ $t('technicals-informations') }}
		</h3>
		<ul>
			<li v-if="transportPlan.definitionTime">
				<span>{{ $t('route-definition') }}</span>
				<span>{{ transportPlan.definitionTime }}</span>
			</li>
			<li v-if="transportPlan.unitsRail">
				<span>{{ $t('type-of-transport-units-rail') }}</span>
				<span>{{ $t(transportPlan.unitsRail.type) }}</span>
			</li>
			<li v-if="transportPlan.unitsRail">
				<span>{{ $t('number-of-transport-units-rail') }}</span>
				<span>{{ transportPlan.unitsRail.units }}</span>
			</li>
			<li v-if="transportPlan.unitsRoad">
				<span>{{ $t('type-of-transport-units-road') }}</span>
				<span>{{ $t(transportPlan.unitsRoad.type) }}</span>
			</li>
			<li v-if="transportPlan.unitsRoad">
				<span>{{ $t('number-of-transport-units-road') }}</span>
				<span>{{ transportPlan.unitsRoad.units }}</span>
			</li>
			<li v-if="transportPlan.handling">
				<span>{{ $t('type-of-handling-units') }}</span>
				<span>{{ $t(transportPlan.handling.type) }}</span>
			</li>
			<li v-if="transportPlan.handling">
				<span>{{ $t('number-of-handling-units') }}</span>
				<span>{{ transportPlan.handling.units }}</span>
			</li>
			<li>
				<span>{{ $t('weekly-number-potential') }}</span>
				<span>{{ transportPlan.timetable.circulation_number }}</span>
			</li>
			<li v-if="transportPlan.capacity.max_weight">
				<span>{{ $t('max-tonnage-train') }}</span>
				<span>{{ transportPlan.capacity.max_weight }} T</span>
			</li>
			<li v-if="transportPlan.capacity.max_length">
				<span>{{ $t('total-length-train') }}</span>
				<span>{{ transportPlan.capacity.max_length }} M</span>
			</li>
		</ul>
	</div>
</template>
<script>
import Moment from 'moment';

export default {
	props : {
		transportPlan : {
			required : true,
			type     : Object,
		}
	},
	created() {
		if (this.transportPlan.timetable && this.transportPlan.timetable.departure_time) {
			const hours_minutes = this.transportPlan.timetable.departure_time.split(':');
			const start = Moment().hour(hours_minutes[0]).minute(hours_minutes[1]);
			let end = start.clone();
			end.add(this.transportPlan.timetable.duration, 'minutes');
			end = Moment(end).hour(23);
			const endTime = end.diff(start, 'days');
			const nextCharcode = 65 + endTime;
			this.transportPlan.definitionTime = 'A/' + String.fromCharCode(nextCharcode);
		}
	}
};
</script>
<style lang="scss" scoped>
.technical-informations {
	max-width: 542px;
	width: 50%;
}
</style>