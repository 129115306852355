<template>
	<b-card no-body>
		<b-card-header>
			{{ $t('roads') }}
		</b-card-header>
		<b-card-body>
			<div class="mb-3">
				<b-form-group :label="$t('filter-per-origin') + ' :'">
					<b-form-select v-model="filter" :options="options" />
				</b-form-group>
			</div>
			<div class="list selected">
				<div class="container-item" v-for=" (transportPlan, index) in transportPlans" :key="index"
					@click="selectItem(transportPlan)">
					<div class="item">
						<Informations :transport-plan="transportPlan" />
						<HorizontalVisualization :segments="transportPlan.segments" class="mt-4" />
					</div>
				</div>
			</div>
		</b-card-body>
	</b-card>
</template>
<script>
import Informations from '@/components/segments/Informations';
import HorizontalVisualization from '@/components/transportPlan/HorizontalVisualization';
export default {
	name       : 'RoadList',
	components : {
		HorizontalVisualization,
		Informations
	},
	props : {
		order : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			transportPlans : [],
			filter         : 'all',
		};
	},
	computed : {
		options() {
			return [
				{
					value : 'all',
					text  : this._i18n.t('all')
				}
			];
		}
	},
	created() {
		this.getData();
		this.transportPlans.forEach((e) => {
			const origin = e.origin;
			if(!this.options.find((opt) => opt.value == origin.origin_id)) {
				this.options.push({ value : origin.origin_id,
					text  : origin.name });
			}
		});
	},
	methods : {
		selectItem(road) {
			Array.from(document.getElementsByClassName('container-item')).forEach((e) => {
				e.classList.remove('active');
			});
			event.target.closest('.container-item').classList.add('active');
			this.order.road = road;
		},
		getData() {
			const user = JSON.parse(localStorage.user);
			this.routes  = require('@/utils/fakeData/' + user.company.name + '/mockRoutes.json');
			this.transportPlans = [...this.routes];
		}
	},
	watch : {
		filter(val) {
			this.transportPlans = [];
			if(val == 'all') {
				this.transportPlans = [...this.routes];
			}
			this.routes.forEach((e) => {
				const origin = e.origin;
				if(origin.origin_id == val) {
					this.transportPlans.push(e);
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/ .card-body {
	max-height: 999px;
	overflow-y: auto;
}
/deep/ .horizontal.visualization {
	width: 55%;
}
/deep/ .list .container-item .informations > li:nth-child(2) {
	justify-content: flex-start;
}
/deep/ .list .container-item .informations li:nth-child(2) p {
	padding-left: 0;
}
/deep/ .list .container-item .informations > li:nth-child(4) {
	justify-content: flex-end;
}
/deep/ .list .container-item .informations li:nth-child(4) p {
	padding-right: 0;
}
/deep/ .list .visualization.horizontal #last-destination {
	left: 100%;
}
</style>
<i18n>
{
	"fr": {
		"filter-per-origin": "Filtrer par origine"
	},
	"en": {
		"filter-per-origin": "Filter per origin"
	}
}
</i18n>