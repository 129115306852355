<template>
	<b-card no-body>
		<b-card-header>{{ $t('edit-the-order') }}</b-card-header>
		<b-card-body>
			<b-form v-if="items.currentRoad">
				<b-form-group :label="$t('new-road')">
					<b-form-select :value="null" :options="getRoads()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>
								_
							</b-form-select-option>
						</template>
					</b-form-select>
					<div class="d-flex justify-content-between mt-3 align-items-center">
						<b-button variant="primary" @click="removeRoad()">
							{{ $t('valid') }}
						</b-button>
					</div>
				</b-form-group>
			</b-form>
			<b-form v-if="items.selectedDate">
				<b-form-group :label="$t('new-date')">
					<b-form-datepicker :label-no-date-selected="$t('no-date-selected')" boundary="viewPort"
						:locale="_i18n.locale" :value="getDatePickerValue" @input="setNewDate($event)"
						:min="items.selectedDate.clone().add(1, 'days').format('YYYY-MM-DD')" />
				</b-form-group>
				<div class="d-flex justify-content-between mt-3 align-items-center">
					<p v-if="items.newDate">
						{{ $t('price') }} : {{ getPrice() }} €
					</p>
					<b-button variant="primary" @click="removeDate()">
						{{ $t('valid') }}
					</b-button>
				</div>
			</b-form>
		</b-card-body>
	</b-card>
</template>
<script>
import Moment from 'moment';
export default {
	props : {
		items : {
			required : true,
			type     : Object,
		}
	},
	computed : {
		getDatePickerValue() {
			const t = this;
			if(t.items.newDate) {
				return t.items.newDate.format('YYYY-MM-DD');
			} else {
				return null;
			}
		}
	},
	methods : {
		removeDate() {
			const t = this;
			t.items.selectedDate = null;
			t.items.newDate = null;
			t.$emit('set-items', t.items);
		},
		removeRoad() {
			const t = this;
			t.items.currentRoad = null;
			t.$emit('set-items', t.items);
		},
		setNewDate(val) {
			const t = this;
			const newDate = Moment(val, 'YYYY-MM-DD').startOf('days');
			if(newDate.diff(t.items.selectedDate, 'days', true) > 0) {
				t.items.newDate = newDate;
				t.$emit('set-items', t.items);
			}
		},
		getRoads() {
			const t = this;
			const roads = [];
			for (let i = 0; i < t.items.roads.length; i++) {
				const road = t.items.roads[i];
				if(t.items.currentRoad.origin.origin_id == road.origin.origin_id) {
					roads.push(road.name);
				}
			}
			return roads;
		},
		getPrice() {
			const diff = this.items.newDate.diff(this.items.selectedDate, 'days');
			if(diff < 7) {
				return 1200;
			} else if(diff < 14) {
				return 600;
			} else {
				return 0;
			}
		}
	}

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
form {
	text-transform: initial;
	&:nth-child(2n) {
		margin-top: 0.5rem;
		padding-top: 0.5rem;
		border-top: 1px solid $light-grey;
	}
}
/deep/.b-form-spinbutton bdi {
	position: relative;
	padding-right: 10px;
	&:after {
		content: '€';
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		color: $black;
		font-size: 1rem;
	}
}
/deep/.b-form-spinbutton svg {
	fill: $primary;
	font-size: 100% !important;
	transform: none !important;
}
/deep/.b-form-spinbutton button {
	&:hover {
		background-color: $primary;
	}
}
/deep/.b-form-spinbutton button:hover svg {
	fill: $white;
}
</style>