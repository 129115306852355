<template>
	<main id="update-collaborator">
		<h1 class="mb-4">
			{{ $t('update-collaborator') }}
		</h1>
		<b-row>
			<b-col xl="12">
				<b-card no-body id="updatecollaborator">
					<b-card-header class="primary" />
					<b-card-body>
						<CollaboratorsFields :collaborator="collaborator" />
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
		<b-row>
			<b-col xl="6" class="d-flex justify-content-end">
				<b-button class="btn-icon mt-3" variant="info"
					@click="$router.push({name: 'CollaboratorList'})">
					<div class="icon arrow-back" />
					{{ $t('return') }}
				</b-button>
			</b-col>
			<b-col xl="6">
				<b-button class="btn-icon mt-3" variant="primary"
					@click="$router.push({name: 'CollaboratorList'})">
					<div class="icon save" />
					{{ $t('save') }}
				</b-button>
			</b-col>
		</b-row>
	</main>
</template>

<script>
import CollaboratorsFields from '../collaborators/components/CollaboratorsFields.vue';

export default {
	name       : 'UpdateCollaborator',
	components : {
		CollaboratorsFields
	},

	created() {
		const id = this.$route.params.id;
		const user = JSON.parse(localStorage.user);
		const collaboratorsMocker  = require('@/utils/fakeData/' + user.company.name + '/mockCollaborators.json');
		this.collaborator = collaboratorsMocker.find(collaborator => collaborator._id === id);
	},

	data() {
		return {
			collaborator : {}
		};
	},
};
</script>