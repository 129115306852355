<template>
	<main id="add-collaborator">
		<div class="d-flex justify-content-between align-items-center">
			<h1 class="mb-4">
				{{ $t('create-collaborator') }}
			</h1>
			<b-button class="btn-icon" variant="info"
				@click="$router.push({name: 'CollaboratorList'})">
				<div class="icon arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<b-card no-body>
			<b-card-header class="primary" />
			<b-card-body>
				<CollaboratorsFields />
			</b-card-body>
		</b-card>
		<div class="d-flex justify-content-center">
			<b-button class="btn-icon mt-3" variant="primary"
				@click="$router.push({name: 'CollaboratorList'})">
				<div class="icon save" />
				{{ $t('save') }}
			</b-button>
		</div>
	</main>
</template>

<script>
import CollaboratorsFields from '../collaborators/components/CollaboratorsFields.vue';

export default {
	name       : 'CreateCollaborator',
	components : {
		CollaboratorsFields
	},
};
</script>
<style lang="scss" scoped>
.card-body {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.card-body fieldset {
	width: calc(50% - 30px);
	margin: 0 15px 1rem 15px;
}
</style>