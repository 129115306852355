<template>
	<div>
		<ul class="informations">
			<li>
				<p>
					<b>{{ order.int_code || segment.internal_reference || transportPlan.road }}</b>
					<span v-if="order.freight">{{ order.freight.type }} : {{ order.freight.quantity }}</span>
					<span>
						{{ (order.client && order.client.name) || (segment.operator && segment.operator.operator_name)
							||transportPlan.name }}
					</span>
				</p>
			</li>
			<li>
				<p class="text-center">
					<b>{{ order.origin.name || segment.origin.name || transportPlan.origin.name }}</b>
					<span v-if="order.departure_date">{{ order.departure_date | getFullDate }}</span>
					<span v-else-if="segment.timetable">{{ segment.timetable.departure_time }}</span>
				</p>
			</li>
			<li>
				<DateList v-if="!order.int_code" :date-list="transportPlan.dateList || segment.timetable"
					:segment="segment" />
				<!-- TEMPORARY ELEMENT FOR DEMO, TO CORRECT WITH WRIGHT ORDER MODEL -->
				<p class="text-center" v-if="order && order.delivery">
					<b>{{ order.delivery.name }}</b>
					<span v-if="order.delivery_date">{{ order.delivery_date | getFullDate }}</span>
				</p>
				<p class="text-center" v-if="transportPlan && transportPlan.delivery">
					<b>{{ transportPlan.delivery.name }}</b>
					<span v-if="transportPlan.delivery_date">{{ transportPlan.delivery_date | getFullDate }}</span>
				</p>
			</li>

			<li>
				<p class="text-center">
					<b>{{ order.destination.name || segment.destination.name || transportPlan.destination.name }}</b>
					<span v-if="order.arrival_date">{{ order.arrival_date | getFullDate }}</span>
					<span v-else-if="segment.timetable">
						{{ segment.timetable.arrival_time }}
						<span v-if="getDaysPlusTransportPlan(segment) > 0">
							{{ $t('day-letter') }}+{{ getDaysPlusTransportPlan(segment) }}
						</span>
					</span>
				</p>
			</li>
			<li>
				<Actions v-if="order.int_code" />
				<Statut v-if="segment._id" :segment="segment" />
			</li>
		</ul>
	</div>
</template>
<script>
import Informations from '@/mixins/segments/Informations';
import Actions from '@/components/Actions.vue';
import DateList from './DateList.vue';
import Statut from './Statut.vue';
export default {
	name       : 'Informations',
	components : {
		Actions,
		DateList,
		Statut
	},
	mixins : [
		Informations,
	],
	props : {
		order : {
			type     : Object,
			required : false,
			default() {
				return {
					client_id   : {},
					origin      : {},
					destination : {}
				};
			}
		},
		segment : {
			type     : Object,
			required : false,
			default() {
				return {
					origin      : {},
					destination : {}
				};
			}
		},
		transportPlan : {
			type     : Object,
			required : false,
			default() {
				return {
					origin      : {},
					destination : {}
				};
			}
		},
	},

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.informations {
	color: $secondary;
}
</style>