<template>
	<div class="technical-informations light" width="50%">
		<h3 class="bold">
			{{ $t('technicals-informations') }}
		</h3>
		<ul>
			<li>
				<span>{{ $t('number-of-transport-units') }}</span>
				<span> {{ segment.volumetry.units }} </span>
			</li>
			<li>
				<span>{{ $t('type-of-transport-units') }}</span>
				<span> {{ $t(segment.volumetry.trans_unit) }} </span>
			</li>
			<li v-if="segment.origin.length">
				<span>{{ $t('length-of-the-departure-lanes') }}</span>
				<span> {{ segment.origin.length }} m </span>
			</li>
			<li v-if="segment.origin.docks">
				<span>{{ $t('number-of-docks-at-start') }}</span>
				<span> {{ segment.origin.docks }} </span>
			</li>
			<li v-if="segment.destination.length">
				<span>{{ $t('length-of-the-arrival-lanes') }}</span>
				<span> {{ segment.destination.length }} m </span>
			</li>
			<li v-if="segment.destination.docks">
				<span>{{ $t('number-of-docks-at-arrival') }}</span>
				<span> {{ segment.destination.docks }} </span>
			</li>
			<li>
				<span>{{ $t('handler-at-start') }}</span>
				<span>{{ segment.origin.handler }}</span>
			</li>
			<li v-if="segment.origin && segment.origin.handling_time != undefined">
				<span>{{ $t('departure-handling-time') }} {{ $t('total') }}</span>
				<span>
					<div v-if="segment.origin.handling_time != 0">
						{{ segment.origin.handling_time }} h
					</div>
					<div v-else>
						{{ $t('immediate') }}
					</div>
				</span>
			</li>

			<li v-if="segment.origin && segment.origin.handling_time_per_unit != undefined">
				<span>{{ $t('departure-handling-time') }} / {{ $t('transport-units') }}</span>
				<span>
					<div v-if="segment.origin.handling_time_per_unit != 0">
						{{ segment.origin.handling_time_per_unit }} h
					</div>
					<div v-else>
						{{ $t('immediate') }}
					</div>
				</span>
			</li>
			<li>
				<span>{{ $t('handler-at-arrival') }}</span>
				<span>{{ segment.destination.handler }}</span>
			</li>
			<li v-if="segment.destination && segment.destination.handling_time != undefined">
				<span>{{ $t('arrival-handling-time') }} {{ $t('total') }}</span>
				<span>
					<div v-if="segment.destination.handling_time != 0">
						{{ segment.destination.handling_time }} h
					</div>
					<div v-else>
						{{ $t('immediate') }}
					</div>
				</span>
			</li>
			<li v-if="segment.destination && segment.destination.handling_time_per_unit != undefined">
				<span>{{ $t('arrival-handling-time') }} / {{ $t('transport-units') }}</span>
				<span>
					<div v-if="segment.destination.handling_time_per_unit != 0">
						{{ segment.destination.handling_time_per_unit }} h
					</div>
					<div v-else>
						{{ $t('immediate') }}
					</div>
				</span>
			</li>


			<li v-if="segment.definitionTime ">
				<span>{{ $t('route-definition') }}</span>
				<span> {{ segment.definitionTime }} </span>
			</li>
			<li v-if="segment.handling && segment.handling.type">
				<span>{{ $t('type-of-handling-units') }}</span>
				<span>
					<div> {{ $t(segment.handling.type) }} </div>
				</span>
			</li>
			<li v-if="segment.handling && segment.handling.units">
				<span>{{ $t('number-of-handling-units') }}</span>
				<span>
					<div> {{ segment.handling.units }} </div>
				</span>
			</li>
			<li>
				<span>{{ $t('weekly-number-potential') }}</span>
				<span> {{ segment.timetable.days.length }} </span>
			</li>
			<li v-if="segment.tech_constrains && segment.tech_constrains.max_length">
				<span>{{ $t('max-length-train') }}</span>
				<span> {{ segment.tech_constrains.max_length }} m </span>
			</li>
			<li v-if="segment.tech_constrains && segment.tech_constrains.max_weight">
				<span>{{ $t('max-tonnage-train') }}</span>
				<span> {{ segment.tech_constrains.max_weight }} T</span>
			</li>
		</ul>
	</div>
</template>
<script>
import Moment from 'moment';
export default {
	props : {
		segment : {
			required : true,
			type     : Object,
		}
	},
	created() {
		if (this.segment.timetable && this.segment.timetable.departure_time) {
			const hours_minutes = this.segment.timetable.departure_time.split(':');
			const start = Moment().hour(hours_minutes[0]).minute(hours_minutes[1]);
			let end = start.clone();
			end.add(this.segment.timetable.duration, 'minutes');
			end = Moment(end).hour(23);
			const endTime = end.diff(start, 'days');
			const nextCharcode = 65 + endTime;
			this.segment.definitionTime = 'A/' + String.fromCharCode(nextCharcode);
		}
	}


};
</script>
<style lang="scss" scoped>
.technical-informations {
	max-width: 800px;
	width: 90%;
}
</style>