import { HTTP } from '../../http-common';

const state = () => ({
	registers        : null,
	selectedRegister : null,
});

const getters = {
	registers        : state => state.registers,
	selectedRegister : state => state.selectedRegister,
};

const mutations = {
	SAVED_REGISTERS(state, payload) {
		// console.log(payload);
		state.registers = payload;
	},
	SAVED_REGISTER(state, payload) {
		// console.log(payload);
		state.selectedRegister = payload;
	},
	SELECT_REGISTER(state, index) {
		if (state.registers && state.registers.length >= index) {
			state.selectedRegister = state.registers[index];
		}
	},
	CLEAR_REGISTER(state) {
		// console.log('CLEAR_REGISTER');
		state.selectedRegister = null;
	}
};
const actions = {
	loadRegisters(context) {
		HTTP.get('/registers').then(res => {
			// const registers = JSON.stringify(res.data);

			context.commit('SAVED_REGISTERS', res.data.list);
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	selectRegister(context, index) {
		context.commit('SAVED_REGISTER', index);
	},
	async createRegister(context, payload) {
		const res = await HTTP.post('/registers', payload);
		if(res.insertedId) {
			context.commit('SAVED_REGISTER', payload);
		}
		return res;
	},
	async validateRegister(context, id) {
		HTTP.get('/registers/' + id + '/validate').then(res => {
			if(res.insertedId) {
				context.commit('CLEAR_REGISTER');
			}
		}).catch(err => {
			throw err.response.data.message;
		});
	}
};
export default {
	namespaced : true,
	state,
	getters,
	actions,
	mutations
};