<template>
	<b-card no-body id="charger" class="identification-components">
		<b-card-header>
			{{ $t(contractor.type) }} - {{ contractor.name }}
		</b-card-header>
		<b-card-body>
			<ul>
				<li>
					<p class="title-components">
						{{ contractor.name }}
					</p>
					<p>{{ $t('siren') }} : {{ contractor.siret.siren }}</p>
					<p>{{ $t('nic') }} : {{ contractor.siret.nic }}</p>
					<p>{{ $t('vat-number') }} : {{ contractor.vat }}</p>
					<p>
						{{ contractor.address.number }} {{ contractor.address.street }},
						{{ contractor.address.city }}, {{ contractor.address.country }}
					</p>
				</li>
				<li v-for="(contact, ind) in contractor.contacts" :key="ind">
					<p class="title-components">
						{{ $t('contact') }}
					</p>
					<p>{{ contact.firstname }} {{ contact.lastname }}</p>
					<p>{{ $t('phone') }}: {{ contact.phone }}</p>
					<p>{{ contact.email }}</p>
					<p>
						{{ contact.address.number }} {{ contact.address.street }},
						{{ contact.address.city }}, {{ contact.address.country }}
					</p>
				</li>
			</ul>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	name       : 'Charger',
	props : {
		contractor : {
			type     : Object,
			required : true,
		}
	}
};
</script>