<template>
	<b-card no-body>
		<b-card-header>Selectionner une date</b-card-header>
		<b-card-body>
			<div class="d-flex justify-content-center">
				<ul>
					<li v-for="(day, index) in days" :key="index" @click="setDate($event, day)"
						:class="{'active' : day.active}">
						<div class="vertical-rectangle mr-1" :style="'background-color:' + items.currentRoad.color" />
						<div class="date">
							{{ day.value.format('DD/MM/YYYY') }}
						</div>
					</li>
				</ul>
			</div>
		</b-card-body>
	</b-card>
</template>
<script>
import Moment from 'moment';
export default {
	props : {
		items : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			days : []
		};
	},
	created() {
		this.getDays();
	},
	computed : {
		selectedDate() {
			return this.items.selectedDate;
		},
		currentRoad() {
			return this.items.currentRoad;
		}
	},
	watch : {
		currentRoad() {
			this.getDays();
			this.addActive();
		},
		selectedDate() {
			this.addActive();
		}
	},
	methods : {
		getDays() {
			const t = this;
			const orders = t.items.orders;
			t.days = [];
			for (let i = 0; i < orders.length; i++) {
				const order = orders[i];
				if(t.items.currentRoad._id == order.road_id) {
					const departureDate = Moment(order.departure_date).startOf('day');
					t.days.push({
						value  : departureDate,
						active : false
					});
					const arrivalDate = Moment(order.arrival_date).startOf('day');
					t.days.push({
						value  : arrivalDate,
						active  : false
					});
				}
			}
			t.days.sort((a, b) => {
				return a.value - b.value;
			});
		},
		setDate(event, day) {
			const t = this;
			const newDate = t.items.newDate;
			t.removeActive();
			day.active = true;
			if(newDate) {
				if(newDate.diff(day, 'days') < 0) {
					t.items.newDate = null;
					this.$emit('set-items', t.items);
				}
			}
			t.items.selectedDate = day.value;
			this.$emit('set-items', t.items);
		},
		removeActive() {
			for (let i = 0; i < this.days.length; i++) {
				const day = this.days[i];
				day.active = false;
			}
		},
		addActive() {
			const t = this;
			t.removeActive();
			for (let i = 0; i < t.days.length; i++) {
				const day = t.days[i];
				if(day.value.diff(t.items.selectedDate) == 0) {
					day.active = true;
					break;
				}
			}
		}
	}

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.card-body {
	max-height: 139px;
	overflow-y: auto;
}
ul {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
ul li {
	display: flex;
	align-items: center;
	background-color: $white;
	border-radius: $border-radius;
	box-shadow: $shadow;
	border: 2px solid $white;
	padding: 0 0.5rem;
	margin: 1%;
	width: 31%;
	min-width: 105px;
	cursor: pointer;
}
ul li.active {
	border-color: $green;
}
</style>