<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('group')">
			<b-form-select v-model="group" :options="options">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
	</b-col>
</template>
<script>
export default {
	props : {
		items : {
			required : true,
			type     : Array
		}
	},
	computed : {
		options() {
			return [
				{
					value : 1,
					text  : 'Parc 1'
				},
				{
					value : 2,
					text  : 'Parc 2'
				},
				{
					value : 3,
					text  : 'Réserve'
				}
			];
		}
	},
	created() {
		const groupParam = this.$route.query.group;
		if(groupParam) {
			this.group = groupParam;
		}
	},
	data() {
		return {
			group : null,
		};
	},
	methods : {
		reset() {
			this.group = null;
		}
	},
	watch : {
		group(val) {
			let items = this.items;
			if(val) {
				items = items.filter(item => item.group._id == val);
			}
			this.$emit('set-items', items);
		}
	}
};
</script>