<template>
	<div id="RentalFields">
		<b-card no-body>
			<b-card-header>
				{{ $t('company') }}
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('company-type')">
							<b-form-input type="text" placeholder="_" v-model="registration.company.type" />
						</b-form-group>
						<b-row>
							<b-col lg="6">
								<b-form-group :label="$t('siren')">
									<b-form-input type="text" placeholder="_"
										v-model="registration.company.siret.siren" />
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group :label="$t('nic')" class="ml-4">
									<b-form-input type="text" placeholder="_"
										v-model="registration.company.siret.nic" />
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('company-name')">
							<b-form-input type="text" placeholder="_"
								v-model="registration.company.name" />
						</b-form-group>
						<b-form-group :label="$t('intra-community-vat-number')">
							<b-form-input type="text" placeholder="_"
								v-model="registration.company.tva" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-card no-body class="mt-3">
			<b-card-header>
				{{ $t('address') }}
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('street')">
							<b-form-input type="text" placeholder="_"
								v-model="registration.company.address.street" />
						</b-form-group>

						<b-form-group :label="$t('postal-code')">
							<b-form-input type="text" placeholder="_"
								v-model="registration.company.address.code" />
						</b-form-group>

						<b-form-group :label="$t('department-or-region')">
							<b-form-input type="text" placeholder="_"
								v-model="registration.company.address.department" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('complement-address')">
							<b-form-input type="text" placeholder="_"
								v-model="registration.company.address.other" />
						</b-form-group>

						<b-form-group :label="$t('city')">
							<b-form-input type="text" placeholder="_"
								v-model="registration.company.address.city" />
						</b-form-group>

						<b-form-group :label="$t('country')">
							<b-form-input type="text" placeholder="_"
								v-model="registration.company.address.country" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
export default {
	name       : 'CompanyFields',
	props : {
		registration : {
			type     : Object,
			required : true,
		}
	},

	data() {
		return {
		};
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>