<template>
	<main id="add-global-order">
		<h1 class="mb-0">
			{{ $t('create-order') }}
		</h1>
		<h4 class="mb-3">
			{{ $t('contract') }} : N° 310022
		</h4>
		<b-row>
			<b-col xl="6">
				<RoadList :order="order" />
			</b-col>
			<b-col xl="6" class="p-0">
				<b-row>
					<b-col xl="12">
						<Dates class="mb-3" :order="order" />
					</b-col>
					<b-col xl="12">
						<Wagons :order="order" />
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<div class="d-flex align-items-center flex-column">
			<ul id="info-order" class="mt-3">
				<li>
					<span>{{ $t('road') }} : </span>
					<span v-if="order.road">{{ order.road.name }}</span>
					<span v-else />
				</li>
				<li>
					<span>{{ $t('date') }} : </span>
					<span v-if="order.date">{{ order.date | getDateMonthYear }}</span>
					<span v-else />
				</li>
				<li class="d-flex">
					<span>{{ $t('wagons') }} : </span>
					<p class="d-flex flex-column">
						<span v-for="wagon in order.wagons" :key="wagon">
							{{ wagon }}
						</span>
					</p>
				</li>
			</ul>
			<div class="d-flex mt-3">
				<b-button variant="info" @click="reset">
					{{ $t('cancel') }}
				</b-button>
				<b-button variant="primary" class="btn-icon ml-2"
					:disabled="!order.date || !order.road || !order.wagons.length"
					@click="$router.push('/orders/list')">
					<div class="icon save" />
					{{ $t('save') }}
				</b-button>
			</div>
		</div>
	</main>
</template>
<script>
import { RoadList, Dates, Wagons } from './components/createOrder';
export default {
	name       : 'CreateOrder',
	components : {
		RoadList,
		Dates,
		Wagons
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('add-global-order'),
		};
	},
	data() {
		return {
			order : {
				wagons : [],
				road   : null,
			}
		};
	},
	methods : {
		reset() {
			this.order.wagons = [];
			this.order.road = null;
			this.order.date = null;
			Array.from(document.getElementsByTagName('tr')).forEach((e) => {
				e.classList.remove('active');
			});
			Array.from(document.getElementsByClassName('container-item ')).forEach((e) => {
				e.classList.remove('active');
			});
		}
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#info-order {
	font-weight: 600;
}
#info-order li > span {
	min-width: 100px;
	display: inline-block;
	&:first-child {
		color: $grey;
	}
	&:last-child {
		color: $thirdary;
	}
}
#info-order li p {
	display: flex;
	color: $thirdary;
}
/deep/ .date-list {
	display: none;
}
</style>