<template>
	<div>
		<b-row>
			<b-col xl="12" lg="12">
				<Delay />
			</b-col>
			<b-col xl="6" lg="12">
				<b-row>
					<b-col xl="12" lg="6" class="mt-3">
						<Changes />
					</b-col>
					<b-col xl="12" lg="6" class="mt-3">
						<Cancellations />
					</b-col>
				</b-row>
			</b-col>
			<b-col xl="6" lg="12" class="mt-3">
				<Penalties />
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { Changes, Cancellations, Penalties, Delay } from './components';
export default {
	name       : 'PricingConitions',
	components : {
		Changes,
		Cancellations,
		Penalties,
		Delay
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/h5 {
	color: $secondary;
	font-weight: 600;
	width: 80%;
	margin-bottom: 1rem;
	text-align: center;
}
</style>