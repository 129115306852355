import { HTTP } from '../../http-common';
const state = () => ({
	user : localStorage.getItem('user') || null,
});

const getters = {
	get : state => JSON.parse(state.user),
};

const mutations = {
	setUser : (state, user) => {
		state.user = user;
	},
};
const actions = {
	async LOGIN(context, user) {
		const basic = 'basic ' + btoa(`${user.email}:${user.password}`);
		await HTTP.get('/users/login', {
			headers        : {
				Authorization : basic
			}
		}).then(res => {
			const userData = JSON.stringify(res.data);
			localStorage.setItem('user', userData);
			context.commit('setUser', userData);
		}).catch(err => {
			localStorage.removeItem('user');
			throw err.response.data.message;
		});
	},
	LOGOUT(context) {
		localStorage.removeItem('user');
		context.commit('setUser', null);
	}
};
export default {
	namespaced : true,
	state,
	getters,
	actions,
	mutations
};