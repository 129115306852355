<template>
	<ul :class="{'light' : !order.int_code, 'horizontal visualization' : true}">
		<li v-for="(segment, key) in segments" :key="key" :class="segment.status">
			<div class="segment-container" :class="segment.statut + ' ' + segment.finished">
				<div class="current-position" v-if="checkCurrentPosition(segment)"
					:style="{left : order.current_position.position + '%'}">
					<div class="icon flag green" />
				</div>
				<div class="error-segment" v-if="segment.statut === 'error'">
					<div class="icon warning red" />
				</div>
				<div class="position" :id="'position-' + key">
					<span v-if="key != 0 && segments.length < 5">
						{{ segment.origin.name }}
					</span>
				</div>
				<b-tooltip v-if="key != 0 && segments.length >= 5"
					:target="'position-' + key" triggers="hover" placement="top">
					{{ segment.origin.name }}
				</b-tooltip>
				<div :class="'segment ' + segment.type">
					<div class="road-element" v-if="segment.type == 'road'" />
				</div>
				<div v-if="key == (segments.length - 1)" class="position" id="last-destination" />
			</div>
		</li>
	</ul>
</template>
<script>
export default {
	name  : 'HorizontalVisualization',
	props : {
		segments : {
			type     : Array,
			required : true,
		},
		order : {
			type     : Object,
			required : false,
			default() {
				return {
					current_position : {}
				};
			}
		},
	},
	methods : {
		checkCurrentPosition(segment) {
			return this.order.current_position.segment_id && segment._id == this.order.current_position.segment_id;
		}
	},
	created() {
		if(this.segments.length > 1) {
			this.segments.forEach((segment, index) => {
				if(segment.statut == 'finished') {
					if(this.segments[index + 1]) {
						this.segments[index + 1].finished = 'started';
					}
				}
			});
		}
		const lastSegments = this.segments[this.segments.length - 1];
		const orderPosition = this.order.current_position;
		if(lastSegments._id ==  orderPosition.segment_id && orderPosition.position == 100) {
			orderPosition.position = 97;
		}
	}

};
</script>