<template>
	<b-card no-body id="segments" class="identification-components">
		<b-card-header>
			{{ $t('segments') }}
		</b-card-header>
		<b-card-body>
			<ul>
				<li v-for="segment in segments" :key="segment._id">
					<div>
						<div v-if="segment.type=== 'rail'" class="icon primary trains" />
						<div v-if="segment.type=== 'road'" class="icon primary truck" />
						<div v-if="segment.type=== 'air'" class="icon primary plane" />
						<div v-if="segment.type=== 'sea'" class="icon primary ship" />
						<b class="ml-2">{{ segment.origin.name }}</b>
					</div>
					<div>
						<b class="mr-2">{{ segment.destination.name }}</b>
						<div v-if="segment.type=== 'rail'" class="icon primary trains" />
						<div v-if="segment.type=== 'road'" class="icon primary truck" />
						<div v-if="segment.type=== 'air'" class="icon primary plane" />
						<div v-if="segment.type=== 'sea'" class="icon primary ship" />
					</div>
				</li>
			</ul>
			<div class="d-flex justify-content-end">
				<b-button variant="primary" size="sm" class="mt-2" v-b-toggle="'segments'">
					{{ $t('details') }}
				</b-button>
			</div>
		</b-card-body>
	</b-card>
</template>
<style lang="scss" scoped>
ul li {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
ul li div {
	display: flex;
	align-items: center;
}
</style>
<script>
export default {
	name : 'ContractSegments',

	props : {
		segments : {
			type     : Array,
			required : false,
			default() {
				return {};
			}
		},
	}
};
</script>