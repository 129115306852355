<template>
	<main id="register">
		<router-link to="/login" id="login">
			<b-button variant="light">
				{{ $t('login') }}
			</b-button>
		</router-link>
		<div class="form-container">
			<h1 class="my-3" v-if="step != 'confirmation'">
				<span>{{ $t('register') }}</span>
			</h1>
			<b-breadcrumb v-if="step != 'confirmation'">
				<b-breadcrumb-item id="breadcrumb-society" @click="goToStep('society')" class="active">
					<span class="number mr-2">1</span>
					<span>{{ $t('company') }}</span>
				</b-breadcrumb-item>
				<b-breadcrumb-item id="breadcrumb-address" @click="goToStep('address')">
					<span class="number mr-2">2</span>
					<span>{{ $t('address') }}</span>
				</b-breadcrumb-item>
				<b-breadcrumb-item id="breadcrumb-user" @click="goToStep('user')">
					<span class="number mr-2">3</span>
					<span>{{ $t('user') }}</span>
				</b-breadcrumb-item>
			</b-breadcrumb>
			<Society v-if="step === 'society'" @go-to-step="goToStep" />
			<SocietyAddress v-if="step === 'address'" @go-to-step="goToStep" />
			<User v-if="step === 'user'" :user="user" @go-to-step="goToStep" />
			<Confirmation v-if="step == 'confirmation'" />
		</div>
	</main>
</template>

<script>
//import { HTTP } from '../../http-common';
import { Society, SocietyAddress, User, Confirmation } from './components/';
export default {
	name       : 'FormRegister',
	components : {
		Society,
		SocietyAddress,
		User,
		Confirmation
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('register'),
		};
	},
	data() {
		return {
			step : 'society',
			user : {
				sameAddress : true,
			},
			company : {},
			errors  : []
		};
	},
	created() {
		if(this.$route.params.step) {
			this.step = this.$route.params.step;
		} else {
			this.$router.push('/register/society');
		}
	/* 	HTTP.get('/ping')
			.then(response => {
				console.log(response);
			})
			.catch(e => {
				this.errors.push(e);
			}); */
	},
	watch : {
		step(val) {
			Array.from(document.getElementsByClassName('breadcrumb-item')).forEach((e) => {
				e.classList.remove('active');
			});
			const breadcrumb = document.getElementById('breadcrumb-' + val);
			if(breadcrumb) breadcrumb.classList.add('active');
		},
	},
	methods : {
		goToStep(step) {
			this.step = step;
			this.$router.push('/register/' + step);
		}
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
main {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
h1 {
	font-weight: 600;
}
#login {
	position: absolute;
	top: 20px;
	right: 20px;
}
.form-container {
	width: 100%;
	max-height: 85%;
	overflow-y: auto;
	max-width: 650px;
	color: $black;
	display: flex;
	flex-direction: column;
	border-radius: $border-radius;
	box-shadow: 0 0 5px $black;
	height: fit-content;
	background-color: $white;
	align-items: center;
	padding: 25px 50px;
}
/deep/ form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
/deep/ .custom-checkbox {
	width: 100%;
	display: flex;
}
.form-container /deep/ .form-group {
	width: 49%;
}
.breadcrumb {
	justify-content: center;
}
.breadcrumb-item {
	margin-top: 0;
}

@include media-breakpoint-down(xs) {
	.form-container {
		padding: 25px;
	}
	.form-container /deep/ .form-group {
		width: 100%;
	}
	.breadcrumb {
		width: 100%;
		justify-content: space-between;
	}
	.breadcrumb-item {
		padding: 0;
		margin: 0;
	}
	.breadcrumb-item a {
		flex-direction: column;
	}
}
</style>
