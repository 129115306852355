<template>
	<div class="calendar-planning" @scroll="addMonths($event)">
		<Weekdays />
		<ul class="days">
			<Day v-for="(day, index) in days" :items="items"
				:key="index" :day="day" :index="index" />
		</ul>
	</div>
</template>
<script>
import Moment from 'moment';
import Weekdays from './components/Weekdays';
import Day from './components/Day';
export default {
	components : {
		Weekdays,
		Day,
	},
	props : {
		items : {
			type     : Object,
			required : true,
		},
	},
	data() {
		return {
			numberOfMonth    : null,
			firstDateSegment : null,
			lastDateSegment  : null,
			days             : [],
		};
	},
	created() {
		const t = this;
		const orders = t.items.orders;
		// Get first date
		t.firstDateSegment = Moment(orders[0].departure_date);
		// Get last date
		t.lastDateSegment = Moment(orders[orders.length - 1].arrival_date);
		// Get month diff between last and first date
		t.numberOfMonth = Math.ceil(t.lastDateSegment.diff(t.firstDateSegment, 'month', true) + 1);
		if(t.numberOfMonth < 3) {
			t.numberOfMonth = 3;
		}
		const days = t.days;
		this.addDays(t.firstDateSegment);

		// Add days of previous month if first day is not Monday
		if(days[0].day() != 1) {
			let weekdays = days[0].day();
			if(weekdays == 0) weekdays = 7;
			for (let i = 1; i <= weekdays - 1; i++) {
				const previousMonth = t.firstDateSegment.clone().startOf('day').add(-1, 'month');
				const daysInPreviousMonth = previousMonth.daysInMonth();
				days.unshift(previousMonth.date(daysInPreviousMonth - i));
			}
		}
	},
	methods : {
		addDays(firstDate) {
			const t = this;
			for (let currentMonth = 0; currentMonth < t.numberOfMonth; currentMonth++) {
				const daysInMonth = firstDate.clone().startOf('day').add(currentMonth, 'month').daysInMonth();
				for (let i = 1; i <= daysInMonth; i++) {
					const day = firstDate.clone().startOf('day').add(currentMonth, 'month').date(i);

					day.orders = [];
					if(i == 1) {
						if(day.day() != 1) {
							day.class = 'first-day-of-month';
						} else {
							day.class = 'weekdays';
						}
					} else if(i < 8) {
						day.class = 'weekdays';
					}
					for (let i = 0; i < t.items.orders.length; i++) {
						const order = t.items.orders[i];
						if(day.format('DD/MM/YYYY') == Moment(order.arrival_date).format('DD/MM/YYYY')) {
							day.orders.push(order);
						}
						if(day.format('DD/MM/YYYY') == Moment(order.departure_date).format('DD/MM/YYYY')) {
							day.orders.push(order);
						}
					}
					t.days.push(day);
				}
			}
		},
		addMonths($event) {
			const elem = $event.target;
			if((elem.scrollTop + elem.clientHeight ) >	 (elem.scrollHeight - 50)) {
				this.addDays(this.days[this.days.length - 1].add(1, 'days'));
			}
		},
		setItems(items) {
			this.$emit('set-items', items);
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
table {
	border-right: 1px solid $light-grey;
}
.calendar-planning {
	max-height: 910px;
	overflow-y: auto;
	padding-left: 45px;
	padding-bottom: 1px;
	position: relative;
}
.days {
	display: flex;
	flex-wrap: wrap;
	border-right: 1px solid $light-grey;
}
</style>