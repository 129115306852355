<template>
	<div class="d-flex flex-wrap">
		<div v-for="terminal in terminals" :key="terminal.id" class="terminal">
			<b-card-header>
				{{ terminal.name }}
			</b-card-header>
			<b-card-body>
				<p>
					<span>
						<b>{{ $t('address') }} :</b>
						{{ terminal.modals[0].address.number }} {{ terminal.modals[0].address.street }}</span>
					<span>{{ terminal.modals[0].address.postal_code }} {{ terminal.modals[0].address.city }}</span>
					<span>{{ terminal.modals[0].address.country }}</span>
					<span>
						<b>{{ $t('length-capacity') }} (M) :</b>
						{{ terminal.modals[0].characteristics.length }}
					</span>
					<span>
						<b>{{ $t('tonnage-capacity') }} (T) :</b>
						{{ terminal.modals[0].characteristics.capacity }}
					</span>
					<span>
						<b>{{ $t('open-days') }} :</b>
						<span v-for="openings in terminal.modals[0].opening_hours" :key="openings.day_of_week">
							{{ $t('weekday_sh_' + openings.day_of_week) }}
						</span>
					</span>
					<span>
						<b>{{ $t('opening-time') }} : </b>
						00H-24H
					</span>
					<span>
						<b>{{ $t('handler') }} :</b>
						<span v-if="terminal.modals[0].handler">{{ terminal.modals[0].handler.name }}</span>
					</span>
				</p>
			</b-card-body>
		</div>
	</div>
</template>
<script>
export default {
	name : 'Terminals',
	data() {
		return {
			terminals : [],
		};
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			this.terminals  = require('@/utils/fakeData/' + user.company.name + '/mockTerminals.json');
		}
	},
	created() {
		this.getData();
	}
};
</script>
<style lang="scss" scoped>
.terminal {
	width: calc(33% - 1rem);
	margin: 0.5rem;
}
.terminal p {
	text-transform: initial;
	display: flex;
	flex-direction: column;
}

</style>