<template>
	<form @submit="submit">
		<b-form-group :label="$t('address')">
			<b-input placeholder="_" />
		</b-form-group>
		<b-form-group :label="$t('city')">
			<b-input placeholder="_" />
		</b-form-group>
		<b-form-group :label="$t('departement')">
			<b-input placeholder="_" />
		</b-form-group>
		<b-form-group :label="$t('country')">
			<b-form-select :value="null">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
		<div class="d-flex justify-content-between w-100 mt-3">
			<b-button variant="info" class="w-49" type="button" @click="previousStep">
				{{ $t('previous') }}
			</b-button>
			<b-button variant="primary" class="w-49" type="button" @click="submit">
				{{ $t('next') }}
			</b-button>
		</div>
	</form>
</template>

<script>
export default {
	name    : 'SocietyAddress',
	methods : {
		previousStep() {
			this.$emit('go-to-step', 'society');
		},
		submit() {
			this.$emit('go-to-step', 'user');
		}
	}
};
</script>