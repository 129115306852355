<template>
	<b-form class="filter">
		<b-row class="w-100 justify-content-center">
			<slot />
			<b-col sm="12" class="d-flex mt-1 justify-content-center">
				<b-button variant="outline-light" class="btn-icon" @click="reset">
					<div class="icon cross" />
					<span>{{ $t('reset') }}</span>
				</b-button>
			</b-col>
		</b-row>
	</b-form>
</template>
<script>
export default {
	methods : {
		reset() {
			for (let i = 0; i < this.$slots.default.length; i++) {
				const input = this.$slots.default[i].componentInstance;
				if(input.reset) input.reset();
			}
		}
	}
};
</script>