<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('incident-type')">
			<b-form-select v-model="incident" :options="options">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
	</b-col>
</template>
<script>
export default {
	props : {
		items : {
			required : true,
			type     : Array
		}
	},
	computed : {
		options() {
			return [
				{
					value : 'Segment',
					text  : 'Segment'
				},
				{
					value : 'Commande',
					text  : 'Commande'
				},
				{
					value : 'Wagons',
					text  : 'Wagons'
				},
				{
					value : 'Autres',
					text  : 'Autres'
				}
			];
		}
	},
	created() {
		const incidentParam = this.$route.query.incident;
		if(incidentParam) {
			this.incident = incidentParam;
		}
	},
	data() {
		return {
			incident : null,
		};
	},
	methods : {
		reset() {
			this.incident = null;
		}
	},
	watch : {
		incident(val) {
			let items = this.items;
			if(val) {
				items = items.filter(item => item.incident.type == val);
			}
			this.$emit('set-items', items);
		}
	}
};
</script>