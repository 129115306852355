<template>
	<div id="orders-list">
		<div class="d-flex justify-content-between">
			<h1>{{ $t('list-of-orders') }}</h1>
			<Legends />
		</div>
		<div class="child-mx-2 d-flex justify-content-end mt-2">
			<b-button variant="primary" @click="$router.push('/orders/planning')">
				{{ $t('edit-order') }}
			</b-button>
			<b-button variant="primary" @click="$router.push({name: 'CreateOrder'})">
				{{ $t('create-order') }}
			</b-button>
		</div>
		<b-tabs class="mt-3">
			<b-tab :title="$t('orders')" lazy>
				<div class="tab-header border-radius-top-right">
					<Filters>
						<Code />
						<Sites />
						<Clients />
						<ClientRef />
						<Ef />
						<Status />
					</Filters>
				</div>
				<div class="list-header tab-header-bottom border-radius-bottom">
					<ul>
						<li>{{ $t('order-code') }}</li>
						<li>{{ $t('origine') }}</li>
						<li> {{ $t('site') }}</li>
						<li>{{ $t('destination') }}</li>
						<li>{{ $t('actions') }}</li>
					</ul>
				</div>
				<div class="tab-content list mt-2">
					<Order :order="order" v-for="(order) in getOrdersSliced" :key="order._id" />
					<Pagination :pagination="pagination" />
				</div>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import Legends from './components/list/Legends.vue';
import Filters from '@/components/filters/Filters.vue';
import { Code,  Sites, ClientRef, Ef, Status } from '@/components/filters/components/order';
import Clients from '@/components/filters/components/Clients.vue';
import Pagination from '@/components/Pagination.vue';
import Order from './components/list/order/Order.vue';
export default {
	name       : 'Orders',
	components : {
		Legends,
		Filters,
		Code,
		Sites,
		Clients,
		ClientRef,
		Ef,
		Status,
		Pagination,
		Order,
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('list-of-orders'),
		};
	},
	data() {
		return {
			orders      : [],
			pagination : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			}
		};
	},
	computed : {
		getOrdersSliced() {
			var pagination = this.pagination;
			return this.orders.slice((pagination.currentPage - 1) * pagination.perPage,
				pagination.currentPage * pagination.perPage);
		},
	},
	methods : {
		getData() {
			const user = JSON.parse(localStorage.user);
			this.orders  = require('@/utils/fakeData/' + user.company.name + '/mockOrder.json');
			this.pagination.totalRows = this.orders.length;
		}
	},
	created() {
		this.getData();
	}
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';
</style>
<i18n>
{
	"fr": {
		"edit-order": "Modifier une commande",
		"create-order": "Crée une commande"
	},
	"en": {
		"edit-order": "Edit order",
		"create-order": "Create order"
	}
}
</i18n>