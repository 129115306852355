<template>
	<form @submit="submit">
		<b-form-group :label="$t('email')">
			<b-input placeholder="_" type="email" />
		</b-form-group>
		<b-form-group :label="$t('email-confirmation')">
			<b-input placeholder="_" type="email" />
		</b-form-group>
		<b-form-group :label="$t('password')">
			<b-input placeholder="_" type="password" />
		</b-form-group>
		<b-form-group :label="$t('password-confirmation')">
			<b-input placeholder="_" type="password" />
		</b-form-group>
		<b-form-group :label="$t('phone-number')">
			<b-input placeholder="_" type="number" />
		</b-form-group>
		<b-form-group :label="$t('job')">
			<b-input placeholder="_" />
		</b-form-group>
		<b-form-group :label="$t('language')">
			<b-form-select :value="null">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
		<b-form-checkbox class="text-initial" v-model="user.sameAddress">
			{{ $t('identical-address-to-company') }}
		</b-form-checkbox>
		<div v-if="!user.sameAddress" id="user-address">
			<b-form-group :label="$t('address')">
				<b-input placeholder="_" />
			</b-form-group>
			<b-form-group :label="$t('city')">
				<b-input placeholder="_" />
			</b-form-group>
			<b-form-group :label="$t('departement')">
				<b-input placeholder="_" />
			</b-form-group>
			<b-form-group :label="$t('country')">
				<b-form-select :value="null">
					<template v-slot:first>
						<b-form-select-option :value="null" disabled>
							_
						</b-form-select-option>
					</template>
				</b-form-select>
			</b-form-group>
		</div>
		<div class="d-flex justify-content-between w-100 mt-3">
			<b-button variant="info" class="w-49" @click="previousStep">
				{{ $t('previous') }}
			</b-button>
			<b-button variant="primary" class="w-49" @click="register">
				{{ $t('register') }}
			</b-button>
		</div>
	</form>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name  : 'User',
	props : {
		user : {
			type     : Object,
			required : true
		},
	},
	methods  : {
		...mapActions({
			createRegister : 'registers/createRegister'
		}),
		previousStep() {
			this.$emit('go-to-step', 'address');
		},
		submit($event) {
			$event.preventDefault();
			this.$emit('go-to-step', 'confirmation');
		},
		async register($event) {
			$event.preventDefault();
			this.$loading.state = true;
			this.$store.commit('alert/DISMISS');
			try {
				const body = {
					user    : this.user,
					company : this.company,
				};
				const res = await this.createRegister( body);
				if (res.body.insertedId) {
					this.$emit('go-to-step', 'confirmation', this.user.email);
				} else {
					// console.log(res);
				}
			} catch (e) {
				this.$store.commit('alert/SHOW',
					{
						message : e.response.data.message,
						variant : 'danger'
					}
				);
			} finally{
				this.$loading.state = false;
			}
		},
	}
};
</script>
<style lang="scss" scoped>
#user-address {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 1rem;
	width: 100%;
}
</style>