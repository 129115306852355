<template>
	<b-card no-body>
		<b-card-header>{{ $t('economy-of-co2-by-order') }}</b-card-header>
		<b-card-body>
			<b-form-select :value="null">
				<template v-slot:first>
					<b-form-select-option :value="null">
						{{ $t('january') }}
					</b-form-select-option>
				</template>
			</b-form-select>
			<b-row class="w-100 align-items-center">
				<b-col md="6" sm="12">
					<div class="d-flex flex-column align-self-start w-100">
						<div class="circle-on-bar-container" v-for="(road, index) in roads.slice(0, limit)" :key="index"
							:style="getStyle(road)">
							<p class="text-green">
								{{ road.label }}
							</p>
							<CircleOnBar :road="road" />
							<p>
								{{ $t('on-a-road-repository-of') }} {{ road.total }} {{ $t('tons-of-CO2-used') }}
							</p>
						</div>
					</div>
					<div class="text-center">
						<b-button class="center" variant="green" @click="limit*=2">
							{{ $t('see-more-orders') }}
						</b-button>
					</div>
				</b-col>
				<b-col md="6" sm="12" class="d-flex align-items-center flex-column">
					<h4 class="text-green text-initial">
						{{ $t('total-economy-CO2') }}
					</h4>
					<div class="percentage-circle green big">
						<h2>72%</h2>
					</div>
					<p class="w-50 text-center text-initial bold mt-3">
						{{ $t('percentage-tons-CO2') }}
					</p>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>
<script>
import CircleOnBar from './components/CircleOnBar';
export default {
	components : {
		CircleOnBar
	},
	data() {
		return {
			limit : 2,
			roads : [
				{
					label : 'Rouen 1 > Rouen faisceau > Tergnier > Amifontaine'
									+ '> Tergnier > Rouen faisceau > Rouen 1',
					total : 4.2,
					used  : 1.548
				},
				{
					label : 'Rouen 1 > Rouen faisceau > Tergnier > Amifontaine > '
									+ 'Tergnier > Rouen faisceau > Rouen 2',
					total : 3.2,
					used  : 0.943
				},
				{
					label : 'Rouen 2 > Rouen faisceau > Tergnier > Amifontaine > '
									+ 'Tergnier > Rouen faisceau > Rouen 1',
					total : 4.7,
					used  : 1.648
				},
				{
					label : 'Rouen 2 > Rouen faisceau > Tergnier > Amifontaine > '
									+ 'Tergnier > Rouen faisceau > Rouen 2',
					total : 3.7,
					used  : 0.756
				},
				{
					label : 'Rouen 1 > Rouen faisceau > Tergnier > Dercy-Mortiers > '
									+ 'Tergnier > Rouen faisceau > Rouen 1',
					total : 4.4,
					used  : 1.848
				},
				{
					label : 'Rouen 1 > Rouen faisceau > Tergnier > Dercy-Mortiers > '
									+ 'Tergnier > Rouen faisceau > Rouen 2',
					total : 3.9,
					used  : 1.143
				}
			],
			highestTotal : '',
		};
	},
	created() {
		const roadsSorted = [... this.roads].sort((a, b) => (a.total > b.total) ? 1 : -1);
		this.highestTotal = roadsSorted[roadsSorted.length - 1].total;
	},
	methods : {
		getStyle(road) {
			const width = (road.total / this.highestTotal).toFixed(2) * 100;
			return 'width: ' + width + '%;';
		}
	}
};
</script>
<style lang="scss" scoped>
.circle-on-bar-container {
	width: 100%;
	font-weight: 600;
}
.circle-on-bar-container p {
	margin: 1.5rem 0;
	text-transform: initial;
}
</style>
<i18n>
{
    "fr": {
        "on-a-road-repository-of": "sur un référentiel route de",
        "economy-of-co2-by-order": "Économies de CO2 par commandes",
        "tons-of-CO2-used": "tonnes de CO2 utilisées",
        "total-economy-CO2": "Total des économies de CO2",
        "percentage-tons-CO2": "% en tonnes de CO2 économisées par rapport à la route"
    },
    "en" : {
        "on-a-road-repository-of": "on-a-road-repository-of",
        "economy-of-co2-by-order": "CO2 savings per order",
        "tons-of-CO2-used": "tons of CO2 used",
        "total-economy-CO2": "Total CO2 savings",
        "percentage-tons-CO2": "% in tons of CO2 saved compared to road transport"
    }
}
</i18n>