<template>
	<div id="contracts-edit">
		<h1>{{ $t('contract') }} : N° 315203232</h1>
		<div class="d-flex justify-content-between align-items-baseline">
			<Breadcrumbs />
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)">
				<div class="icon arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<b-button variant="primary" v-b-toggle="'identification'" class="btn-collapse">
			{{ $t('identification') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="identification" visible accordion="accordion">
			<b-card>
				<Charger />
				<Provider />
				<Provider />
				<b-row>
					<b-col lg="6">
						<Terminals />
					</b-col>
					<b-col lg="6">
						<Segments />
					</b-col>
					<b-col lg="6" class="mt-4">
						<Calendar />
					</b-col>
					<b-col lg="6" class="mt-4">
						<TransportPlan />
					</b-col>
				</b-row>
				<b-row>
					<b-col xl="6" class="d-flex justify-content-end">
						<b-button class="d-flex align-items-center my-3" variant="info">
							<div class="icon cross" />
							Annuler
						</b-button>
					</b-col>
					<b-col xl="6">
						<b-button class="d-flex align-items-center my-3" variant="primary">
							<div class="icon save" />
							Sauvegarder
						</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'terminals'" class="btn-collapse">
			{{ $t('sites') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="sites" class="" accordion="accordion">
			<b-card>
				<b-card-header header-bg-variant="info" header-text-variant="white">
					Test
				</b-card-header>
				<b-card-body>Testy</b-card-body>
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'segments'" class="btn-collapse">
			{{ $t('segments') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="segments" class="" accordion="accordion">
			<b-card>
				<b-card-header header-bg-variant="info" header-text-variant="white">
					Test
				</b-card-header>
				<b-card-body>Testy</b-card-body>
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'transport-plan'" class="btn-collapse">
			{{ $t('transport-plan') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="transport-plan" class="" accordion="accordion">
			<b-card>
				<b-card-header header-bg-variant="info" header-text-variant="white">
					Test
				</b-card-header>
				<b-card-body>Testy</b-card-body>
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'pricing-conditions'" class="btn-collapse">
			{{ $t('pricing-conditions') }}
			<div class="icon chevron-down" />
		</b-button>
		<b-collapse id="pricing-conditions" class="" accordion="accordion">
			<b-card>
				<b-card-header header-bg-variant="info" header-text-variant="white">
					Test
				</b-card-header>
				<b-card-body>Testy</b-card-body>
			</b-card>
		</b-collapse>
	</div>
</template>

<script>
import Breadcrumbs from './components/Breadcrumbs.vue';
import { Charger, Provider, Terminals, Segments, Calendar, TransportPlan } from './components/edit/identification';
export default {
	name       : 'Edit',
	components : {
		Breadcrumbs,
		Charger,
		Provider,
		Terminals,
		Segments,
		Calendar,
		TransportPlan
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('contracts') + ' N° 3111111',
		};
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/ #charger .row > .col,
/deep/ #provider .row > .col {
	&:last-of-type {
		padding-left: 20px;
		border-left: 2px solid darken($white, 15%);
	}
	&:not(:last-of-type) {
		padding-right: 20px;
	}
}
/deep/ #terminals ul li,
/deep/ #segments ul li {
	background-color: $white;
	border-radius: $border-radius;
	box-shadow: $shadow;
}
</style>