<template>
	<div id="RentalFields">
		<b-card no-body>
			<b-card-header>
				{{ $t('location') }}
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="4">
						<b-form-group :label="$t('site-name')">
							<b-form-input placeholder="_" v-model="location.name" />
						</b-form-group>
					</b-col>
					<b-col lg="4">
						<b-form-group :label="$t('site-type')">
							<b-form-select :value="site">
								<option v-for="(option, key) in options.site" :key="key" :value="option">
									{{ $t(option) }}
								</option>
								<template v-slot:first>
									<b-form-select-option :value="null" disabled>
										{{ location.modals[0].typology }}
									</b-form-select-option>
								</template>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col lg="4">
						<b-form-group :label="$t('interconnection')">
							<multi-select v-model="value" :select-label="$t('add')"
								placeholder="_" label="name" track-by="code" :hide-selected="true"
								:options="multioptions" :multiple="true" :taggable="true" @tag="addTag" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-row>
			<b-col lg="6" class="mt-3">
				<b-card class="h-100" no-body>
					<b-card-header>
						{{ $t('address') }}
					</b-card-header>
					<b-card-body>
						<b-row>
							<b-col lg="6">
								<b-form-group :label="$t('street-number')">
									<b-form-input v-model="location.modals[0].address.number" placeholder="_" />
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group :label="$t('street')">
									<b-form-input v-model="location.modals[0].address.street" placeholder="_" />
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col lg="6">
								<b-form-group :label="$t('city')">
									<b-form-input v-model="location.modals[0].address.city" placeholder="_" />
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group :label="$t('postal-code')">
									<b-form-input v-model="location.modals[0].address.postal_code"
										@input="emitAddress()" placeholder="_" />
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col lg="6">
								<b-form-group :label="$t('country')">
									<b-form-input v-model="location.modals[0].address.country"
										type="text" placeholder="_" />
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group :label="$t('state')">
									<b-form-input v-model="location.modals[0].address.state"
										type="text" placeholder="_" />
								</b-form-group>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col lg="6" class="mt-3">
				<b-card no-body>
					<b-card-header>
						{{ $t('hours') }}
					</b-card-header>
					<b-card-body>
						<div class="primary d-flex justify-content-between"
							v-for="(opening_hour, index) in location.modals[0].opening_hours" :key="index">
							<b-form-group :label="$t('weekday_lo_' + (index + 1))" />
							{{ opening_hour.hours[0].start }} -
							{{ opening_hour.hours[0].end }}
						</div>

						<div class="d-flex justify-content-end">
							<b-button @click="modalShow = !modalShow" variant="primary">
								{{ $t('manage-hours') }}
							</b-button>
							<b-modal v-model="modalShow" title="Horaires" :cancel-title="$t('cancel')"
								centered :ok-title="$t('save')">
								<div class="d-flex justify-content-between">
									<b-button v-for="(btn, idx) in buttons" :key="idx"
										:pressed.sync="btn.state" :variant="getBtnVariant(btn.state)">
										{{ btn.caption }}
									</b-button>
								</div>
								<b-row class="mt-2">
									<div class="ml-3">
										<b-form-checkbox>
											<b-form-group :label="$t('closed')" />
										</b-form-checkbox>
									</div>
									<div class="ml-3">
										<b-form-checkbox>
											<b-form-group :label="$t('always-open')" />
										</b-form-checkbox>
									</div>
								</b-row>
								<div class="d-flex justify-content-between">
									<b-form-timepicker size="sm" :label-no-time-selected="$t('open-time-selected')"
										:locale="_i18n.locale" :label-close-button="$t('close')" />
									_
									<b-form-timepicker size="sm" :label-no-time-selected="$t('close-time-selected')"
										:locale="_i18n.locale" :label-close-button="$t('close')" />
								</div>
								<div class="d-flex justify-content-end mt-2">
									<b-button class="btn-icon-only" variant="primary">
										<div class="icon add" />
									</b-button>
								</div>
							</b-modal>
						</div>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import MultiSelect from 'vue-multiselect';
export default {
	name       : 'RentalFields',
	components : {
		MultiSelect
	},
	props : {
		location : {
			type     : Object,
			required : false,
			default() {
				return {
					modals : [{
						opening_hours : [
							{
								hours : [{
									start : '00:00',
									end   : '00:00'
								}],
							},
							{
								hours : [{
									start : '00:00',
									end   : '00:00'
								}],
							},
							{
								hours : [{
									start : '00:00',
									end   : '00:00'
								}],
							},
							{
								hours : [{
									start : '00:00',
									end   : '00:00'
								}],
							},
							{
								hours : [{
									start : '00:00',
									end   : '00:00'
								}],
							},
							{
								hours : [{
									start : '00:00',
									end   : '00:00'
								}],
							},
							{
								hours : [{
									start : '00:00',
									end   : '00:00'
								}],
							}
						],
						address : {
							number : ''
						}
					}],
				};
			}
		},
	},

	data() {
		return {
			site    : null,
			options : {
				site : [
					'terminal',
					'terminal-hub',
					'platform',
					'ITE',
					'Station'
				],
			},
			modalShow : false,
			buttons   : [
				{ caption : 'L',
					state   : false },
				{ caption : 'M',
					state   : false },
				{ caption : 'M',
					state   : false },
				{ caption : 'J',
					state   : false },
				{ caption : 'V',
					state   : false },
				{ caption : 'S',
					state   : false },
				{ caption : 'D',
					state   : false }
			],
			value : [
				{ name : 'Fos Graveleau',
					code : '1' }
			],
			multioptions : [
				{ name : 'Fos Graveleau',
					code : '1' },
				{ name : 'Eurofos',
					code : '2' },
				{ name : 'Seayard',
					code : '3' }
			]
		};
	},
	methods : {
		emitAddress() {
			this.$emit('set-address', this.address);
		},
		getBtnVariant(state) {
			if(state) {
				return 'primary';
			} else {
				return 'info';
			}
		},
		addTag(newTag) {
			const tag = {
				name : newTag,
				code : newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
			};
			this.multioptions.push(tag);
			this.value.push(tag);
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>