<template>
	<b-alert show :class="{'show' : alert.show}" :variant="alert.variant" id="alert">
		<p class="pr-3">
			{{ alert.message }}
		</p>
		<b-button @click="close" class="shadow-none">
			<div class="icon cross" />
		</b-button>
	</b-alert>
</template>

<script>
export default {
	name     : 'Alert',
	computed : {
		alert() {
			return this.$store.getters['alert/getAlert'];
		}
	},
	methods : {
		close() {
			this.$store.commit('alert/DISMISS');
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.alert {
	position: absolute;
	width: 60%;
	left: 50%;
	transform: translate(-50%, -100%);
	z-index: 4;
	text-transform: initial;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.alert.show {
	transition: transform $transition;
	transform: translateX(-50%);
	top: 10px;
}
.alert /deep/ button {
	display: flex;
	box-shadow: none;
	outline: none;
	padding: 0;
	background-color: transparent;
	border-color: transparent;
	&:active:focus {
		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
	}
}
.alert-danger .icon {
	filter: invert(17%) sepia(20%) saturate(6886%) hue-rotate(351deg) brightness(98%) contrast(115%);
}
</style>
