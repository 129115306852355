<template>
	<nav id="nav" v-b-hover="hoverNav" @click="closeNavOnTabletAndMobile">
		<div id="nav-state" @click="minimizeNav">
			<div v-if="navMinimized" class="icon uncompress" />
			<div v-else class="icon compress" />
		</div>
		<router-link to="/">
			<img src="@/assets/img/logo_ecoPms.png" />
		</router-link>
		<ul>
			<li v-for="route in items" :key="route.key">
				<router-link :to="{path: route.path}" @click.native="toggleSubMenu($event)"
					:class="{'sub-menu-link': subMenuHaveSubItem(route)}">
					<div :class="route.icon + ' icon'" />
					<span class="ml-2">{{ $t(route.title) }}</span>
				</router-link>
				<ul class="sub-menu" v-if="subMenuHaveSubItem(route)">
					<li v-for="(children) in route.children" :key="children.name" class="sub-item">
						<router-link :to="{path: children.path}" v-if="children.meta && children.meta.subitem">
							<div :class="children.icon + ' icon'" />
							<span class="ml-2">{{ $t(children.title) }}</span>
						</router-link>
					</li>
				</ul>
			</li>
		</ul>
		<div id="settings">
			<b-dropdown size="small" variant="link" toggle-class="text-decoration-none"
				no-caret>
				<template slot="button-content">
					<div class="icon settings" />
				</template>
				<b-dropdown-item href="#" @click="switchLanguage">
					<div class="icon globe" />
					<p>{{ $t('switch-language') }}</p>
				</b-dropdown-item>
				<b-dropdown-item href="#" @click="logoutAndCloseNav">
					<div class="icon log-out" />
					<p>{{ $t('logout') }}</p>
				</b-dropdown-item>
			</b-dropdown>
		</div>
	</nav>
</template>

<script>
import store from '@/store';
export default {
	name : 'Nav',
	data() {
		return {
			items          : [],
			routes         : this.$router.options.routes,
			navMinimized   : false,
			currentNavItem : null,
		};
	},
	created() {

	},
	mounted() {
		const routes = this.routes.slice(0);
		const userPermissions = store.getters['user/get'].scope;
		for (let i = 0; i < routes.length; i++) {
			var route = JSON.parse(JSON.stringify(routes[i]));
			if(route.icon) { // If route is on menu
				if(route.meta && route.meta.permission) {
					for (let e = 0; e < userPermissions.length; e++) {
						const userPermission = userPermissions[e];
						if(route.meta.permission.includes(userPermission)) { // If route is permission allowed
							if(route.children) {
								for (let a = 0; a < route.children.length; a++) {
									const children = { ...route.children[a] };
									if(children.meta && !children.meta.subitem) {
										route.children.splice(a, 1);
									}
									if(children.meta && children.meta.permission) {
										if(!children.meta.permission.includes(userPermission)) {
											route.children.splice(a, 1);
										}
									}
								}
							}
							if(this.items.indexOf(route) == -1) {
								this.items.push(route);
							}
						}
					}
				} else {
					if(route.children) {
						for (let a = 0; a < route.children.length; a++) {
							const children = { ...route.children[a] };
							if(children.meta && !children.meta.subitem) {
								route.children.splice(a, 1);
							}
							if(children.meta && children.meta.permission) {
								for (let d = 0; d < userPermissions.length; d++) {
									const userPermissionSecond = userPermissions[d];
									if(!children.meta.permission.includes(userPermissionSecond)) {
										route.children.splice(a, 1);
									}
								}
							}
						}
					}
					if(this.items.indexOf(route) == -1) {
						this.items.push(route);
					}
				}
			}
		}
		this.items.sort((a, b) => {
			return a.index - b.index;
		});
		let navPreference = localStorage.getItem('navMinimized');
		if(navPreference != undefined) {
			const app = document.getElementById('app');
			navPreference = (navPreference == 'true');
			if (!navPreference) {
				app.classList.add('minimized');
				this.navMinimized = true;
			}
		}
		var subItem = document.getElementsByClassName('sub-item');
		[... subItem].forEach((e) => {
			if(window.location.href.includes(e.getElementsByTagName('a')[0].href)) {
				if(this.navMinimized == true) {
					e.closest('ul').parentElement.classList.add('current-sub-menu');
				} else {
					e.closest('ul').parentElement.classList.add('open-sub-menu', 'current-sub-menu');
				}
			}
		});
	},
	methods : {
		subMenuHaveSubItem(submenu) {
			if(submenu.children) {
				for (let i = 0; i < submenu.children.length; i++) {
					const children = submenu.children[i];
					if(children.subitem) {
						return true;
					}
				}
			}
			return false;
		},
		closeNavOnTabletAndMobile() {
			const app = this.$root.$el;
			const hamburger = document.getElementById('hamburger');
			if(app.classList.contains('open-nav') && hamburger) {
				hamburger.classList.remove('cross');
				app.classList.remove('open-nav');
			}
		},
		logoutAndCloseNav() {
			this.closeNavOnTabletAndMobile();
			this.logout();
		},
		hoverNav(isHovered) {
			if (this.navMinimized) {
				const nav = document.getElementsByTagName('nav')[0];
				const ul = nav.getElementsByTagName('ul')[0];
				let currentSubMenu = null;
				Array.from(ul.getElementsByTagName('li')).forEach(e => {
					if (e.classList.contains('current-sub-menu')) currentSubMenu = e;
				});

				const app = document.getElementById('app');
				if (isHovered) {
					app.classList.remove('minimized');
					if (currentSubMenu) currentSubMenu.classList.add('open-sub-menu');
				} else {
					app.classList.add('minimized');
					if (currentSubMenu) currentSubMenu.classList.remove('open-sub-menu');
				}
			}
		},
		minimizeNav() {
			const app = document.getElementById('app');
			if (this.navMinimized) app.classList.remove('minimized');
			else app.classList.add('minimized');
			this.navMinimized = !this.navMinimized;
			localStorage.setItem('navMinimized', !this.navMinimized);
		},
		toggleSubMenu($event) {
			const li = $event.target.closest('li');
			if (li.getElementsByClassName('sub-menu').length) {
				li.classList.toggle('open-sub-menu');
				li.classList.toggle('current-sub-menu');
			} else {
				const ul = $event.target.closest('ul');
				Array.from(ul.getElementsByTagName('li')).forEach(e => {
					e.classList.remove('open-sub-menu');
					e.classList.remove('current-sub-menu');
				});
			}
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
nav {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $secondary;
	color: $white;
	width: 270px;
	height: 100vh;
	transition: $transition;
}
.minimized nav {
	width: 60px;
}
nav .icon {
	width: 1.1em;
	height: 1.1em;
}
nav #nav-state {
	cursor: pointer;
	padding: 10px;
	align-self: flex-end;
	min-height: 53px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
nav #nav-state .icon {
	position: relative;
	left: 2px;
	top: 2px;
}
nav img {
	padding-bottom: 10px;
	display: block;
	height: 100px;
}
.minimized nav img {
	display: none;
}
nav > ul {
	width: 100%;
	height: 100%;
	padding-top: 20px;
	overflow-y: auto;
	overflow-x: hidden;
}
nav ul li a {
	display: block;
	min-height: 53px;
}
nav ul li a,
nav #nav-state {
	color: $white;
	position: relative;
	font-size: 1.2em;
	padding: 15px 20px;
	display: flex;
	align-items: center;
	&:hover {
		text-decoration: none;
		color: $white;
		background-color: $primary;
	}
}
nav ul li:not(.current-sub-menu) a.router-link-active .icon {
	filter: invert(34%) sepia(72%) saturate(10000%) hue-rotate(207deg) brightness(100%) contrast(100%);
}
.minimized nav #nav-state {
	margin-bottom: 110px;
	align-self: center;
	justify-content: center;
	width: 100%;
}
.minimized nav #nav-state .icon {
	transform: rotate(180deg);
	position: relative;
	left: -2px;
	top: -1px;
}
nav ul.sub-menu {
	max-height: 0;
	visibility: hidden;
	opacity: 0;
	transition: $transition * 1.5;
}
nav ul li.open-sub-menu ul.sub-menu {
	max-height: 1000px;
	visibility: visible;
	opacity: 1;
}
nav ul li.current-sub-menu > a {
	background-color: $primary;
}
nav ul.sub-menu li a {
	padding-left: 40px;
	background-color: $thirdary;
	&:hover {
		background-color: $primary;
	}
}
nav ul li:not(.current-sub-menu) a.router-link-active {
	color: $primary;
	background-color: $white;
	&:hover {
		background-color: $white;
	}
}
nav ul li a .sub-menu-indicator {
	position: absolute;
	top: 50%;
	transition: $transition;
	transform: translateY(-50%);
	right: 1rem;
}
nav ul li.open-sub-menu .sub-menu-indicator {
	position: absolute;
	top: 50%;
	right: 1rem;
	transform: rotate(90deg) translateX(-30%);
}
.minimized nav ul li a .sub-menu-indicator {
	display: none;
}
.minimized nav ul li a span {
	display: none;
}
#settings {
	width: 100%;
	display: none;
	padding: 15px 20px;
	position: absolute;
	bottom: 10px;
}
#settings /deep/.dropdown-toggle {
	display: flex;
	font-size: 1.5rem;
}

@include media-breakpoint-down(md) {
	nav #nav-state {
		display: none;
	}
	nav {
		position: absolute;
		z-index: 9;
		padding: 60px 0;
		left: -270px;
	}
	nav > ul {
		padding-top: 0;
	}
	nav img {
		display: none;
	}
	.open-nav nav {
		left: 0;
	}
	.open-nav #settings {
		display: flex;
	}
}
</style>